import {
  View,
  Text,
  StyleSheet,
  Modal,
  TouchableOpacity,
  Dimensions,
  TextInput,
  KeyboardAvoidingView,
  Alert,
  Image,
  ScrollView,
} from "react-native";
import React from "react";
import { useState } from "react";
import CalendarPicker from "react-native-calendar-picker";
import { AntDesign, Ionicons, MaterialIcons } from "@expo/vector-icons";
import { Platform } from "react-native";
import { useEffect } from "react";
import CustomDropdownUser from "../../../customComponents/DropDown/CustomDropdownUser";
import { sendNewPushNotification } from "../../../utils/notification";
import { DateFormat, GetDateMergedTime, convertDate } from "../../../utils/function";
import moment from "moment";
import CustomToggle from "../Setting/CustomToggle";
import { sendNotification } from "../../../utils/webNotification";
import CustomSliderFilter from "../../../customComponents/CustomSliderFilter";
import CustomSliderFilterDropDown from "../../../customComponents/CustomSliderFilterDropDown";
import { getAllNurseList, getNurseAvaJobDate, getNurseDetails } from "../../../api_url/nurseTable";
import { postNurseNotification } from "../../../api_url/nurseNotification";
import { postFacilityNotification } from "../../../api_url/facilityNotification";
import { getFacilityDetails, getAllFacilityList } from "../../../api_url/facilityTable";
import { useAuth } from "../../../context/AuthContext";
import { ChatState } from "../../../context/ChatContext";
import {
  getNurseAvaJobDateAllTimeOff,
  getNurseTimeOffDetails,
  postNurseTimeOff,
  updateNurseTimeOff
} from "../../../api_url/timeoffNurse";
import { socket } from "../../../api_url/socket";

const TimeOffRequest = (props) => {
  const { width, height } = Dimensions.get("window");

  const { authToken, userId, userUniqueId } = useAuth();
  const { fetchAgainTimeOffListNurse, setFetchAgainTimeOffLisNurse } = ChatState();

  const [nurseUserId, setNurseUserId] = useState(
    props.route.params?.data?.userId !== undefined
      ? props.route.params?.data?.userId
      : undefined
  );
  // console.log(props.route.params?.data);
  // console.log(props.route.params?.data)
  // console.log("TimeOffRequest", props?.userId,nurseUserId);

  const [authUserId, setAuthUserId] = useState(userId);

  // console.log(authUserId)

  const [user, setUser] = useState(undefined);
  const [facilityData, setFacilityData] = useState(undefined);

  const [timeOffData, setTimeOffData] = useState(undefined);

  const [fullDay, setFullDay] = useState(true);

  const [timeOff, setTimeOff] = useState({
    timeOffReason: "",
    startDate: new Date(),
    startTime: new Date(),
    endDate: new Date(),
    endTime: new Date(),
    alternateContact: "",
    phoneNum: "",
    comments: "",
  });
  const [cancellationReason, setCancellationReason] = useState("");

  const [showCalendar, setShowCalendar] = useState(false);
  const [showCalendarEnd, setShowCalendarEnd] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showTimeEnd, setShowTimeEnd] = useState(false);
  const [selectUser, setSelectUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  const [isDeleted, setIsDeleted] = useState(false);

  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [showTimeWeb, setShowTimeWeb] = useState(false);
  const [showTimeEndWeb, setShowTimeEndWeb] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownMinute, setShowDropdownMinute] = useState(false);
  const [showDropdownAP, setShowDropdownAP] = useState(false);

  const [selectedHours, setSelectedHours] = useState("1");

  const now = new Date();
  const currentMinutes = now.getMinutes().toString().padStart(2, "0");
  const currentAmPm = now.getHours() >= 12 ? "PM" : "AM";
  const [selectedHourTime, setSelectedHourTime] = useState(
    now.getHours() === 0
      ? 12
      : now.getHours() > 12
        ? now.getHours() - 12
        : now.getHours()
  );
  const [selectedMinuteTime, setSelectedMinuteTime] = useState(currentMinutes);
  const [selectedAPTime, setSelectedAPTime] = useState(currentAmPm);

  const [showDropdownEnd, setShowDropdownEnd] = useState(false);
  const [showDropdownMinuteEnd, setShowDropdownMinuteEnd] = useState(false);
  const [showDropdownAPEnd, setShowDropdownAPEnd] = useState(false);

  const [selectedHourTimeEnd, setSelectedHourTimeEnd] = useState(
    now.getHours() === 0
      ? 12
      : now.getHours() > 12
        ? now.getHours() - 12
        : now.getHours()
  );

  const [selectedMinuteTimeEnd, setSelectedMinuteTimeEnd] = useState("00");
  const [selectedAPTimeEnd, setSelectedAPTimeEnd] = useState("AM");

  useEffect(() => {
    const nowEnd = new Date();
    const currentMinutesEnd = nowEnd.getMinutes().toString().padStart(2, "0");
    const currentAmPmEnd = nowEnd.getHours() >= 12 ? "PM" : "AM";

    setSelectedHourTimeEnd(
      nowEnd.getHours() === 0
        ? 12
        : nowEnd.getHours() > 12
          ? nowEnd.getHours() - 12
          : nowEnd.getHours()
    );
    setSelectedMinuteTimeEnd(currentMinutesEnd);
    setSelectedAPTimeEnd(currentAmPmEnd);
  }, []);

  const hour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const minutes = [0, 15, 30, 45];
  const ampm = ["AM", "PM"];

  const getUpdatedTimeOff = async (id) => {
    const itemArr = await getNurseTimeOffDetails(authToken, id)
    setTimeOffData(itemArr);
  };

  useEffect(() => {
    if (props.route.params?.data?.item !== undefined) {
      getUpdatedTimeOff(props.route.params?.data?.item);
    } else if (props.route.params?.data?.timeOffId !== undefined) {
      getUpdatedTimeOff(props.route.params?.data?.timeOffId);
    }
  }, [props]);

  useEffect(() => {
    if (timeOffData !== undefined) {
      setTimeOff({
        ...timeOff,
        timeOffReason: timeOffData?.timeOffReason,
        startDate: moment(timeOffData?.startDate),
        startTime: moment(timeOffData?.startTime),
        endDate: moment(timeOffData?.endDate),
        endTime: moment(timeOffData?.endTime),
        alternateContact: timeOffData?.alternateContactNumber,
        phoneNum: timeOffData?.phoneNumber,
        comments: timeOffData?.comments,
      });
      setStartTime(moment(timeOffData?.startTime))
      setEndTime(moment(timeOffData?.endTime))
      if (timeOffData?.startTime === "" && timeOffData?.endTime === "") {
        setFullDay(true)
      } else {
        setFullDay(false)
      }
    }
  }, [timeOffData]);

  useEffect(() => {
    // const startTimeM = "00:00"; // 12:00 AM
    // const endTimeM = "23:59";   // 11:59 PM

    // const startDate = moment().format("YYYY-MM-DD"); // current date

    // const startDateTime = moment(`${startDate} ${startTimeM}`, "YYYY-MM-DD HH:mm");
    // const endDateTime = moment(`${startDate} ${endTimeM}`, "YYYY-MM-DD HH:mm");

    if (fullDay) {
      setStartTime("")
      setEndTime("")
    } else {
      setSelectedHours("1")
      if (timeOffData !== undefined) {
        setStartTime(moment(timeOffData?.startTime))
        setEndTime(moment(timeOffData?.endTime))
      } else {
        let dateStart = new Date();
        let dateEnd = new Date();
        let selectedAP = moment().format("a");
        let selectedHour = moment().format("hh");
        let selectedMinute = moment().format("mm");

        dateStart.setHours(
          selectedAP === "AM" && selectedHour === 12
            ? 0
            : selectedAP === "PM" && selectedHour === 12
              ? 12
              : selectedHour !== 12 && selectedAP === "PM"
                ? parseInt(selectedHour) + 12
                : parseInt(selectedHour)
        );
        dateStart.setMinutes(selectedMinute);

        dateEnd.setFullYear(dateStart.getFullYear());
        dateEnd.setMonth(dateStart.getMonth());
        dateEnd.setDate(dateStart.getDate());
        dateEnd.setHours(dateStart.getHours() + 1);
        dateEnd.setMinutes(dateStart.getMinutes());

        // Determine if it's AM or PM
        let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

        if (selectedAP === "PM" && meridiem === "AM") {
          let endDate = new Date(dateStart);
          endDate.setDate(endDate.getDate() + 1);
          setEndTime(dateEnd)
        } else {
          setEndTime(dateEnd)
        }
        setStartTime(dateStart)
      }
    }
  }, [fullDay, timeOffData]);

  //Facility Detail
  const getFacilityDetail = async (id) => {
    try {
      const facilityData = await getFacilityDetails(authToken, id)
      setFacilityData(facilityData)
    } catch (error) {
      console.log(error);
    }
  };

  const getNurseList = async (id) => {
    try {
      await getAllNurseList(authToken, id).then(
        res => {
          setData(res?.filter(item => item?.nurseAppAccessControl === false &&
            item?.nurseLoginControl === true));
        }
      )
    } catch (error) {
      console.log(error);
    }
  };

  const onUserDetailNavigate = (d) => {
    setSelectUser(false);
    props.navigation.navigate("UserDetailsScreen", {
      data: d,
    });
  };

  useEffect(() => {
    if (authUserId !== undefined) {
      getFacilityDetail(authUserId);
      getNurseList(authUserId);
    }
  }, [authUserId]);

  const checkUser = async (id) => {
    try {
      const nurse = await getNurseDetails(authToken, id);
      setUser(nurse);
    } catch (error) {
      setUser(undefined);
    }
  };

  useEffect(() => {
    if (nurseUserId === undefined) {
      return;
    }
    checkUser(nurseUserId);
  }, [nurseUserId]);

  useEffect(() => {
    if (user !== undefined) {
      setTimeOff({
        ...timeOff,
        phoneNum: user?.phoneNumber,
      });
    }
  }, [user]);

  const onChangeStartTime = (event, selectedDate) => {
    setTimeOff({ ...timeOff, startTime: selectedDate });
    setShowTime(false);
  };

  const onChangeEndTime = (event, selectedDate) => {
    setTimeOff({ ...timeOff, endTime: selectedDate });
    setShowTimeEnd(false);
  };

  const getFacilityLocationDetail = async () => {
    try {
      const facilityLocationData = await getAllFacilityList(authToken, authUserId, "facility");
      return facilityLocationData.filter(i => parseInt(i.id) !== parseInt(authUserId));
    } catch (error) {
      console.log("data save error:", error);
    }
  };

  const postTimeOff = async () => {
    setLoading(true);

    const startMoment = moment(startTime);
    const endMoment = moment(endTime);

    const durationInMinutes = endMoment.diff(startMoment, 'minutes');

    if (
      nurseUserId === undefined ||
      user === undefined ||
      timeOff.timeOffReason === ""
    ) {
      if (Platform.OS === "web") {
        alert("Fill all required fields first");
      } else {
        Alert.alert("Fill all required fields first");
      }
      setLoading(false);
    } else if (convertDate(timeOff.startDate) < convertDate(currentDate)) {
      if (Platform.OS === "web") {
        alert("Check your date");
      } else {
        Alert.alert("Check your date");
      }
      setLoading(false);
    } else if (convertDate(timeOff.endDate) < convertDate(timeOff.startDate)) {
      if (Platform.OS === "web") {
        alert("Check your end date");
      } else {
        Alert.alert("Check your end date");
      }
      setLoading(false);
    } else if (durationInMinutes < 30) {
      // console.log("The time difference is less than 30 minutes.");
      if (Platform.OS === "web") {
        alert("Minimum 30 minutes is required");
      } else {
        Alert.alert("Minimum 30 minutes is required");
      }
      setLoading(false);
    } else {
      const res = await getNurseAvaJobDate(
        authToken,
        nurseUserId,
        convertDate(timeOff?.startDate),
        convertDate(timeOff?.endDate),
        moment(startTime).format("hh:mm a"),
        moment(endTime).format("hh:mm a"));
      // console.log("getNurseAvaJobDate", res)
      if (res?.availableTime) {
        let sDate = moment(timeOff.startDate).format("YYYY-MM-DD")
        let eDate = moment(timeOff.endDate).format("YYYY-MM-DD")
        try {
          const response = await postNurseTimeOff(
            authToken,
            nurseUserId,
            timeOff.timeOffReason,
            sDate,
            fullDay ? "" : GetDateMergedTime(timeOff.startDate.toString(), startTime.toString()).toString(),
            eDate,
            fullDay ? "" : GetDateMergedTime(timeOff.endDate.toString(), endTime.toString()).toString(),
            timeOff.alternateContact,
            timeOff.phoneNum,
            user?.organization,
            user?.location_id,
            timeOff.comments,
            "Approved",
            "",
            authUserId.toString()
          );
          socket?.emit("tableTimeOffInsert",
            {
              table: "NurseTimeOff",
              nurseTableID: nurseUserId
            })
          if (Platform.OS !== "web") {
            await sendNewPushNotification({
              expoPushToken: user?.mobileId,
              job: `Time Off Request(${DateFormat(res?.startDate)})`,
              typeMessage: `Time off Request Approved.`,
              screen: "TimeOffScreen",
            });
          } else {
            sendNotification(authToken, {
              expoPushToken: user?.mobileId,
              title: `Time Off Request(${DateFormat(res?.startDate)})  `,
              body: `Time off Request Approved.`,
              data: { screen: "TimeOffScreen" },
            })
          }
          await postNurseNotification(
            authToken,
            nurseUserId,
            "",
            `Time Off Request(${DateFormat(res?.startDate)})`,
            `Time off Request Approved.`,
            "TimeOffScreen",
            {
              id: "",
            },
            false,
            false,
            "green",
            ""
          );
          socket?.emit("tableNotificationInsert",
            {
              table: "NurseNotificationTable",
              id: nurseUserId
            })
          if (Platform.OS === "web") {
            alert("Success\nTime Off request approved successfully.");
          } else {
            Alert.alert("Success", "Time Off request approved successfully.");
          }
          setLoading(false);
          setFetchAgainTimeOffLisNurse(!fetchAgainTimeOffListNurse)
          props?.navigation.goBack();
        } catch (error) {
          console.log("data save error:", error);
          setLoading(false);
        }
      } else {
        // console.log(res);
        if (Platform.OS === "web") {
          alert(`Date Conflict.Choose a different date.`);
        } else {
          Alert.alert(`Date Conflict.Choose a different date.`);
        }
        setLoading(false);
      }
    }
  };

  const actionTimeOff = async (approveStatus) => {
    setLoading(true);
    if (approveStatus === "Approved") {
      const resp = await getNurseAvaJobDate(
        authToken,
        nurseUserId,
        convertDate(timeOff?.startDate),
        convertDate(timeOff?.endDate),
        moment(startTime).format("hh:mm a"),
        moment(endTime).format("hh:mm a"));

      if (resp?.availableTime) {
        try {
          console.log(authUserId)
          const resTime = await updateNurseTimeOff(
            authToken,
            timeOffData?.id,
            {
              status: approveStatus,
              comments: timeOff.comments,
              approved_manager: authUserId?.toString(),
            })

          socket?.emit("tableTimeOffInsert",
            {
              table: "NurseTimeOff",
              nurseTableID: nurseUserId
            })
          if (Platform.OS !== "web") {
            await sendNewPushNotification({
              expoPushToken: user?.mobileId,
              job:
                `Time Off Request(${DateFormat(resTime?.startDate)})`,
              typeMessage: `Time off Request Approved.`,
              screen: "TimeOffScreen",
            });
          } else {
            sendNotification(authToken, {
              expoPushToken: user?.mobileId,
              title:
                `Time Off Request(${DateFormat(resTime?.startDate)})`,
              body: `Time off Request Approved.`,
              data: { screen: "TimeOffScreen" },
            })
          }
          await postNurseNotification(
            authToken,
            Number(nurseUserId),
            "",
            `Time Off Request(${DateFormat(resTime?.startDate)})`,
            `Time off Request Approved.`,
            "TimeOffScreen",
            {
              id: "",
            },
            false,
            false,
            "green",
            ""
          ).catch(e => console.log("error", e, nurseUserId));
          socket?.emit("tableNotificationInsert",
            {
              table: "NurseNotificationTable",
              id: nurseUserId
            })
          getFacilityLocationDetail()
            .then(async (facilitiesData) => {
              for (const facility of facilitiesData) {
                await postFacilityNotification(
                  authToken,
                  facility?.id,
                  "",
                  "Time Off Request Approved",
                  `Time Off Request(${convertDate(resTime?.startDate)}) - ${user?.firstName + " " + user?.lastName
                  } Approved By ${facilityData?.firstName} ${facilityData?.lastName} `,
                  "RequestTimeOffScreen",
                  {
                    timeOffId: resTime.id,
                    userId: nurseUserId
                  },
                  false,
                  false,
                  "green",
                  ""
                );
                socket?.emit("tableNotificationInsert",
                  {
                    table: "FacilityNotificationTable",
                    id: facility?.id,
                  })
                if (facility?.mobileId) {
                  if (Platform.OS === "web") {
                    sendNotification(authToken, {
                      expoPushToken: user?.mobileId,
                      title: "Time Off Request Approved",
                      body: `Time Off Request(${convertDate(resTime?.startDate)}) - ${user?.firstName + " " + user?.lastName
                        } Approved By ${facilityData?.firstName} ${facilityData?.lastName} `,
                      data: { screen: "NotificationScreen" },
                    })
                  } else {
                    await sendNewPushNotification({
                      expoPushToken: facility?.mobileId,
                      job: "Time Off Request Approved",
                      typeMessage: `Time Off Request(${convertDate(resTime?.startDate)}) - ${user?.firstName + " " + user?.lastName
                        } Approved By ${facilityData?.firstName} ${facilityData?.lastName} `,
                      screen: "NotificationScreen",
                    });
                  }
                }
              }
            })
            .catch((error) => console.error(error));
          if (Platform.OS === "web") {
            alert("Success\nTime Off request approved successfully.");
          } else {
            Alert.alert("Success", "Time Off request approved successfully.");
          }
          setLoading(false);
          setFetchAgainTimeOffLisNurse(!fetchAgainTimeOffListNurse)
          props?.navigation.goBack();
        } catch (error) {
          console.log("data save error:", error);
          setLoading(false);
        }
      } else {
        if (Platform.OS === "web") {
          alert(`Date Conflict.Choose a different date.`);
        } else {
          Alert.alert(`Date Conflict.Choose a different date.`);
        }
        setLoading(false);
      }
    } else {
      try {
        const resTime = await updateNurseTimeOff(
          authToken,
          timeOffData?.id,
          {
            status: approveStatus,
            comments: timeOff.comments,
            approved_manager: authUserId?.toString(),
            nurseCancelNotes: timeOffData?.nurseCancelNotes
              ? timeOffData?.nurseCancelNotes
              : cancellationReason,
          })
        socket?.emit("tableTimeOffInsert",
          {
            table: "NurseTimeOff",
            nurseTableID: nurseUserId
          })
        if (Platform.OS !== "web") {
          await sendNewPushNotification({
            expoPushToken: user?.mobileId,
            job: `Time Off Request(${DateFormat(resTime?.startDate)})`,
            typeMessage: `Time off Request Not Approved.`,
            screen: "TimeOffScreen",
          });
        } else {
          sendNotification(authToken, {
            expoPushToken: user?.mobileId,
            title: `Time Off Request(${DateFormat(resTime?.startDate)})`,
            body: `Time off Request Not Approved.`,
            data: { screen: "TimeOffScreen" },
          })
        }
        await postNurseNotification(
          authToken,
          Number(nurseUserId),
          "",
          `Time Off Request(${DateFormat(resTime?.startDate)})`,
          `Time off Request Not Approved`,
          "TimeOffScreen",
          {
            id: "",
          },
          false,
          false,
          "red",
          ""
        );
        socket?.emit("tableNotificationInsert",
          {
            table: "NurseNotificationTable",
            id: nurseUserId
          })
        getFacilityLocationDetail()
          .then(async (facilitiesData) => {
            for (const facility of facilitiesData) {
              await postFacilityNotification(
                authToken,
                facility?.id,
                "",
                "Time Off Request Not-Approved",
                `Time Off Request(${convertDate(resTime?.startDate)}) - ${user?.firstName + " " + user?.lastName
                } Not - Approved By ${facilityData?.firstName} ${facilityData?.lastName} `,
                "RequestTimeOffScreen",
                {
                  timeOffId: resTime.id,
                  userId: nurseUserId
                },
                false,
                false,
                "green",
                ""
              );
              socket?.emit("tableNotificationInsert",
                {
                  table: "FacilityNotificationTable",
                  id: facility?.id,
                })
              if (facility?.mobileId) {
                if (Platform.OS === "web") {
                  sendNotification(authToken, {
                    expoPushToken: user?.mobileId,
                    title: "Time Off Request Not-Approved",
                    body: `Time Off Request(${convertDate(resTime?.startDate)}) - ${user?.firstName + " " + user?.lastName
                      } Not - Approved By ${facilityData?.firstName} ${facilityData?.lastName} `,
                    data: { screen: "NotificationScreen" },
                  })
                } else {
                  await sendNewPushNotification({
                    expoPushToken: facility?.mobileId,
                    job: "Time Off Request Not-Approved",
                    typeMessage: `Time Off Request(${convertDate(resTime?.startDate)}) - ${user?.firstName + " " + user?.lastName
                      } Not - Approved By ${facilityData?.firstName} ${facilityData?.lastName} `,
                    screen: "NotificationScreen",
                  });
                }
              }
            }
          })
          .catch((error) => console.error(error));
        if (Platform.OS === "web") {
          alert("Success\nTime Off request not-approved successfully.");
        } else {
          Alert.alert(
            "Success",
            "Time Off request not-approved successfully."
          );
        }
        setLoading(false);
        setFetchAgainTimeOffLisNurse(!fetchAgainTimeOffListNurse)
        props?.navigation.goBack();
      } catch (error) {
        console.log("data save error:", error);
        setLoading(false);
      }
    }
  };

  const editTimeOff = async () => {
    setLoading(true);

    // console.log(moment(timeOff.startDate).format("MM-DD-YYYY"), moment(timeOffData?.startDate).format("MM-DD-YYYY"),
    //   moment(timeOff.endDate).format("MM-DD-YYYY"), moment(timeOffData?.endDate).format("MM-DD-YYYY"),
    //   moment(startTime).format("hh:mm a"), moment(timeOffData.startTime).format("hh:mm a"),
    //   moment(endTime).format("hh:mm a"), moment(timeOffData.endTime).format("hh:mm a"))

    if (moment(timeOff.startDate).format("MM-DD-YYYY") !== moment(timeOffData?.startDate).format("MM-DD-YYYY") ||
      moment(timeOff.endDate).format("MM-DD-YYYY") !== moment(timeOffData?.endDate).format("MM-DD-YYYY") ||
      moment(startTime).format("hh:mm a") !== moment(timeOffData.startTime).format("hh:mm a") ||
      moment(endTime).format("hh:mm a") !== moment(timeOffData.endTime).format("hh:mm a")) {
      // console.log("Time Change");
      const resp = await getNurseAvaJobDateAllTimeOff(
        authToken,
        nurseUserId,
        convertDate(timeOff?.startDate),
        convertDate(timeOff?.endDate),
        moment(startTime).format("hh:mm a"),
        moment(endTime).format("hh:mm a"));
      // console.log(resp);
      if (resp?.availableTime) {
        let sDate = moment(timeOff.startDate).format("YYYY-MM-DD")
        let eDate = moment(timeOff.endDate).format("YYYY-MM-DD")
        try {
          const res = await updateNurseTimeOff(authToken, timeOffData?.id,
            {
              timeOffReason: timeOff.timeOffReason,
              startDate: sDate,
              endDate: eDate,
              startTime: fullDay ? "" : GetDateMergedTime(timeOff.startDate.toString(), startTime.toString()).toString(),
              endTime: fullDay ? "" : GetDateMergedTime(timeOff.endDate.toString(), endTime.toString()).toString(),
              comments: timeOff.comments,
              alternateContactNumber: timeOff.alternateContact,
              approved_manager: authUserId?.toString(),
            })

          socket?.emit("tableTimeOffInsert",
            {
              table: "NurseTimeOff",
              nurseTableID: nurseUserId
            })
          if (Platform.OS !== "web") {
            await sendNewPushNotification({
              expoPushToken: user?.mobileId,
              job:
                DateFormat(res?.startDate) === DateFormat(res?.endDate)
                  ? `Time Off Update Request(${DateFormat(res?.startDate)})`
                  : `Time Off Update Request(${DateFormat(
                    res?.startDate
                  )
                  } - ${DateFormat(res?.endDate)})`,
              typeMessage: `Time off update request processed successfully.`,
              screen: "TimeOffScreen",
            });
          } else {
            sendNotification(authToken, {
              expoPushToken: user?.mobileId,
              title: DateFormat(res?.startDate) === DateFormat(res?.endDate)
                ? `Time Off Update Request(${DateFormat(res?.startDate)})`
                : `Time Off Update Request(${DateFormat(
                  res?.startDate
                )
                } - ${DateFormat(res?.endDate)})`,
              body: `Time off update request processed successfully.`,
              data: { screen: "TimeOffScreen" },
            })
          }
          await postNurseNotification(
            authToken,
            Number(nurseUserId),
            "",
            DateFormat(res?.startDate) === DateFormat(res?.endDate)
              ? `Time Off Request(${DateFormat(res?.startDate)})`
              : `Time Off Request(${DateFormat(res?.startDate)} - ${DateFormat(
                res?.endDate
              )
              })`,
            `Time off update request processed successfully.`,
            "TimeOffScreen",
            {
              id: "",
            },
            false,
            false,
            "green",
            ""
          );
          socket?.emit("tableNotificationInsert",
            {
              table: "NurseNotificationTable",
              id: nurseUserId
            })
          getFacilityLocationDetail()
            .then(async (facilitiesData) => {
              for (const facility of facilitiesData) {
                await postFacilityNotification(
                  authToken,
                  facility?.id,
                  "",
                  "Time off Update",
                  `Time Off Update Request ${moment(res?.startDate).format("MM-DD-YYYY")} - ${user?.firstName + " " + user?.lastName
                  } Updated By ${facilityData?.firstName} ${facilityData?.lastName} `,
                  "RequestTimeOffScreen",
                  {
                    timeOffId: res.id,
                    userId: nurseUserId
                  },
                  false,
                  false,
                  "green",
                  ""
                );
                socket?.emit("tableNotificationInsert",
                  {
                    table: "FacilityNotificationTable",
                    id: facility?.id,
                  })
                if (facility?.mobileId) {
                  if (Platform.OS === "web") {
                    sendNotification(authToken, {
                      expoPushToken: user?.mobileId,
                      title: "Time off Update",
                      body: `Time Off Update Request ${moment(res?.startDate).format("MM-DD-YYYY")} - ${user?.firstName + " " + user?.lastName
                        } Updated By ${facilityData?.firstName} ${facilityData?.lastName} `,
                      data: { screen: "NotificationScreen" },
                    })
                  } else {
                    await sendNewPushNotification({
                      expoPushToken: facility?.mobileId,
                      job: "Time off Update",
                      typeMessage: `Time Off Update Request ${moment(res?.startDate).format("MM-DD-YYYY")} - ${user?.firstName + " " + user?.lastName
                        } Updated By ${facilityData?.firstName} ${facilityData?.lastName} `,
                      screen: "NotificationScreen",
                    });
                  }
                }
              }
            })
            .catch((error) => console.error(error));
          setLoading(false);
          if (Platform.OS === "web") {
            alert("Success\nTime Off successfully edited.");
          } else {
            Alert.alert("Success", "Time Off successfully edited.");
          }
          setFetchAgainTimeOffLisNurse(!fetchAgainTimeOffListNurse)
          props?.navigation.goBack();
        } catch (error) {
          console.log("data save error:", error);
          setLoading(false);
        }
      } else {
        if (Platform.OS === "web") {
          alert(`Date Conflict.Choose a different date.`);
        } else {
          Alert.alert(`Date Conflict.Choose a different date.`);
        }
        setLoading(false);
      }
    }
    else {
      let sDate = moment(timeOff.startDate).format("YYYY-MM-DD")
      let eDate = moment(timeOff.endDate).format("YYYY-MM-DD")
      // console.log("No Time Change",
      //   {
      //     timeOffReason: timeOff.timeOffReason,
      //     startDate: sDate,
      //     endDate: eDate,
      //     startTime: fullDay ? "" : GetDateMergedTime(timeOff.startDate.toString(), startTime.toString()).toString(),
      //     endTime: fullDay ? "" : GetDateMergedTime(timeOff.endDate.toString(), endTime.toString()).toString(),
      //     comments: timeOff.comments,
      //     alternateContactNumber: timeOff.alternateContact,
      //     approved_manager: authUserId?.toString(),
      //   }
      // )
      try {
        const res = await updateNurseTimeOff(authToken, timeOffData?.id,
          {
            timeOffReason: timeOff.timeOffReason,
            startDate: sDate,
            endDate: eDate,
            startTime: fullDay ? "" : GetDateMergedTime(timeOff.startDate.toString(), startTime.toString()).toString(),
            endTime: fullDay ? "" : GetDateMergedTime(timeOff.endDate.toString(), endTime.toString()).toString(),
            comments: timeOff.comments,
            alternateContactNumber: timeOff.alternateContact,
            approved_manager: authUserId?.toString(),
          })
        socket?.emit("tableTimeOffInsert",
          {
            table: "NurseTimeOff",
            nurseTableID: nurseUserId
          })
        if (Platform.OS !== "web") {
          await sendNewPushNotification({
            expoPushToken: user?.mobileId,
            job:
              DateFormat(res?.startDate) === DateFormat(res?.endDate)
                ? `Time Off Update Request(${DateFormat(res?.startDate)})`
                : `Time Off Update Request(${DateFormat(
                  res?.startDate
                )
                } - ${DateFormat(res?.endDate)})`,
            typeMessage: `Time off update request processed successfully.`,
            screen: "TimeOffScreen",
          });
        } else {
          sendNotification(authToken, {
            expoPushToken: user?.mobileId,
            title: DateFormat(res?.startDate) === DateFormat(res?.endDate)
              ? `Time Off Update Request(${DateFormat(res?.startDate)})`
              : `Time Off Update Request(${DateFormat(
                res?.startDate
              )
              } - ${DateFormat(res?.endDate)})`,
            body: `Time off update request processed successfully.`,
            data: { screen: "TimeOffScreen" },
          })
        }
        await postNurseNotification(
          authToken,
          Number(nurseUserId),
          "",
          DateFormat(res?.startDate) === DateFormat(res?.endDate)
            ? `Time Off Request(${DateFormat(res?.startDate)})`
            : `Time Off Request(${DateFormat(res?.startDate)} - ${DateFormat(
              res?.endDate
            )
            })`,
          `Time off update request processed successfully.`,
          "TimeOffScreen",
          {
            id: "",
          },
          false,
          false,
          "green",
          ""
        );
        socket?.emit("tableNotificationInsert",
          {
            table: "NurseNotificationTable",
            id: nurseUserId
          })
        getFacilityLocationDetail()
          .then(async (facilitiesData) => {
            for (const facility of facilitiesData) {
              await postFacilityNotification(
                authToken,
                facility?.id,
                "",
                "Time off Update",
                `Time Off Update Request ${moment(res?.startDate).format("MM-DD-YYYY")} - ${user?.firstName + " " + user?.lastName
                } Updated By ${facilityData?.firstName} ${facilityData?.lastName} `,
                "RequestTimeOffScreen",
                {
                  timeOffId: res.id,
                  userId: nurseUserId
                },
                false,
                false,
                "green",
                ""
              );
              socket?.emit("tableNotificationInsert",
                {
                  table: "FacilityNotificationTable",
                  id: facility?.id,
                })
              if (facility?.mobileId) {
                if (Platform.OS === "web") {
                  sendNotification(authToken, {
                    expoPushToken: user?.mobileId,
                    title: "Time off Update",
                    body: `Time Off Update Request ${moment(res?.startDate).format("MM-DD-YYYY")} - ${user?.firstName + " " + user?.lastName
                      } Updated By ${facilityData?.firstName} ${facilityData?.lastName} `,
                    data: { screen: "NotificationScreen" },
                  })
                } else {
                  await sendNewPushNotification({
                    expoPushToken: facility?.mobileId,
                    job: "Time off Update",
                    typeMessage: `Time Off Update Request ${moment(res?.startDate).format("MM-DD-YYYY")} - ${user?.firstName + " " + user?.lastName
                      } Updated By ${facilityData?.firstName} ${facilityData?.lastName} `,
                    screen: "NotificationScreen",
                  });
                }
              }
            }
          })
          .catch((error) => console.error(error));
        setLoading(false);
        if (Platform.OS === "web") {
          alert("Success\nTime Off successfully edited.");
        } else {
          Alert.alert("Success", "Time Off successfully edited.");
        }
        setFetchAgainTimeOffLisNurse(!fetchAgainTimeOffListNurse)
        props?.navigation.goBack();
      } catch (error) {
        console.log("data save error:", error);
        setLoading(false);
      }
    }
  };

  const currentDate = moment();
  const isPastDate = (date) => {
    return date.isBefore(currentDate, "day");
  };

  const disabledDates = (date) => {
    return isPastDate(date);
  };

  const isStartPastDate = (date) => {
    return date.isBefore(moment(timeOff.startDate), "day");
  };

  const disabledStartDates = (date) => {
    return isStartPastDate(date);
  };

  const disabledDatesTextStyle = { color: "#CCCCCC" };

  const deleteTimeOff = async (element) => {
    try {
      const res = await updateNurseTimeOff(authToken, timeOffData?.id,
        {
          status: "Cancelled",
          nurseCancelNotes: timeOffData?.nurseCancelNotes
            ? timeOffData?.nurseCancelNotes
            : cancellationReason,
          approved_manager: authUserId?.toString(),
        })
      socket?.emit("tableTimeOffInsert",
        {
          table: "NurseTimeOff",
          nurseTableID: nurseUserId
        })
      if (Platform.OS !== "web") {
        await sendNewPushNotification({
          expoPushToken: user?.mobileId,
          job: `Time Off Cancel Request`,
          typeMessage: `Date:${moment(element?.startDate).format("MM-DD-YYYY")}.Reason:${element?.timeOffReason},Time off cancel request processed successfully.`,
          screen: "TimeOffScreen",
        });
      } else {
        sendNotification(authToken, {
          expoPushToken: user?.mobileId,
          title: `Time Off Cancel Request`,
          body: `Date:${moment(element?.startDate).format("MM-DD-YYYY")}.Reason:${element?.timeOffReason},Time off cancel request processed successfully.`,
          data: { screen: "TimeOffScreen" },
        })
      }
      await postNurseNotification(
        authToken,
        Number(nurseUserId),
        "",
        `Time Off Cancel Request`,
        `Date:${moment(element?.startDate).format("MM-DD-YYYY")}.Reason:${element?.timeOffReason},Time off cancel request processed successfully.`,
        "TimeOffScreen",
        {
          id: "",
        },
        false,
        false,
        "red",
        ""
      );
      socket?.emit("tableNotificationInsert",
        {
          table: "NurseNotificationTable",
          id: nurseUserId
        })
      getFacilityLocationDetail()
        .then(async (facilitiesData) => {
          for (const facility of facilitiesData) {
            await postFacilityNotification(
              authToken,
              facility?.id,
              "",
              "Time off cancel request",
              `Date:${moment(element?.startDate).format("MM-DD-YYYY")}.Reason:${element?.timeOffReason},Time off cancel request processed By ${facilityData?.firstName} ${facilityData?.lastName} `,
              "TimeOffScreenNurse",
              {
                userId: nurseUserId
              },
              false,
              false,
              "green",
              ""
            );
            socket?.emit("tableNotificationInsert",
              {
                table: "FacilityNotificationTable",
                id: facility?.id,
              })
            if (facility?.mobileId) {
              if (Platform.OS === "web") {
                sendNotification(authToken, {
                  expoPushToken: user?.mobileId,
                  title: "Time off cancel request",
                  body: `Date:${moment(element?.startDate).format("MM-DD-YYYY")}.Reason:${element?.timeOffReason},Time off cancel request processed By ${facilityData?.firstName} ${facilityData?.lastName} `,
                  data: { screen: "NotificationScreen" },
                })
              } else {
                await sendNewPushNotification({
                  expoPushToken: facility?.mobileId,
                  job: "Time off cancel request",
                  typeMessage: `Date:${moment(element?.startDate).format("MM-DD-YYYY")}.Reason:${element?.timeOffReason},Time off cancel request processed By ${facilityData?.firstName} ${facilityData?.lastName} `,
                  screen: "NotificationScreen",
                });
              }
            }
          }
        })
        .catch((error) => console.error(error));
      setCancellationReason("")
      setFetchAgainTimeOffLisNurse(!fetchAgainTimeOffListNurse)
      props?.navigation.goBack();
    } catch (error) {
      console.log("data save error:", error);
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={styles.container}
    >
      {timeOffData?.status === "Cancelled" ?
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Text>This Time Off is already cancelled.</Text>
        </View> :
        <ScrollView scrollEnabled={true} showsVerticalScrollIndicator={false}>
          <View style={{ marginTop: 10 }}>
            {timeOffData?.nurseCancelNotes && <View>
              <Text
                style={{
                  marginBottom: 5,
                  marginTop: 10,
                  fontSize: 12,
                  color: "#737373",
                  marginHorizontal: 20,
                  fontWeight: "600"
                }}
              >
                Cancellation Message = <Text
                  style={{
                    marginBottom: 5,
                    marginTop: 10,
                    fontSize: 12,
                    color: "#737373",
                    marginHorizontal: 20,
                    fontWeight: "400"
                  }}
                >
                  {timeOffData?.nurseCancelNotes}
                </Text>
              </Text>
            </View>}

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 10,
                marginHorizontal: 20,
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    color: "#737373",
                  }}
                >
                  User <Text style={{ color: "red" }}>*</Text>
                </Text>
                {userId === undefined ? (
                  <TouchableOpacity
                    onPress={() => setSelectUser(true)}
                    style={{
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 10,
                      marginTop: 10,
                      marginRight: 5,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        color: "#737373",
                      }}
                    >
                      Select User
                    </Text>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    style={{
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                    }}
                    onPress={() =>
                      onUserDetailNavigate({
                        id: user?.uniqueId,
                      })
                    }
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          borderWidth: 1,
                          borderRadius: 10,
                          borderColor: "#2775BD",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#fff",
                          width: 45,
                          height: 45,
                          overflow: "hidden",
                        }}
                      >
                        {(user?.profileImage) && (
                          <Image
                            source={{
                              uri: user?.profileImage
                            }}
                            style={{
                              width: 43,
                              height: 43,
                              borderRadius: 10,
                            }}

                          />
                        )}
                        {(user?.profileImage === null ||
                          user?.profileImage === undefined ||
                          user?.profileImage === "") && (
                            <Image
                              source={require("../../../../assets/images/icon.jpg")}
                              style={{ width: 45, height: 45 }}
                            />
                          )}
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flex: 1,
                        }}
                      >
                        <View
                          style={{
                            marginLeft: 10,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text>
                            {user?.firstName} {user?.lastName}
                          </Text>
                          <Text
                            style={{
                              color: "#8d8d8d",
                              fontWeight: "600",
                              fontSize: 12,
                            }}
                          >
                            {user?.primaryLicenseType}
                          </Text>
                        </View>
                        {props.route.params?.data?.userId === undefined && (
                          <TouchableOpacity
                            style={{
                              marginLeft: 10,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                            onPress={() => {
                              setNurseUserId(undefined);
                              setUser(undefined);
                              setTimeOff({
                                ...timeOff,
                                phoneNum: "",
                                alternateContact: "",
                              });
                            }}
                          >
                            <AntDesign name="close" size={22} color={"red"} />
                          </TouchableOpacity>
                        )}
                      </View>
                    </View>
                  </TouchableOpacity>
                )}
              </View>
            </View>

            <Text
              style={{
                marginBottom: 5,
                marginTop: 10,
                fontSize: 12,
                color: "#737373",
                marginHorizontal: 20,
              }}
            >
              Time Off Reason <Text style={{ color: "red" }}>*</Text>
            </Text>

            <View
              style={{
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                marginHorizontal: 20,
                paddingRight: 10,
                justifyContent: "space-between",
              }}
            >
              <TextInput
                style={{
                  width: "90%",
                  padding: 2,
                  paddingLeft: 5,
                  fontSize: 12,
                  color: "#737373",
                }}
                multiline
                onChangeText={(text) =>
                  setTimeOff({ ...timeOff, timeOffReason: text })
                }
                value={timeOff.timeOffReason}
                keyboardType={"default"}
                autoCapitalize="sentences"
                placeholderTextColor="#b3b3b3"
                placeholder={"Time Off Reason"}
                editable={timeOffData?.status === "Pending" ? false : true}
              />
              <View
                style={{
                  height: 40,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {timeOff.timeOffReason ? (
                  <TouchableOpacity
                    onPress={() => setTimeOff({ ...timeOff, timeOffReason: "" })}
                    style={{ marginLeft: 3 }}
                    disabled={timeOffData?.status === "Pending" ? true : false}
                  >
                    <Ionicons name="close-sharp" size={22} color="#808080" />
                  </TouchableOpacity>
                ) : null}
              </View>
            </View>
          </View>

          <View
            style={{
              marginHorizontal: 20,
              marginTop: 10,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  marginBottom: 5,
                  fontSize: 12,
                  color: "#737373",
                }}
              >
                Full Day
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CustomToggle
                  state={fullDay}
                  onValueChange={(value) =>
                    setFullDay(value)
                  }
                  disabled={timeOffData?.status === "Pending" ? true : false}
                />
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  marginBottom: 5,
                  fontSize: 12,
                  color: "#737373",
                }}
              >
                Partial Day
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CustomToggle
                  state={!fullDay}
                  onValueChange={(value) =>
                    setFullDay(!value)
                  }
                  disabled={timeOffData?.status === "Pending" ? true : false}
                />
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 10,
              marginHorizontal: 20,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "#737373",
                }}
              >
                Start Date <Text style={{ color: "red" }}>*</Text>
              </Text>
              <TouchableOpacity
                onPress={() => setShowCalendar(true)}
                style={{
                  borderWidth: 1,
                  borderColor: "#e6e6e6",
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 10,
                  marginTop: 10,
                  marginRight: 5,
                }}
                disabled={timeOffData?.status === "Pending" ? true : false}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    color: "#737373",
                  }}
                >
                  {moment(timeOff.startDate).format("MM-DD-YYYY")}
                </Text>
                <AntDesign name="calendar" size={20} color="#808080" />
              </TouchableOpacity>
            </View>
            {!fullDay && <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "#737373",
                }}
              >
                Start Time <Text style={{ color: "red" }}>*</Text>
              </Text>
              <TouchableOpacity
                onPress={() =>
                  setShowTimeWeb(true)
                }
                style={{
                  flex: 1,
                  marginLeft: 5,
                  borderWidth: 1,
                  borderColor: "#e6e6e6",
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 10,
                  marginTop: 10,
                }}
                disabled={timeOffData?.status === "Pending" ? true : false}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    color: "#737373",
                  }}
                >
                  {moment(startTime).format("h:mm a")}
                </Text>
                <MaterialIcons name="access-time" size={20} color="#808080" />
              </TouchableOpacity>
            </View>}
          </View>

          {!fullDay &&
            <View
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginHorizontal: 10,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginHorizontal: 20,
                  marginTop: 10
                }}
              >
                <Text
                  style={[
                    {
                      fontSize: 12,
                      color: "#737373",
                    },
                  ]}
                >
                  Select Hour's (
                  <Text
                    style={[
                      {
                        fontSize: 12,
                        color: "#000",
                        fontWeight: "bold",
                        marginBottom: 10,
                      },
                    ]}
                  >
                    {selectedHours !== "" &&
                      `${selectedHours} ${selectedHours >= 2 ? "Hour(s)" : "Hour"
                      } `}
                  </Text>
                  )<Text style={{ color: "red" }}>*</Text>
                </Text>
              </View>
              {/* <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  top: 10,
                }}
              >
                <Text style={{ fontSize: 12 }}>1 H</Text>
                <Text style={{ fontSize: 12 }}>12 H</Text>
              </View> */}
              <View>
                {/* <CustomSliderFilter
                  sliderWidth={width / 1.3}
                  min={1}
                  max={12}
                  step={1}
                  type={""}
                  label={true}
                  color={"#00b359"}
                  onValueChange={(range) => {
                    setSelectedHours(range.max);

                    let date = new Date();
                    let dateEnd = new Date();

                    let nowEnd = new Date(startTime);
                    let selectedMinuteTime = nowEnd.getMinutes().toString().padStart(2, "0");
                    let selectedAPTime = nowEnd.getHours() >= 12 ? "PM" : "AM";
                    let selectedHourTime = nowEnd.getHours() === 0
                      ? 12
                      : nowEnd.getHours() > 12
                        ? nowEnd.getHours() - 12
                        : nowEnd.getHours()

                    date.setHours(
                      selectedAPTime === "AM" && selectedHourTime === 12
                        ? 0
                        : selectedAPTime === "PM" && selectedHourTime === 12
                          ? 12
                          : selectedHourTime !== 12 && selectedAPTime === "PM"
                            ? parseInt(selectedHourTime) + 12
                            : parseInt(selectedHourTime)
                    );
                    date.setMinutes(selectedMinuteTime);

                    // Add 8 hours to dateEnd based on date
                    dateEnd.setFullYear(date.getFullYear());
                    dateEnd.setMonth(date.getMonth());
                    dateEnd.setDate(date.getDate());
                    dateEnd.setHours(date.getHours() + range.max);
                    dateEnd.setMinutes(date.getMinutes());

                    // Determine if it's AM or PM
                    let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

                    if (selectedAPTime === "PM" && meridiem === "AM") {
                      let endDate = moment(timeOff.startDate);
                      endDate.add(1, "day")
                      setStartTime(date)
                      setEndTime(dateEnd)
                      setTimeOff({
                        ...timeOff,
                        endDate: endDate,
                      });
                    } else {
                      setStartTime(date)
                      setEndTime(dateEnd)
                      setTimeOff({
                        ...timeOff,
                        endDate: timeOff.startDate,
                      });
                    }
                  }}
                  valueMin={0}
                  valueMax={parseInt(selectedHours)}
                  minDrag={false}
                /> */}
                <Text
                  style={{
                    fontSize: 12,
                    color: "#737373",
                    marginLeft: 10,
                    marginBottom: 2
                  }}
                >
                  Duration
                </Text>
                <CustomSliderFilterDropDown
                  sliderWidth={10}
                  min={1}
                  max={12}
                  step={1}
                  type={""}
                  label={true}
                  color={"#737373"}
                  onValueChange={(range) => {
                    setSelectedHours(range.max);

                    let date = new Date();
                    let dateEnd = new Date();

                    let nowEnd = new Date(startTime);
                    let selectedMinuteTime = nowEnd.getMinutes().toString().padStart(2, "0");
                    let selectedAPTime = nowEnd.getHours() >= 12 ? "PM" : "AM";
                    let selectedHourTime = nowEnd.getHours() === 0
                      ? 12
                      : nowEnd.getHours() > 12
                        ? nowEnd.getHours() - 12
                        : nowEnd.getHours()

                    date.setHours(
                      selectedAPTime === "AM" && selectedHourTime === 12
                        ? 0
                        : selectedAPTime === "PM" && selectedHourTime === 12
                          ? 12
                          : selectedHourTime !== 12 && selectedAPTime === "PM"
                            ? parseInt(selectedHourTime) + 12
                            : parseInt(selectedHourTime)
                    );
                    date.setMinutes(selectedMinuteTime);

                    // Add 8 hours to dateEnd based on date
                    dateEnd.setFullYear(date.getFullYear());
                    dateEnd.setMonth(date.getMonth());
                    dateEnd.setDate(date.getDate());
                    dateEnd.setHours(date.getHours() + range.max);
                    dateEnd.setMinutes(date.getMinutes());

                    // Determine if it's AM or PM
                    let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

                    if (selectedAPTime === "PM" && meridiem === "AM") {
                      let endDate = moment(timeOff.startDate);
                      endDate.add(1, "day")
                      setStartTime(date)
                      setEndTime(dateEnd)
                      setTimeOff({
                        ...timeOff,
                        endDate: endDate,
                      });
                    } else {
                      setStartTime(date)
                      setEndTime(dateEnd)
                      setTimeOff({
                        ...timeOff,
                        endDate: timeOff.startDate,
                      });
                    }
                  }}
                  valueMin={0}
                  valueMax={parseInt(selectedHours)}
                  minDrag={false}
                  dType={"time"}
                />
              </View>
            </View>}

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 10,
              marginHorizontal: 20,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "#737373",
                }}
              >
                End Date
              </Text>
              <TouchableOpacity
                // onPress={() => setShowCalendarEnd(true)}
                style={{
                  borderWidth: 1,
                  borderColor: "#e6e6e6",
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 10,
                  marginTop: 10,
                  marginRight: 5,
                }}
                disabled={timeOffData?.status === "Pending" ? true : false}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    color: "#737373",
                  }}
                >
                  {moment(timeOff.endDate).format("MM-DD-YYYY")}
                </Text>
                <AntDesign name="calendar" size={20} color="#808080" />
              </TouchableOpacity>
            </View>
            {!fullDay && <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "#737373",
                }}
              >
                End Time
              </Text>
              <TouchableOpacity
                // onPress={() =>
                // setShowTimeEndWeb(true)
                // }
                style={{
                  flex: 1,
                  marginLeft: 5,
                  borderWidth: 1,
                  borderColor: "#e6e6e6",
                  backgroundColor: "#f2f2f2",
                  borderRadius: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 10,
                  marginTop: 10,
                }}
                disabled={timeOffData?.status === "Pending" ? true : false}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    color: "#737373",
                  }}
                >
                  {moment(endTime).format("h:mm a")}
                </Text>
                <MaterialIcons name="access-time" size={20} color="#808080" />
              </TouchableOpacity>
            </View>}
          </View>

          <View
            style={{
              marginTop: 10,
              marginHorizontal: 20,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: "#737373",
              }}
            >
              Alternate Contact (Optional){" "}
            </Text>
            <View
              style={{
                height: 45,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                paddingRight: 10,
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  marginLeft: 5,
                  paddingHorizontal: 5,
                  borderRightWidth: 1,
                  borderColor: "#d9d9d9",
                  color: "#737373",
                }}
              >
                +1
              </Text>
              <TextInput
                onChangeText={(text) =>
                  setTimeOff({ ...timeOff, alternateContact: text })
                }
                value={timeOff.alternateContact}
                keyboardType={"decimal-pad"}
                autoCapitalize="none"
                placeholderTextColor="#b3b3b3"
                placeholder={"Enter Alternate Contact"}
                style={{
                  flex: 1,
                  height: 45,
                  borderRadius: 10,
                  paddingLeft: 10,
                  paddingRight: 5,
                  fontSize: 12,
                }}
                editable={timeOffData?.status === "Pending" ? false : true}
              />
              <View
                style={{
                  height: 40,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {timeOff.alternateContact ? (
                  <TouchableOpacity
                    onPress={() =>
                      setTimeOff({ ...timeOff, alternateContact: "" })
                    }
                    disabled={timeOffData?.status === "Pending" ? true : false}
                  >
                    <Ionicons name="close-sharp" size={22} color="#808080" />
                  </TouchableOpacity>
                ) : null}
              </View>
            </View>
          </View>

          <View
            style={{
              marginTop: 10,
              marginHorizontal: 20,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: "#737373",
              }}
            >
              Phone Number
            </Text>
            <View
              style={{
                height: 45,
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                paddingRight: 10,
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  marginLeft: 5,
                  paddingHorizontal: 5,
                  borderRightWidth: 1,
                  borderColor: "#d9d9d9",
                  color: "#737373",
                }}
              >
                +1
              </Text>
              <TextInput
                onChangeText={(text) =>
                  setTimeOff({ ...timeOff, phoneNum: text })
                }
                value={timeOff.phoneNum}
                keyboardType={"decimal-pad"}
                editable={false}
                autoCapitalize="none"
                placeholderTextColor="#b3b3b3"
                placeholder={"Enter Phone Number"}
                style={{
                  flex: 1,
                  height: 45,
                  borderRadius: 10,
                  paddingLeft: 10,
                  paddingRight: 5,
                  fontSize: 12,
                }}
              />
            </View>
          </View>
          {timeOffData !== undefined && !timeOffData?.nurseCancelNotes && <View>
            <Text
              style={{
                marginTop: 10,
                fontSize: 12,
                color: "#737373",
                marginHorizontal: 20,
              }}
            >
              Cancellation Reason
            </Text>

            <View
              style={{
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                marginHorizontal: 20,
                paddingRight: 10,
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <TextInput
                style={{
                  width: "90%",
                  padding: 2,
                  paddingLeft: 5,
                  fontSize: 12,
                  color: "#737373",
                }}
                multiline
                onChangeText={(text) =>
                  setCancellationReason(text)
                }
                value={cancellationReason}
                keyboardType={"default"}
                autoCapitalize="sentences"
                placeholderTextColor="#b3b3b3"
                placeholder={"Cancellation Reason"}
              // returnKeyType="next"
              />
              <View
                style={{
                  height: 40,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {cancellationReason ? (
                  <TouchableOpacity
                    onPress={() => setCancellationReason("")}
                    style={{ marginLeft: 3 }}
                  >
                    <Ionicons name="close-sharp" size={22} color="#808080" />
                  </TouchableOpacity>
                ) : null}
              </View>
            </View>
          </View>}
          <View>
            <Text
              style={{
                marginTop: 10,
                fontSize: 12,
                color: "#737373",
                marginHorizontal: 20,
              }}
            >
              Comments
            </Text>

            <View
              style={{
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                marginHorizontal: 20,
                paddingRight: 10,
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <TextInput
                style={{
                  width: "90%",
                  padding: 2,
                  paddingLeft: 5,
                  fontSize: 12,
                  color: "#737373",
                }}
                multiline
                onChangeText={(text) =>
                  setTimeOff({ ...timeOff, comments: text })
                }
                value={timeOff.comments}
                keyboardType={"default"}
                autoCapitalize="sentences"
                placeholderTextColor="#b3b3b3"
                placeholder={"Comments..."}
              // returnKeyType="next"
              />
              <View
                style={{
                  height: 40,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {timeOff.comments ? (
                  <TouchableOpacity
                    onPress={() => setTimeOff({ ...timeOff, comments: "" })}
                    style={{ marginLeft: 3 }}
                  >
                    <Ionicons name="close-sharp" size={22} color="#808080" />
                  </TouchableOpacity>
                ) : null}
              </View>
            </View>
          </View>

          {timeOffData?.status === "Pending" ? (
            <View
              style={{
                marginTop: 10,
                marginBottom: 100,
                marginHorizontal: 10,
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <View
                style={{
                  flex: 1,
                  paddingVertical: 3,
                  paddingHorizontal: 10,
                  marginHorizontal: 10,
                  marginTop: 10,
                  backgroundColor: "red",
                  borderRadius: 10,
                  elevation: 3,
                }}
              >
                <TouchableOpacity
                  style={{
                    paddingVertical: 3,
                    paddingHorizontal: 10,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => actionTimeOff("Not-Approved")}
                >
                  <Text
                    style={{ fontSize: 18, color: "#fff", fontWeight: "700" }}
                  >
                    {loading ? "Loading..." : "Not-Approved"}
                  </Text>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flex: 1,
                  paddingVertical: 3,
                  paddingHorizontal: 10,
                  marginHorizontal: 10,
                  marginTop: 10,
                  backgroundColor: "#00b359",
                  borderRadius: 10,
                  elevation: 3,
                }}
              >
                <TouchableOpacity
                  style={{
                    paddingVertical: 3,
                    paddingHorizontal: 10,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => actionTimeOff("Approved")}
                >
                  <Text
                    style={{ fontSize: 18, color: "#fff", fontWeight: "700" }}
                  >
                    {loading ? "Loading..." : "Approved"}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          ) : (
            <View
              style={{
                marginTop: 10,
                marginBottom: 100,
                marginHorizontal: 10,
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              {timeOffData?.status !== undefined && timeOffData?.status !== "Pending" &&
                <View
                  style={{
                    flex: 1,
                    paddingVertical: 3,
                    paddingHorizontal: 10,
                    marginHorizontal: 10,
                    marginTop: 10,
                    backgroundColor: "red",
                    borderRadius: 10,
                    elevation: 3,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      paddingVertical: 3,
                      paddingHorizontal: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() =>
                      Platform.OS === "web"
                        ? confirm(
                          "This will cancel the Time off, Continue?"
                        ).valueOf(true) && deleteTimeOff(timeOffData)
                        : Alert.alert(
                          "This will cancel the Time off, Continue?",
                          "",
                          [
                            {
                              text: "Yes",
                              onPress: () => deleteTimeOff(timeOffData),
                            },
                            {
                              text: "No",
                              style: "cancel",
                            },
                          ]
                        )
                    }
                  >
                    <Text
                      style={{ fontSize: 18, color: "#fff", fontWeight: "700" }}
                    >
                      Cancel Time Off
                    </Text>
                  </TouchableOpacity>
                </View>}

              <View
                style={{
                  flex: 1,
                  paddingVertical: 3,
                  paddingHorizontal: 10,
                  marginHorizontal: 10,
                  marginTop: 10,
                  backgroundColor: "#00b359",
                  borderRadius: 10,
                  elevation: 3,
                }}
              >
                <TouchableOpacity
                  style={{
                    paddingVertical: 3,
                    paddingHorizontal: 10,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() =>
                    timeOffData !== undefined
                      ? editTimeOff()
                      : postTimeOff()
                  }
                >
                  <Text
                    style={{ fontSize: 18, color: "#fff", fontWeight: "700" }}
                  >
                    {loading
                      ? "Loading..."
                      : timeOffData !== undefined
                        ? "Update Time Off"
                        : "Submit"}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          )}

          <Modal
            animationType="fade"
            transparent={true}
            visible={showCalendar}
            onRequestClose={() => setShowCalendar(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 20,
                  justifyContent: "center",
                  elevation: 5,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <CalendarPicker
                  onDateChange={(date) =>
                    setTimeOff({ ...timeOff, startDate: date, endDate: date })
                  }
                  selectedDayColor="#00b359"
                  selectedDayTextColor="#FFFFFF"
                  initialDate={new Date()}
                  disabledDates={disabledDates}
                  disabledDatesTextStyle={disabledDatesTextStyle}
                />
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowCalendar(false)}
                    style={{
                      backgroundColor: "#00b359",
                      marginHorizontal: 20,
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Select
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showCalendarEnd}
            onRequestClose={() => setShowCalendarEnd(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 20,
                  justifyContent: "center",
                  elevation: 5,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <CalendarPicker
                  onDateChange={(date) =>
                    setTimeOff({ ...timeOff, endDate: date })
                  }
                  selectedDayColor="#00b359"
                  selectedDayTextColor="#FFFFFF"
                  initialDate={new Date()}
                  disabledDates={disabledStartDates}
                  disabledDatesTextStyle={disabledDatesTextStyle}
                />
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setShowCalendarEnd(false)}
                    style={{
                      backgroundColor: "#00b359",
                      marginHorizontal: 20,
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Select
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showTimeWeb}
            onRequestClose={() => setShowTimeWeb(false)}
          >
            <View
              style={{
                height: height,
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.3,
                  marginTop: 100,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text style={{ fontSize: 18, marginBottom: 0 }}>
                  Select Start Time{" "}
                </Text>
                <Text style={{ fontSize: 10, marginBottom: 10 }}>
                  Select Hour and Minutes
                </Text>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdown(!showDropdown);
                      setShowDropdownMinute(false);
                      setShowDropdownAP(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdown ? "#00b359" : "#000",
                      }}
                    >
                      {selectedHourTime === "" ? "00" : selectedHourTime}
                    </Text>
                  </TouchableOpacity>
                  <Text style={{ fontSize: 25, marginBottom: 10 }}> : </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownMinute(!showDropdownMinute);
                      setShowDropdown(false);
                      setShowDropdownAP(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownMinute ? "#00b359" : "#000",
                      }}
                    >
                      {selectedMinuteTime === "" ? "00" : selectedMinuteTime}
                    </Text>
                  </TouchableOpacity>
                  <Text style={{ fontSize: 25, marginBottom: 10 }}> </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownAP(!showDropdownAP);
                      setShowDropdown(false);
                      setShowDropdownMinute(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownAP ? "#00b359" : "#000",
                      }}
                    >
                      {selectedAPTime === "" ? "AM" : selectedAPTime}
                    </Text>
                  </TouchableOpacity>
                </View>
                {showDropdown && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 150,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    {hour.map((item, index) => {
                      return (
                        <TouchableOpacity
                          key={index}
                          onPress={() => {
                            setSelectedHourTime(item < 10 ? "0" + item : item);
                            setShowDropdown(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item < 10 ? "0" + item : item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}
                {showDropdownMinute && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 150,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    <TextInput
                      style={{
                        width: "90%",
                        padding: 2,
                        paddingLeft: 5,
                        fontSize: 12,
                        color: "#737373",
                      }}
                      keyboardType={"decimal-pad"}
                      onChangeText={(text) => {
                        setSelectedMinuteTime(text);
                      }}
                      value={
                        selectedMinuteTime === "00" ? "" : selectedMinuteTime
                      }
                      placeholderTextColor="#b3b3b3"
                      placeholder={`Enter custom minutes`}
                    />
                    {minutes.map((item, index) => {
                      return (
                        <TouchableOpacity
                          key={index}
                          onPress={() => {
                            setSelectedMinuteTime(item < 10 ? "0" + item : item);
                            setShowDropdownMinute(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item < 10 ? "0" + item : item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}
                {showDropdownAP && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 100,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    {ampm.map((item, index) => {
                      return (
                        <TouchableOpacity
                          key={index}
                          onPress={() => {
                            setSelectedAPTime(item);
                            setShowDropdownAP(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setShowTimeWeb(false);
                      setShowDropdown(false);
                      setShowDropdownMinute(false);
                      setShowDropdownAP(false);
                    }}
                    style={{
                      backgroundColor: "#888",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                      marginHorizontal: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      // dateStart.setHours(
                      //   selectedAPTime === "AM" && selectedHourTime === 12
                      //     ? 0
                      //     : selectedAPTime === "PM" && selectedHourTime === 12
                      //       ? 12
                      //       : selectedHourTime !== 12 && selectedAPTime === "PM"
                      //         ? parseInt(selectedHourTime) + 12
                      //         : parseInt(selectedHourTime)
                      // );
                      // dateStart.setMinutes(selectedMinuteTime)

                      if (parseInt(selectedHours) === 1) {
                        let date = new Date();
                        let dateEnd = new Date();
                        date.setHours(
                          selectedAPTime === "AM" && selectedHourTime === 12
                            ? 0
                            : selectedAPTime === "PM" && selectedHourTime === 12
                              ? 12
                              : selectedHourTime !== 12 && selectedAPTime === "PM"
                                ? parseInt(selectedHourTime) + 12
                                : parseInt(selectedHourTime)
                        );
                        date.setMinutes(selectedMinuteTime);
                        // Add 8 hours to dateEnd based on date
                        dateEnd.setFullYear(date.getFullYear());
                        dateEnd.setMonth(date.getMonth());
                        dateEnd.setDate(date.getDate());
                        // dateEnd.setHours(date.getHours() + (jobState.jobType === "Shift" ? 8 : 1));
                        dateEnd.setHours(date.getHours() + 1);
                        dateEnd.setMinutes(date.getMinutes());

                        // Determine if it's AM or PM
                        let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";
                        if (selectedAPTime === "PM" && meridiem === "AM") {
                          let endDate = moment(jobState.startDate);
                          endDate.add(1, "day")
                          setStartTime(date)
                          setEndTime(dateEnd)
                        } else {
                          setStartTime(date)
                          setEndTime(dateEnd)
                        }
                      } else {
                        let date = new Date();
                        let dateEnd = new Date();
                        date.setHours(
                          selectedAPTime === "AM" && selectedHourTime === 12
                            ? 0
                            : selectedAPTime === "PM" && selectedHourTime === 12
                              ? 12
                              : selectedHourTime !== 12 && selectedAPTime === "PM"
                                ? parseInt(selectedHourTime) + 12
                                : parseInt(selectedHourTime)
                        );
                        date.setMinutes(selectedMinuteTime);
                        // Add 8 hours to dateEnd based on date
                        dateEnd.setFullYear(date.getFullYear());
                        dateEnd.setMonth(date.getMonth());
                        dateEnd.setDate(date.getDate());
                        dateEnd.setHours(date.getHours() + 1);
                        // dateEnd.setHours(date.getHours() + parseInt(selectedHours));
                        dateEnd.setMinutes(date.getMinutes());

                        // Determine if it's AM or PM
                        let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";
                        if (selectedAPTime === "PM" && meridiem === "AM") {
                          let endDate = moment(jobState.startDate);
                          endDate.add(1, "day")
                          setStartTime(date)
                          setEndTime(dateEnd)
                        } else {
                          setStartTime(date)
                          setEndTime(dateEnd)
                        }
                      }

                      // setStartTime(dateStart)
                      setShowTimeWeb(false);
                      setShowDropdown(false);
                      setShowDropdownMinute(false);
                      setShowDropdownAP(false);
                    }}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Select
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          <Modal
            animationType="fade"
            transparent={true}
            visible={showTimeEndWeb}
            onRequestClose={() => setShowTimeEndWeb(false)}
          >
            <View
              style={{
                height: height,
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 280,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.3,
                  marginTop: 100,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text style={{ fontSize: 18, marginBottom: 0 }}>
                  Select End Time
                </Text>
                <Text style={{ fontSize: 10, marginBottom: 10 }}>
                  Select Hour and Minutes
                </Text>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownEnd(!showDropdownEnd);
                      setShowDropdownMinuteEnd(false);
                      setShowDropdownAPEnd(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownEnd ? "#00b359" : "#000",
                      }}
                    >
                      {selectedHourTimeEnd === "" ? "00" : selectedHourTimeEnd}
                    </Text>
                  </TouchableOpacity>
                  <Text style={{ fontSize: 25, marginBottom: 10 }}> : </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownMinuteEnd(!showDropdownMinuteEnd);
                      setShowDropdownEnd(false);
                      setShowDropdownAPEnd(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownMinuteEnd ? "#00b359" : "#000",
                      }}
                    >
                      {selectedMinuteTimeEnd === ""
                        ? "00"
                        : selectedMinuteTimeEnd}
                    </Text>
                  </TouchableOpacity>
                  <Text style={{ fontSize: 25, marginBottom: 10 }}> </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setShowDropdownAPEnd(!showDropdownAPEnd);
                      setShowDropdownEnd(false);
                      setShowDropdownMinuteEnd(false);
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        color: showDropdownAPEnd ? "#00b359" : "#000",
                      }}
                    >
                      {selectedAPTimeEnd === "" ? "AM" : selectedAPTimeEnd}
                    </Text>
                  </TouchableOpacity>
                </View>

                {showDropdownEnd && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 150,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    {hour.map((item, index) => {
                      return (
                        <TouchableOpacity
                          key={index}
                          onPress={() => {
                            setSelectedHourTimeEnd(item < 10 ? "0" + item : item);
                            setShowDropdownEnd(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item < 10 ? "0" + item : item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}
                {showDropdownMinuteEnd && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 150,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    <TextInput
                      style={{
                        width: "90%",
                        padding: 2,
                        paddingLeft: 5,
                        fontSize: 12,
                        color: "#737373",
                      }}
                      keyboardType={"decimal-pad"}
                      onChangeText={(text) => {
                        setSelectedMinuteTimeEnd(text);
                      }}
                      value={
                        selectedMinuteTimeEnd === "00"
                          ? ""
                          : selectedMinuteTimeEnd
                      }
                      placeholderTextColor="#b3b3b3"
                      placeholder={`Enter custom minutes`}
                    />
                    {minutes.map((item, index) => {
                      return (
                        <TouchableOpacity
                          key={index}
                          onPress={() => {
                            setSelectedMinuteTimeEnd(
                              item < 10 ? "0" + item : item
                            );
                            setShowDropdownMinuteEnd(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item < 10 ? "0" + item : item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}

                {showDropdownAPEnd && (
                  <ScrollView
                    style={{
                      width: "100%",
                      maxHeight: 100,
                      overflow: "scroll",
                      borderWidth: 0.5,
                      borderRadius: 4,
                      marginBottom: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    {ampm.map((item, index) => {
                      return (
                        <TouchableOpacity
                          key={index}
                          onPress={() => {
                            setSelectedAPTimeEnd(item);
                            setShowDropdownAPEnd(false);
                          }}
                          style={styles.dropdownItem}
                        >
                          <Text>{item}</Text>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollView>
                )}

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setShowTimeEndWeb(false);
                      setShowDropdownEnd(false);
                      setShowDropdownMinuteEnd(false);
                      setShowDropdownAPEnd(false);
                    }}
                    style={{
                      backgroundColor: "#888",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                      marginHorizontal: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      let dateStart = new Date();
                      dateStart.setHours(
                        selectedHourTimeEnd === 12 && selectedAPTimeEnd === "AM"
                          ? 0
                          : selectedAPTimeEnd === "PM" &&
                            selectedHourTimeEnd === 12
                            ? 12
                            : selectedHourTimeEnd !== 12 &&
                              selectedAPTimeEnd === "PM"
                              ? parseInt(selectedHourTimeEnd) + 12
                              : parseInt(selectedHourTimeEnd)
                      );
                      dateStart.setMinutes(selectedMinuteTimeEnd);
                      setEndTime(dateStart)
                      setShowTimeEndWeb(false);
                      setShowDropdownEnd(false);
                      setShowDropdownMinuteEnd(false);
                      setShowDropdownAPEnd(false);
                    }}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Select
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="fade"
            transparent={true}
            visible={selectUser}
            onRequestClose={() => setSelectUser(false)}
          >
            <View
              style={{
                height: height,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  paddingVertical: 10,
                  height: 500,
                  justifyContent: "center",
                  elevation: 5,
                  width: width / 1.5,
                  paddingHorizontal: 10,
                  borderRadius: 15,
                  borderColor: "rgba(0, 0, 0, 0.1)",
                }}
              >
                <Text style={{ fontSize: 12, marginBottom: 10 }}>
                  Select User
                </Text>
                <CustomDropdownUser
                  options={data}
                  selectedValue={
                    nurseUserId === undefined
                      ? "Select User"
                      : user?.firstName + user?.lastName
                  }
                  onValueChange={(value) => setNurseUserId(value)}
                  onUserDetailNavigate={onUserDetailNavigate}
                  setSelectUser={setSelectUser}
                />

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => setSelectUser(false)}
                    style={{
                      backgroundColor: "#00b359",
                      paddingHorizontal: 10,
                      paddingVertical: 5,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "right",
                        fontSize: 12,
                        color: "#fff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </ScrollView>}
    </KeyboardAvoidingView>
  );
};

export default TimeOffRequest;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  input: {
    color: "#000",
    paddingLeft: 15,
    fontWeight: "bold",
    height: 40,
    fontSize: 15,
    marginVertical: 10,
    borderRadius: 5,
  },
  homeBtn: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#00b359",
    width: "40%",
    height: 35,
    borderRadius: 5,
    marginVertical: 15,
    marginHorizontal: 5,
    alignSelf: "center",
  },
  dayHeaderText: {
    fontWeight: "bold",
  },
  dayContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    borderRadius: 15,
    marginTop: 6,
  },
  pastDateContainer: {
    backgroundColor: "#CCCCCC",
  },
  futureDateText: {
    color: "black",
  },
  pastDateText: {
    color: "white",
  },
  inputContainer: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
  },
  dropdownItem: {
    padding: 8,
  },
});

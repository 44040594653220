import React, { useEffect, useRef, useState } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  Alert,
  Modal,
  FlatList,
  Dimensions,
  TextInput,
  ScrollView,
  Animated,
  PanResponder,
  Platform,
  Image,
  ActivityIndicator
} from "react-native";
import moment from "moment";
import { AntDesign, Ionicons, MaterialIcons } from "@expo/vector-icons";
import CustomDropdown from "../../customComponents/DropDown/CustomDropdown";
import CustomDropdownLicense from "../../customComponents/DropDown/CustomDropdownLicense";
import {
  DateFormat,
  GetDateMergedTime,
  convertDate,
  jobUniqueId,
  timeDifferent,
  timeDifferentCard,
  timeDifferentFun
} from "../../utils/function";
import { option, optionBreak } from "../../utils/defaultData";
import CustomInput from "../../customComponents/Input/CustomInput";
import CustomToggle from "../More/Setting/CustomToggle";
// import AutoFillAddress from "../Manage/AutoFillAddress";
import AutoFillAddress from "../Address/AutoFillAddress";
import CustomTemplate from "../../customComponents/DropDown/CustomTemplate";
import CalendarPicker from "react-native-calendar-picker";
import { StyleSheet } from "react-native";
import { sendNewPushNotification } from "../../utils/notification";
import { sendNotification } from "../../utils/webNotification";
import AlertButton from "../../customComponents/AlertButton";
import CustomSliderFilter from "../../customComponents/CustomSliderFilter";
import CustomSliderFilterDropDown from "../../customComponents/CustomSliderFilterDropDown";
import NurseData from './NurseData'
import { useAuth } from "../../context/AuthContext";
import { getFacilityDetails } from "../../api_url/facilityTable";
import { postNurseNotification } from "../../api_url/nurseNotification";
import { getFullScheduleAllData } from "../../api_url/fullSchedule";
import { getNurseAvaJobDate } from "../../api_url/nurseTable";
import { postJobTemplate } from "../../api_url/jobTemplate";
import { postJobTable } from "../../api_url/jobTable";
import { postNurseTimeOff } from "../../api_url/timeoffNurse";
import { socket } from "../../api_url/socket";
import { ChatState } from "../../context/ChatContext";

const NewCalendarScreen = ({ props }) => {

  const { width, height } = Dimensions.get("window");

  const { authToken, userId, userUniqueId } = useAuth();

  const {
    fetchAgainJobDashboard, setFetchAgainJobDashboard,
  } = ChatState();

  const [itemWidth, setItemWidth] = useState(0);

  useEffect(() => {
    // Get the screen width
    const screenWidth = width;
    // Calculate the item width based on the number of items you want to display on the screen
    const numberOfItems = 7; // Adjust this value based on your design
    const calculatedWidth = screenWidth / numberOfItems;

    setItemWidth(calculatedWidth);
  }, []);


  const currentDate = moment();
  // console.log("NewCalendarScreen", props?.userId);

  const onDayCalenderViewNavigate = (d) => {
    props.navigation.navigate("OnDayCalenderViewScreen", {
      data: d,
    });
  };

  const monthDateClick = (match, item) => {
    if (width < 1500) {
      onDayCalenderViewNavigate({ data: match, date: item })
    } else {
      if (moment(item).format("ddd") === "Sun") {
        generateOneWeekDateForJobPost(moment(item).add(2, "days"))
      } else {
        generateOneWeekDateForJobPost(item)
      }
      setCalenderView("Roster");
    }
  }

  const onUserDetailNavigate = (d) => {
    props.navigation.navigate("UserDetailsScreen", {
      data: d,
    });
  };

  const onJobDetailNavigate = (d) => {
    props?.navigation.navigate("JobDetailsScreen", {
      data: d,
    });
  };

  const onViewTimeOffNavigate = (d) => {
    props?.navigation.navigate("TimeOffScreenNurse", {
      data: d,
    });
  };

  const onRequestTimeOffScreenNavigate = (d) => {
    props?.navigation.navigate("RequestTimeOffScreen", {
      data: d,
    });
  };

  const webNavigate = (status, d, propDate) => {
    let date = moment(propDate).format("YYYY-MM-DD")
    if (status === "Pending") {
      onRequestTimeOffScreenNavigate(d)
    } else {
      if (convertDate(date) === convertDate(currentDate)) {
        // console.log("same")
        if (Platform.OS !== "web") {
          Alert.alert("Do you want edit?", "", [
            {
              text: "No",
              onPress: () => console.log("Cancel Pressed"),
              style: "cancel",
            },
            {
              text: "Yes",
              onPress: () => {
                onRequestTimeOffScreenNavigate(d)
              },
            },
          ]);
        } else {
          if (confirm("Do you want edit?")) {
            onRequestTimeOffScreenNavigate(d)
          }
        }
      } else if (moment(date).isAfter(currentDate)) {
        // console.log("after")
        if (Platform.OS !== "web") {
          Alert.alert("Do you want edit?", "", [
            {
              text: "No",
              onPress: () => console.log("Cancel Pressed"),
              style: "cancel",
            },
            {
              text: "Yes",
              onPress: () => {
                onRequestTimeOffScreenNavigate(d)
              },
            },
          ]);
        } else {
          if (confirm("Do you want edit?")) {
            onRequestTimeOffScreenNavigate(d)
          }
        }
      } else {
        // console.log("else")
      }
    }
  }

  const animation = useRef(new Animated.Value(0)).current;
  const [monthDate, setMonthDate] = useState(undefined)
  const [refreshScreen, setRefreshScreen] = useState(false);

  const [calenderView, setCalenderView] = useState("Roster");
  const [loading, setLoading] = useState(true);
  const [loadingWeek, setLoadingWeek] = useState(true);
  const [facilityData, setFacilityData] = useState(undefined);
  const [jobData, setJobData] = useState(undefined);
  const [customerList, setCustomerList] = useState(undefined);
  const [nurseList, setNurseList] = useState(undefined);
  const [timeOffNurse, setTimeOffNurse] = useState(undefined);
  const [templateList, setTemplateList] = useState(undefined);
  const [weekdays, setWeekDays] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [licenseTypeArr, setLicenseTypeArr] = useState([]);
  const [error, setError] = useState(false);
  const [showBreak, setShowBreak] = useState(false);
  const [showLicenseType, setShowLicenseType] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showAddressWeb, setShowAddressWeb] = useState(false);
  const [patientView, setPatientView] = useState(false);
  const [patientSearch, setPatientSearch] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const [patientViewFilter, setPatientViewFilter] = useState(false);
  const [patientSearchFilter, setPatientSearchFilter] = useState("");
  const [filteredSuggestionsFilter, setFilteredSuggestionsFilter] = useState([]);
  const [patientSearchFilterNurse, setPatientSearchFilterNurse] = useState("");
  const [filteredSuggestionsFilterNurse, setFilteredSuggestionsFilterNurse] = useState([]);
  const [nurseFilterId, setNurseFilterId] = useState([]);

  const [viewFilter, setViewFilter] = useState("Customer");

  const [formView, setFormView] = useState("Custom")
  const [formTemView, setFormTemView] = useState("Select")
  const [formTemType, setFormTemType] = useState("Shift")
  const [selectedTemplate, setSelectedTemplate] = useState(undefined)
  const [templateName, setTemplateName] = useState("")
  let appJobType = facilityData?.additionalFields[0]?.appJobType || "Both"
  const [jobSelection, setJobSelection] = useState({
    nurse: {},
    jobType: "Shift",
    title: "",
    notes: "",
    break: "",
    customerVisibility: false,
    startDate: new Date().toString(),
    endDate: new Date().toString(),
    startTime: new Date().toString(),
    endTime: moment().add(1, "hour"),
    customer: "",
    customerId: "",
    fullAddress: "",
    latitude: "",
    longitude: "",
    unit: ""
  });

  const [licenseTypes, setLicenseTypes] = useState(option);
  const [showLicenseTypeFilter, setShowLicenseTypeFilter] = useState(false);
  const [nurseListId, setNurseListId] = useState([]);
  const [customerListId, setCustomerListId] = useState([]);

  const [timeOff, setTimeOff] = useState({
    timeOffReason: "",
    startDate: new Date().toString(),
    startTime: new Date().toString(),
    endDate: new Date().toString(),
    endTime: moment().add(1, "hour"),
    alternateContact: "",
    phoneNum: "",
    comments: "",
  });
  const [fullDay, setFullDay] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showCalendarEnd, setShowCalendarEnd] = useState(false);

  const [addJob, setAddJob] = useState(false);
  const [showTimeWeb, setShowTimeWeb] = useState(false);
  const [showTimeEndWeb, setShowTimeEndWeb] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownMinute, setShowDropdownMinute] = useState(false);
  const [showDropdownAP, setShowDropdownAP] = useState(false);

  const [selectedHours, setSelectedHours] = useState("1")
  const now = new Date();
  const currentMinutes = now.getMinutes().toString().padStart(2, "0");
  const currentAmPm = now.getHours() >= 12 ? "PM" : "AM";
  const [selectedHourTime, setSelectedHourTime] = useState(
    now.getHours() === 0
      ? 12
      : now.getHours() > 12
        ? now.getHours() - 12
        : now.getHours()
  );
  const [selectedMinuteTime, setSelectedMinuteTime] = useState(currentMinutes);
  const [selectedAPTime, setSelectedAPTime] = useState(currentAmPm);

  const [showDropdownEnd, setShowDropdownEnd] = useState(false);
  const [showDropdownMinuteEnd, setShowDropdownMinuteEnd] = useState(false);
  const [showDropdownAPEnd, setShowDropdownAPEnd] = useState(false);

  const [selectedHourTimeEnd, setSelectedHourTimeEnd] = useState(
    now.getHours() === 0
      ? 12
      : now.getHours() > 12
        ? now.getHours() - 12
        : now.getHours()
  );

  const [selectedMinuteTimeEnd, setSelectedMinuteTimeEnd] = useState("00");
  const [selectedAPTimeEnd, setSelectedAPTimeEnd] = useState("AM");

  useEffect(() => {
    const nowEnd = new Date(jobSelection.endTime);
    const currentMinutesEnd = nowEnd.getMinutes().toString().padStart(2, "0");
    const currentAmPmEnd = nowEnd.getHours() >= 12 ? "PM" : "AM";

    setSelectedHourTimeEnd(
      nowEnd.getHours() === 0
        ? 12
        : nowEnd.getHours() > 12
          ? nowEnd.getHours() - 12
          : nowEnd.getHours()
    );
    setSelectedMinuteTimeEnd(currentMinutesEnd);
    setSelectedAPTimeEnd(currentAmPmEnd);
  }, [jobSelection.endTime]);

  const hour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const minutes = [0, 15, 30, 45];
  const ampm = ["AM", "PM"];

  useEffect(() => {
    const startTimeM = "00:00"; // 12:00 AM
    // const endTimeM = "23:59";   // 11:59 PM

    // const startDate = moment().format("YYYY-MM-DD"); // current date

    // const startDateTime = moment(`${startDate} ${startTimeM}`, "YYYY-MM-DD HH:mm");
    // const endDateTime = moment(`${startDate} ${endTimeM}`, "YYYY-MM-DD HH:mm");

    if (fullDay) {
      // setJobSelection({
      //   ...jobSelection,
      //   startTime: "",
      //   endTime: 
      // })
    } else {
      setJobSelection({
        ...jobSelection,
        startTime: new Date().toString(),
        endTime: moment().add(1, "hour"),
      })
    }
  }, [fullDay]);

  const [unassigned, setUnassigned] = useState(false);
  const [unassignedLoading, setUnassignedLoading] = useState(false);
  const [unfulfilled, setUnfulfilled] = useState(true);

  const handleAddLicenseType = (item) => {
    setLicenseTypeArr([...licenseTypeArr, item]);
    setAddJob(true)
    setShowLicenseType(false)
  };

  const handleRemoveLicenseType = (index) => {
    const updatedLicenseType = [...licenseTypeArr];
    updatedLicenseType.splice(index, 1);
    setLicenseTypeArr(updatedLicenseType);
  };

  const getAllData = async (userId, customerListId, licenseTypes, nurseIds, unassigned) => {
    // console.log(userId, customerListId, licenseTypes, nurseIds, unassigned)
    await getFullScheduleAllData(authToken, userId, licenseTypes, unassigned).
      then(res => {
        const nurseFilter = res?.nurseList?.filter(nurse =>
          licenseTypes?.some(type => type === nurse.primaryLicenseType))

        let id = []
        nurseFilter?.map(item => id.push(item?.id));
        setNurseListId(id);

        const filteredJobArray = res?.jobData?.filter(item => {
          return (
            customerListId?.some(selectCustomer =>
              item.customerName === selectCustomer?.name))
        });

        const jobList = customerListId?.length === 0 ? res?.jobData : filteredJobArray


        const licenseFilterJob = jobList.filter((item) =>
          licenseTypes?.some(type => item.licenseType.includes(type))
        )

        const filteredJobArrayNurse = res?.jobData?.filter(item => {
          return (nurseIds?.some((filter) =>
            item.jobFinalSelectionNurseId === filter?.id
          ))
        });

        const finalFilter = nurseIds?.length === 0
          ? licenseFilterJob
          : filteredJobArrayNurse

        setJobData(finalFilter);
        setNurseList(res?.nurseList);
        setCustomerList(res?.customerList);
        setTimeOffNurse(res?.timeOffNurse);
        setTemplateList(res?.templateList);
        setLoading(false);
        setRefreshScreen(false);
      });
  }

  const getFacility = async (id) => {
    const itemArr = await getFacilityDetails(authToken, id)
    setFacilityData(itemArr);
    setJobSelection(prevState => ({
      ...prevState,
      jobType: itemArr?.additionalFields[0]?.appJobType === "Shift" ? "Shift" :
        itemArr?.additionalFields[0]?.appJobType === "Visit" ? "Visit" : "Shift",
    }));
    setFormTemType(itemArr?.additionalFields[0]?.appJobType === "Shift" ? "Shift" :
      itemArr?.additionalFields[0]?.appJobType === "Visit" ? "Visit" : "Shift")
    getAllData(id, customerListId, licenseTypes, nurseFilterId, unassigned)
  }

  useEffect(() => {
    if (userId !== undefined) {
      getAllData(userId, customerListId, licenseTypes, nurseFilterId, unassigned)
    }
  }, [unfulfilled, userId, customerListId, licenseTypes, nurseFilterId, unassigned, fetchAgainJobDashboard]);

  const handleAddLicenseTypeFilter = (item) => {
    if (licenseTypes.includes(item)) {
      setLicenseTypes(licenseTypes.filter((customerId) => customerId !== item));
      setShowLicenseTypeFilter(false)
      getAllData(userId, customerListId, licenseTypes.filter((customerId) => customerId !== item), nurseFilterId, unassigned)
    } else {
      setLicenseTypes([...licenseTypes, item]);
      setShowLicenseTypeFilter(false)
      getAllData(userId, customerListId, [...licenseTypes, item], nurseFilterId, unassigned)
    }
  };

  useEffect(() => {
    socket?.on("tableJobInsertEvent", (data) => {
      if (data?.table === "JobPostingTable" &&
        data?.message === "Fetch Facility Dashboard") {
        getFacility(userId)
      }
    });
  }, [nurseList, jobData, timeOffNurse, weekdays, nurseListId, socket]);

  useEffect(() => {
    if (userId !== undefined) {
      getFacility(userId)
    }
  }, [userId]);

  useEffect(() => {
    generateOneWeekDateForJobPost(jobSelection.startDate)
    generateOneMonthDates(jobSelection.startDate)
  }, []);

  const generateOneMonthDates = (date) => {
    const weeks = [];
    const currentDate = new Date(date);

    // Calculate the date of the first day of the month
    const firstDayOfMonth = new Date(currentDate);
    firstDayOfMonth.setDate(1);

    // Calculate the day of the week for the first day of the month (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
    const startDayOfWeek = firstDayOfMonth.getDay();

    let currentWeek = [];

    // Push empty strings for the days before the start of the month
    for (let i = 0; i < startDayOfWeek; i++) {
      currentWeek.push('');
    }

    for (let i = 0; i < 31; i++) { // Assuming a maximum of 31 days
      const nextDate = new Date(firstDayOfMonth);
      nextDate.setDate(firstDayOfMonth.getDate() + i);

      currentWeek.push(moment(nextDate).format("YYYY-MM-DD"));

      // If the next date is a Saturday or the last day of the month, push the current week and start a new week
      // if (nextDate.getDay() === 6 || (i === 30)) {
      //   weeks.push(currentWeek);
      //   currentWeek = [];
      // }

      if (nextDate.getDay() === 6 || (i === 30)) {
        while (currentWeek.length < 7) {
          currentWeek.push(''); // Push empty strings to fill the week
        }
        weeks.push(currentWeek);
        currentWeek = [];
      }
    }
    weeks.unshift(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"])
    setMonthDate(weeks);
  };

  const generateOneWeekDateForJobPost = (startDate) => {
    const dates = [];
    const currentDate = new Date(startDate);

    // Calculate the day of the week for the provided date (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
    const dayOfWeek = currentDate.getDay();

    // Calculate the date of the current Sunday (start of the week)
    const currentSunday = new Date(currentDate);
    currentSunday.setDate(currentDate.getDate() - dayOfWeek);

    // Generate dates for the entire week (Sunday to Saturday)
    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(currentSunday);
      nextDate.setDate(currentSunday.getDate() + i);
      dates.push({
        date: moment(nextDate).format("YYYY-MM-DD"),
        day: moment(nextDate).format("ddd-DD"),
        dayOrder: "day" + (i + 1)
      });
    }

    dates.unshift({
      day: "Nurse List",
      dayOrder: "day0"
    });

    setWeekDays(dates);
  };

  const getTableData = (nurseList, jobData, timeOffNurse, weekdays) => {
    // console.log(nurseList, jobData?.length, timeOffNurse?.length, weekdays)
    const result = [];

    let totalData = jobData?.concat(timeOffNurse)

    nurseList.forEach(nurseItem => {
      const nurseId = nurseItem.id;
      const nurseData = {
        id: nurseItem,
        day1: [],
        day2: [],
        day3: [],
        day4: [],
        day5: [],
        day6: [],
        day7: [],
        totalHours: 0,
      };

      totalData?.forEach(dataItem => {
        // nurseData.id = nurseItem;
        if (dataItem?.jobFinalSelectionNurseId == nurseId || dataItem?.nurseTableID == nurseId) {
          const dateItem = weekdays?.find(date => date.date === dataItem?.startDate);
          if (dateItem) {
            const dayKey = dateItem.dayOrder;
            nurseData[dayKey].push(dataItem);
            if (!dataItem?.timeOffReason) {
              const hoursWorked = timeDifferentFun(dataItem?.startTime, dataItem?.endTime);
              // console.log("hoursWorked", hoursWorked)
              nurseData.totalHours += hoursWorked;
            }
          }
        }
      });
      result.push(nurseData);
    });
    //Nurse Sorting
    result.sort((a, b) =>
      a?.id?.firstName
        ?.toLowerCase()
        ?.localeCompare(b?.id?.firstName?.toLowerCase())
    )
    const openJobData = {
      id: { firstName: "Unassigned", lastName: "Jobs" },
      day1: [],
      day2: [],
      day3: [],
      day4: [],
      day5: [],
      day6: [],
      day7: [],
    };
    jobData?.forEach(dataItem => {
      if (!dataItem?.jobFinalSelectionNurseId) {
        const dateItem = weekdays?.find(date => date.date === dataItem?.startDate);
        if (dateItem) {
          const dayKey = dateItem.dayOrder;
          openJobData[dayKey].push(dataItem);
        }
      }
    });

    ((unassigned === true)
      && result.unshift(openJobData))

    setTableData(result);
    setLoadingWeek(false);
    setUnassignedLoading(false);
  };

  useEffect(() => {
    if (nurseList !== undefined && jobData !== undefined
      && timeOffNurse !== undefined && weekdays.length === 8) {
      let nurseFilterList = nurseList
        .filter(nurse => nurseListId?.some(id => id === nurse.id))
      getTableData(nurseFilterList, jobData, timeOffNurse, weekdays)
    }
  }, [nurseList, jobData, timeOffNurse, weekdays, nurseListId, fetchAgainJobDashboard]);

  const columns = ['id', 'day1', 'day2', 'day3', 'day4', 'day5', 'day6', 'day7'];

  const filterCustomerNameSuggestions = (query) => {
    if (query === "") {
      setFilteredSuggestions([]);
    } else {
      const filtered = customerList
        ?.filter(
          (item) =>
            item?.firstName
              ?.toLowerCase()
              .includes(query.toLowerCase()) ||
            item?.lastName?.toLowerCase().includes(query.toLowerCase())
        )
        .sort((a, b) =>
          a?.firstName
            .toLowerCase()
            .localeCompare(b?.firstName.toLowerCase())
        );
      setFilteredSuggestions(filtered);
    }
  };

  const renderCustomerCardSuggestion = ({ item }) => {
    return (
      <TouchableOpacity
        style={{
          paddingVertical: 10,
          borderRadius: 10,
          paddingLeft: 5,
          borderColor: "#dddd",
          borderBottomWidth: 2,
        }}
        onPress={async () => {
          // let address =
          //   item?.patient_address_line_1 +
          //   "," +
          //   item?.patient_city +
          //   "," +
          //   item?.patient_state +
          //   "," +
          //   item?.patient_zip_code;

          // const GOOGLE_PLACES_API_KEY =
          //   "AIzaSyASXZSrsvL25WiY7nZc2M4WJhkDMj-jkvs";
          // const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          //   address
          // )}&key=${GOOGLE_PLACES_API_KEY}`;

          try {
            // const response = await fetch(apiUrl);
            // const data = await response.json();
            // const state = data.results[0].address_components.find((component) =>
            //   component.types.includes("administrative_area_level_1")
            // );

            setJobSelection({
              ...jobSelection,
              customer:
                item?.firstName + " " + item?.lastName,
              fullAddress: item?.fullAddress,
              latitude: item?.latitude,
              longitude: item?.longitude,
              // unit: item?.patient_address_line_2
              //   ? item?.patient_address_line_2
              //   : "",
            });
            setPatientSearch("");
            setFilteredSuggestions([]);
            setAddJob(true)
            setPatientView(false);
          } catch (error) {
            console.error("Error:", error);
            return null;
          }
        }}
      >
        <Text>{item?.firstName + " " + item?.lastName} {"  "}
          <Text style={{ fontSize: 8 }}>({item?.location_id})</Text></Text>
      </TouchableOpacity>
    );
  };

  const filterCustomerNameSuggestionsFilter = (query) => {
    if (query === "") {
      setFilteredSuggestionsFilter([]);
    } else {
      const filtered = customerList
        ?.filter(
          (item) =>
            item?.firstName
              ?.toLowerCase()
              .includes(query.toLowerCase()) ||
            item?.lastName?.toLowerCase().includes(query.toLowerCase())
        )
        .sort((a, b) =>
          a?.firstName
            .toLowerCase()
            .localeCompare(b?.firstName.toLowerCase())
        );
      setFilteredSuggestionsFilter(filtered);
    }
  };

  const handleCustomer = (item) => {
    setCustomerListId([item])
    getAllData(userId, [item], licenseTypes, nurseFilterId, unassigned)
  };

  const handleNurse = (item) => {
    if (nurseListId.includes(item)) {
      setNurseListId(nurseListId.filter(
        (customerId) => customerId !== item));
      // setLicenseTypes(licenseTypes.filter((customerId) => customerId !== item));
    } else {
      setNurseListId([...nurseListId, item]);
    }
  };

  const renderCustomerCardSuggestionFilter = ({ item }) => {
    const isItemInList = customerListId?.some((
      customer) =>
      customer.firstName + " " + customer?.lastName === item?.firstName + " " + item?.lastName
      && customer.location === item?.location_id);
    return (
      <TouchableOpacity
        style={{
          marginTop: 2,
          paddingVertical: 10,
          borderRadius: 10,
          paddingLeft: 5,
          borderColor: "#dddd",
          borderBottomWidth: 2,
          backgroundColor: isItemInList ? "#ccc" : "#00000000"
        }}
        onPress={() => {
          handleCustomer({
            name: item?.firstName + " " + item?.lastName,
            location: item?.location_id
          })
          setPatientSearchFilter("");
          setFilteredSuggestionsFilter([]);
          setPatientViewFilter(false);
        }}
      >
        <Text>{item?.firstName + " " + item?.lastName} {"  "}
          <Text style={{ fontSize: 8 }}>({item?.location_id})</Text></Text>
      </TouchableOpacity>
    );
  };

  const filterNurseNameSuggestionsFilter = (query) => {
    if (query === "") {
      setFilteredSuggestionsFilterNurse([]);
    } else {
      const filtered = nurseList
        ?.filter(
          (item) =>
            item.firstName
              ?.toLowerCase()
              .includes(query.toLowerCase()) ||
            item.lastName?.toLowerCase().includes(query.toLowerCase())
        )
        .sort((a, b) =>
          a.firstName
            .toLowerCase()
            .localeCompare(b.lastName.toLowerCase())
        );
      setFilteredSuggestionsFilterNurse(filtered);
    }
  };

  const handleNurseFilter = (item) => {
    setNurseFilterId([item])
    getAllData(userId, customerListId, licenseTypes, [item], unassigned)
  };

  const renderNurseCardSuggestionFilter = ({ item }) => {
    const isItemInList = nurseFilterId?.some(
      (nurse) => nurse?.id === item?.id);
    return (
      <TouchableOpacity
        style={{
          marginTop: 2,
          paddingVertical: 10,
          borderRadius: 10,
          paddingLeft: 5,
          borderColor: "#dddd",
          borderBottomWidth: 2,
          backgroundColor: isItemInList ? "#ccc" : "#00000000"
        }}
        onPress={() => {
          handleNurseFilter({
            name: item?.firstName + " " + item?.lastName,
            id: item?.id
          })
          setPatientSearchFilterNurse("");
          setFilteredSuggestionsFilterNurse([]);
          setPatientViewFilter(false);
        }}
      >
        <Text>{item?.firstName + " " + item?.lastName} {"  "}
          <Text style={{ fontSize: 8 }}>({item?.location_id})</Text></Text>
      </TouchableOpacity>
    );
  };

  const isPastDate = (date) => {
    return date.isBefore(currentDate, "day");
  };

  const disabledDates = (date) => {
    return isPastDate(date);
  };

  const isStartPastDate = (date) => {
    return date.isBefore(moment(timeOff.startDate), "day");
  };

  const disabledStartDates = (date) => {
    return isStartPastDate(date);
  };

  const disabledDatesTextStyle = { color: "#CCCCCC" };

  const postTemplate = async (startDate, endDate, jobTiming) => {
    let sDate = moment(startDate).format("YYYY-MM-DD")
    let eDate = moment(endDate).format("YYYY-MM-DD")
    if (
      jobSelection.shiftTitle === "" ||
      templateName === "" ||
      jobSelection.fullAddress === "" ||
      jobSelection.break === ""
    ) {
      if (Platform.OS === "web") {
        alert("Fill all required fields first");
      } else {
        Alert.alert("Fill all required fields first");
      }
      setError(true);
      setLoading(false);
    } else {
      setError(false);
      try {
        await postJobTemplate(
          authToken,
          licenseTypeArr,
          jobSelection.title,
          "",
          [],
          "",
          "",
          sDate,
          eDate,
          GetDateMergedTime(sDate, jobSelection.startTime.toString()).toString(),
          GetDateMergedTime(eDate, jobSelection.endTime.toString()).toString(),
          jobSelection.break,
          jobSelection.customer,
          jobSelection.customerId.toString(),
          jobSelection.unit,
          "",
          jobSelection.fullAddress,
          jobSelection.latitude.toString(),
          jobSelection.longitude.toString(),
          "1",
          false,
          jobSelection.notes,
          jobSelection?.jobType,
          false,
          "",
          "",
          "",
          jobTiming,
          "",
          [],
          false,
          false,
          false,
          false,
          false,
          userId,
          false,
          GetDateMergedTime(sDate, jobSelection.startTime).valueOf().toString(),
          facilityData?.organization,
          facilityData?.location_id,
          templateName,
        ).then(res => {
          setAddJob(false)
          setJobSelection({
            nurse: {},
            jobType: appJobType === "Shift" ? "Shift" :
              appJobType === "Visit" ? "Visit" : "Shift",
            title: "",
            notes: "",
            break: "",
            customerVisibility: false,
            startDate: new Date().toString(),
            startTime: new Date().toString(),
            endDate: new Date().toString(),
            endTime: moment().add(1, "hour"),
            customer: "",
            fullAddress: "",
            latitude: "",
            longitude: "",
            unit: ""
          })
          setLicenseTypeArr([])
          setSelectedTemplate(undefined)
          setFormView("Custom")
          setTimeOff({
            timeOffReason: "",
            startDate: new Date().toString(),
            startTime: new Date().toString(),
            endDate: new Date().toString(),
            endTime: moment().add(1, "hour"),
            alternateContact: "",
            phoneNum: "",
            comments: "",
          })
          setFormTemView("Select");
          setTemplateName("")
          if (Platform.OS === "web") {
            alert("Template created successfully.")
          } else {
            Alert.alert("Template created successfully.")
          }
          getAllData(userId, customerListId, licenseTypes, nurseFilterId, unassigned)
        })
      } catch (error) {
        console.log("data save error:", error);
        setLoading(false);
      }
    }
  };

  const postJob = async (startDate, endDate, jobDuration, jobTiming) => {
    let sDate = moment(startDate).format("YYYY-MM-DD")
    let eDate = moment(endDate).format("YYYY-MM-DD")
    if (
      jobSelection.title === "" ||
      jobSelection.fullAddress === "" ||
      jobSelection.break === ""
    ) {
      if (Platform.OS === "web") {
        alert("Fill all required fields first");
      } else {
        Alert.alert("Fill all required fields first");
      }
    } else {
      const res = await getNurseAvaJobDate(
        authToken,
        jobSelection?.nurse?.id,
        startDate,
        endDate,
        moment(new Date(jobSelection?.startTime)).format("hh:mm a"),
        moment(new Date(jobSelection?.endTime)).format("hh:mm a"));

      if (res?.availableTime) {
        if (
          jobSelection.shiftTitle === "" ||
          jobSelection.fullAddress === ""
        ) {
          if (Platform.OS === "web") {
            alert("Fill all required fields first");
          } else {
            Alert.alert("Fill all required fields first");
          }
          setError(true);
          setLoading(false);
        } else {
          setError(false);
          try {
            await postJobTable(
              authToken,
              licenseTypeArr,
              jobSelection.title,
              "",
              [],
              "",
              "",
              sDate,
              eDate,
              GetDateMergedTime(sDate, jobSelection.startTime).toString(),
              GetDateMergedTime(eDate, jobSelection.endTime).toString(),
              jobSelection.break,
              jobSelection.customer,
              jobSelection.customerId.toString(),
              jobSelection.unit,
              "",
              jobSelection.fullAddress,
              jobSelection.latitude.toString(),
              jobSelection.longitude.toString(),
              "1",
              false,
              jobSelection.notes,
              jobSelection?.jobType,
              "Nurse Assigned",
              jobSelection?.nurse?.id.toString(),
              "",
              [jobSelection?.nurse?.id],
              false,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              jobDuration,
              jobTiming,
              "",
              [],
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              false,
              false,
              false,
              false,
              false,
              false,
              "",
              "",
              {},
              "",
              userId,
              false,
              "",
              "",
              "",
              false,
              false,
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              GetDateMergedTime(sDate, jobSelection.startTime).valueOf().toString(),
              "",
              "",
              "",
              "",
              facilityData?.organization,
              facilityData?.location_id,
            ).then(
              async res => {
                if (Platform.OS !== "web") {
                  await sendNewPushNotification({
                    expoPushToken: jobSelection?.nurse?.mobileId,
                    job: `“Direct ${jobSelection.jobType} Assignment” (Check Upcoming Jobs)`,
                    typeMessage: `${jobSelection.jobType} assigned.`,
                    screen: "HomeScreen",
                  });
                } else {
                  sendNotification(authToken, {
                    expoPushToken: jobSelection?.nurse?.mobileId,
                    title: `“Direct ${jobSelection.jobType} Assignment” (Check Upcoming Jobs)`,
                    body: `${jobSelection.jobType} assigned.`,
                    data: { screen: "HomeScreen" },
                  })
                }
                await postNurseNotification(
                  authToken,
                  jobSelection?.nurse?.id,
                  "",
                  `“Direct ${jobSelection.jobType} Assignment” (Check Upcoming Jobs)`,
                  `${jobSelection.jobType} assigned.`,
                  "HomeScreen",
                  {
                    screen: "UpComing",
                  },
                  false,
                  false,
                  "green",
                  ""
                );
                socket?.emit("tableNotificationInsert",
                  {
                    table: "NurseNotificationTable",
                    id: jobSelection?.nurse?.id,
                  })
                setAddJob(false)
                setJobSelection({
                  nurse: {},
                  jobType: appJobType === "Shift" ? "Shift" :
                    appJobType === "Visit" ? "Visit" : "Shift",
                  title: "",
                  notes: "",
                  break: "",
                  customerVisibility: false,
                  startDate: new Date().toString(),
                  startTime: new Date().toString(),
                  endDate: new Date().toString(),
                  endTime: new Date().toString(),
                  customer: "",
                  fullAddress: "",
                  latitude: "",
                  longitude: "",
                  unit: ""
                })
                setLicenseTypeArr([])
                setSelectedTemplate(undefined)
                setFormView("Custom")
                setTimeOff({
                  timeOffReason: "",
                  startDate: new Date().toString(),
                  startTime: new Date().toString(),
                  endDate: new Date().toString(),
                  endTime: new Date().toString(),
                  alternateContact: "",
                  phoneNum: "",
                  comments: "",
                })
                if (Platform.OS === "web") {
                  alert("Job created successfully.")
                } else {
                  Alert.alert("Job created successfully.")
                }
                getAllData(userId, customerListId, licenseTypes, nurseFilterId, unassigned)
              }
            );
          } catch (error) {
            console.log("data save error:", error);
            setLoading(false);
          }
        }
      } else {
        if (Platform.OS === "web") {
          alert(`Date Conflict. Choose a different date.`);
        } else {
          Alert.alert(`Date Conflict. Choose a different date.`);
        }
      }
    }
  };

  const postTimeOff = async (startDate, endDate, startTime, endTime) => {
    let sDate = moment(startDate).format("YYYY-MM-DD")
    let eDate = moment(endDate).format("YYYY-MM-DD")
    const startMoment = moment(startTime);
    const endMoment = moment(endTime);

    const durationInMinutes = endMoment.diff(startMoment, 'minutes');

    const res = await getNurseAvaJobDate(
      authToken,
      jobSelection?.nurse?.id,
      startDate,
      endDate,
      fullDay ? moment("").format("hh:mm a") : moment(new Date(startTime)).format("hh:mm a"),
      fullDay ? moment("").format("hh:mm a") : moment(new Date(endTime)).format("hh:mm a")
    );
    if (res?.availableTime) {
      if (
        timeOff.timeOffReason === ""
      ) {
        if (Platform.OS === "web") {
          alert("Fill all required fields first");
        } else {
          Alert.alert("Fill all required fields first");
        }
        setError(true);
        setLoading(false);
      } else if (durationInMinutes < 30) {
        if (Platform.OS === "web") {
          alert("Minimum 30 minutes is required");
        } else {
          Alert.alert("Minimum 30 minutes is required");
        }
        setError(true);
        setLoading(false);
      } else {
        setError(false);
        try {
          await postNurseTimeOff(
            authToken,
            jobSelection?.nurse?.id,
            timeOff.timeOffReason,
            sDate,
            fullDay ? "" : GetDateMergedTime(sDate.toString(), startTime.toString()).toString(),
            eDate,
            fullDay ? "" : GetDateMergedTime(eDate.toString(), endTime.toString()).toString(),
            timeOff.alternateContact,
            timeOff.phoneNum,
            jobSelection?.nurse?.organization,
            jobSelection?.nurse?.location_id,
            timeOff.comments,
            "Approved",
            "",
            userId
          );
          await postNurseNotification(
            authToken,
            jobSelection?.nurse?.id,
            "",
            `Time Off Request(${DateFormat(sDate)})`,
            `Time off Request Approved.`,
            "TimeOffScreen",
            {
              id: "",
            },
            false,
            false,
            "green",
            ""
          );
          if (Platform.OS === "web") {
            sendNotification(authToken, {
              expoPushToken: jobSelection?.nurse?.mobileId,
              title: `Time Off Request(${DateFormat(sDate)})`,
              body: `Time off Request Approved.`,
              data: { screen: "TimeOffScreen" },
            })
          } else {
            await sendNewPushNotification({
              expoPushToken: jobSelection?.nurse?.mobileId,
              job: `Time Off Request(${DateFormat(sDate)})`,
              typeMessage: `Time off Request Approved.`,
              screen: "TimeOffScreen",
            });
          }
          socket?.emit("tableNotificationInsert",
            {
              table: "NurseNotificationTable",
              id: jobSelection?.nurse?.id,
            })
          if (Platform.OS === "web") {
            alert("Time Off created successfully.")
          } else {
            Alert.alert("Time Off created successfully.")
          }
          setJobSelection({
            nurse: {},
            jobType: appJobType === "Shift" ? "Shift" :
              appJobType === "Visit" ? "Visit" : "Shift",
            title: "",
            notes: "",
            break: "",
            customerVisibility: false,
            startDate: new Date().toString(),
            startTime: new Date().toString(),
            endDate: new Date().toString(),
            endTime: moment().add(1, "hour"),
            customer: "",
            fullAddress: "",
            latitude: "",
            longitude: "",
            unit: ""
          })
          setLicenseTypeArr([])
          setSelectedTemplate(undefined)
          setFormView("Custom")
          setTimeOff({
            timeOffReason: "",
            startDate: new Date().toString(),
            startTime: new Date().toString(),
            endDate: new Date().toString(),
            endTime: moment().add(1, "hour"),
            alternateContact: "",
            phoneNum: "",
            comments: "",
          })
          setAddJob(false)
          getAllData(userId, customerListId, licenseTypes, nurseFilterId, unassigned)
        } catch (error) {
          console.log("data save error:", error);
          setLoading(false);
        }
      }
    } else {
      if (Platform.OS === "web") {
        alert(`Date Conflict. Choose a different date.`);
      } else {
        Alert.alert(`Date Conflict. Choose a different date.`);
      }
    }
  };

  useEffect(() => {
    if (calenderView === "Roster") {
      Animated.loop(
        Animated.sequence([
          Animated.timing(animation, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
          }),
          Animated.timing(animation, {
            toValue: 0,
            duration: 500,
            useNativeDriver: true,
          }),
        ])
      ).start();
    }
  }, [calenderView, animation]);

  const translateX = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [5, 10],
  });

  const handleRefresh = () => {
    getAllData(userId, customerListId, licenseTypes, nurseFilterId, unassigned)
  };

  const onPress = () => {
    getAllData(userId, customerListId, licenseTypes, nurseFilterId, unassigned)
  }

  useEffect(() => {
    if (refreshScreen) {
      Animated.loop(
        Animated.sequence([
          Animated.timing(animation, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
          }),
          Animated.timing(animation, {
            toValue: 0,
            duration: 500,
            useNativeDriver: true,
          }),
        ])
      ).start();
    }
  }, [refreshScreen, animation]);

  const translateY = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [5, 10],
  });

  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: (evt, gestureState) => {
      // Check if the user is pulling down (you can adjust the threshold as needed)
      return gestureState.dy > 50;
    },
    onPanResponderRelease: () => {
      if (refreshScreen) {
        // User has released the touch, trigger refresh
        handleRefresh();
      }
    },
  });

  return (
    <View
      style={{ flex: 1, }}>
      {!showAddressWeb &&
        <ScrollView showsVerticalScrollIndicator={Platform.OS === "web" ? true : false}>
          {loading ? (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flex: 1
              }}
            >
              <Text>Loading...</Text>
            </View>
          ) :
            <View>
              {refreshScreen && (
                <View
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    paddingVertical: 20,
                    backgroundColor: "#fff",
                  }}
                >
                  <Animated.Text
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      marginTop: -20,
                      backgroundColor: "#fff",
                      transform: [{ translateY: translateY }],
                    }}
                  >
                    Pull down{" "}
                    <AntDesign name="arrowdown" size={10} color="black" />
                  </Animated.Text>
                </View>
              )}
              <View {...panResponder.panHandlers}>
                {/* Month and Week Button */}
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    borderBottomWidth: 1,
                    marginBottom: 10
                  }}
                >
                  <TouchableOpacity
                    style={{
                      backgroundColor: calenderView === "Roster" ? "#000" : "#00000000",
                      borderTopWidth: calenderView === "Roster" ? 1 : 0,
                      borderRightWidth: calenderView === "Roster" ? 1 : 0,
                      borderLeftWidth: calenderView === "Roster" ? 1 : 0,
                      flex: 1,
                      alignItems: "center",
                      height: 30,
                      justifyContent: 'center'
                    }}
                    activeOpacity={0.5}
                    onPress={() => {
                      setCalenderView("Roster");
                    }}
                  >
                    <Text
                      style={{ fontWeight: "500", fontSize: 12, color: calenderView === "Roster" ? "#fff" : "#000", }}
                    >
                      Roster Plan
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      backgroundColor: calenderView === "Schedule" ? "#000" : "#00000000",
                      flex: 1,
                      borderTopWidth: calenderView === "Schedule" ? 1 : 0,
                      borderRightWidth: calenderView === "Schedule" ? 1 : 0,
                      borderLeftWidth: calenderView === "Schedule" ? 1 : 0,
                      alignItems: "center",
                      height: 30,
                      justifyContent: 'center'
                    }}
                    activeOpacity={0.5}
                    onPress={() => {
                      setCalenderView("Schedule");
                    }}
                  >
                    <Text
                      style={{ fontWeight: "500", fontSize: 12, color: calenderView === "Schedule" ? "#fff" : "#000", }}
                    >
                      Schedule
                    </Text>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    backgroundColor: "#fff",
                    marginHorizontal: 10
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {unassignedLoading && <ActivityIndicator
                      size={"small"}
                    />}
                    <Text
                      style={{
                        marginHorizontal: 5,
                        color: "#000",
                        fontWeight: "700"
                      }}
                    >
                      Unassigned
                    </Text>
                    <CustomToggle
                      state={unassigned}
                      onValueChange={(value) => {
                        setUnassigned(value);
                        setUnassignedLoading(true);
                      }}
                    />
                  </View>
                  {/* <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      marginBottom: 5,
                      color: "#000",
                      fontWeight: "700"
                    }}
                  >
                    Unfulfilled
                  </Text>
                  <CustomToggle
                    state={unfulfilled}
                    onValueChange={(value) =>
                      setUnfulfilled(value)
                    }
                  />
                </View> */}
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingHorizontal: 20,
                    backgroundColor: "#fff",
                    marginTop: 5,
                    marginBottom: 10,
                  }}
                >
                  <View
                    style={{
                      alignItems: "center",
                    }}>
                    <Text
                      style={{
                        fontWeight: "700",
                        fontSize: 12,
                        color: "#000",
                        marginBottom: 5,
                        textDecorationStyle: "solid",
                        textDecorationLine: "underline"
                      }}
                    >
                      {/* Customer/Nurse */}
                      Patient
                    </Text>
                    {/* <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <TouchableOpacity
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#ccc",
                        paddingHorizontal: 5,
                        paddingVertical: 5,
                        borderRadius: 3
                      }}
                      activeOpacity={0.5}
                      onPress={() => {
                        setViewFilter("Customer")
                        setPatientViewFilter(true)
                      }}
                    >
                      <Text
                        style={{ fontWeight: "500", fontSize: 12, color: "#000", }}
                      >
                        {
                          customerListId.length > 0 ?
                            customerListId[0]?.name :
                            "Select Customer"
                        }
                      </Text>
                    </TouchableOpacity>
                    {customerListId.length !== 0 &&
                      <TouchableOpacity
                        onPress={() => {
                          setCustomerListId([])
                          getAllData(userId, [], licenseTypes, nurseFilterId, unassigned)
                        }}
                        style={{ marginLeft: 3 }}
                      >
                        <Ionicons name="close-sharp" size={20} color="red" />
                      </TouchableOpacity>}
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <TouchableOpacity
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#ccc",
                        paddingHorizontal: 5,
                        paddingVertical: 5,
                        borderRadius: 3,
                        marginTop: 3
                      }}
                      activeOpacity={0.5}
                      onPress={() => {
                        setViewFilter("Nurse")
                        setPatientViewFilter(true)
                      }}
                    >
                      <Text
                        style={{ fontWeight: "500", fontSize: 12, color: "#000", }}
                      >
                        {
                          nurseFilterId.length > 0 ?
                            nurseFilterId[0].name :
                            "Select Nurse"
                        }
                      </Text>
                    </TouchableOpacity>
                    {nurseFilterId.length !== 0 && <TouchableOpacity
                      onPress={() => {
                        setNurseFilterId([])
                        getAllData(userId, customerListId, licenseTypes, [], unassigned)
                      }}
                      style={{ marginLeft: 3 }}
                    >
                      <Ionicons name="close-sharp" size={20} color="red" />
                    </TouchableOpacity>}
                  </View> */}
                    {
                      nurseFilterId.length > 0 && customerListId.length > 0
                        ? <View
                          style={{
                            alignItems: "center",
                          }}>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            <TouchableOpacity
                              style={{
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#ccc",
                                paddingHorizontal: 5,
                                paddingVertical: 5,
                                borderRadius: 3
                              }}
                              activeOpacity={0.5}
                              onPress={() => {
                                setViewFilter("Customer")
                                setPatientViewFilter(true)
                              }}
                            >
                              <Text
                                style={{ fontWeight: "500", fontSize: 12, color: "#000", }}
                              >
                                {
                                  customerListId.length > 0 ?
                                    "" + customerListId[0]?.name :
                                    "Select Customer"
                                }
                              </Text>
                            </TouchableOpacity>
                            {customerListId.length !== 0 &&
                              <TouchableOpacity
                                onPress={() => {
                                  setCustomerListId([])
                                  getAllData(userId, [], licenseTypes, nurseFilterId, unassigned)
                                }}
                                style={{ marginLeft: 3 }}
                              >
                                <Ionicons name="close-sharp" size={20} color="red" />
                              </TouchableOpacity>}
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            <TouchableOpacity
                              style={{
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#ccc",
                                paddingHorizontal: 5,
                                paddingVertical: 5,
                                borderRadius: 3,
                                marginTop: 3
                              }}
                              activeOpacity={0.5}
                              onPress={() => {
                                setViewFilter("Nurse")
                                setPatientViewFilter(true)
                              }}
                            >
                              <Text
                                style={{ fontWeight: "500", fontSize: 12, color: "#000", }}
                              >
                                {
                                  nurseFilterId.length > 0 ?
                                    "Nurse : " + nurseFilterId[0].firstName :
                                    "Select Nurse"
                                }
                              </Text>
                            </TouchableOpacity>
                            {nurseFilterId.length !== 0 && <TouchableOpacity
                              onPress={() => {
                                setNurseFilterId([])
                                getAllData(userId, customerListId, licenseTypes, [], unassigned)
                              }}
                              style={{ marginLeft: 3 }}
                            >
                              <Ionicons name="close-sharp" size={20} color="red" />
                            </TouchableOpacity>}
                          </View>
                        </View>
                        : nurseFilterId.length > 0
                          ? <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            <TouchableOpacity
                              style={{
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#ccc",
                                paddingHorizontal: 5,
                                paddingVertical: 5,
                                borderRadius: 3,
                                marginTop: 3
                              }}
                              activeOpacity={0.5}
                              onPress={() => {
                                setViewFilter("Nurse")
                                setPatientViewFilter(true)
                              }}
                            >

                              <Text
                                style={{ fontWeight: "500", fontSize: 12, color: "#000", }}
                              >
                                {
                                  nurseFilterId.length > 0 ?
                                    "Nurse : " + nurseFilterId[0].firstName :
                                    "Select Nurse"
                                }
                              </Text>
                            </TouchableOpacity>
                            {nurseFilterId.length !== 0 && <TouchableOpacity
                              onPress={() => {
                                setNurseFilterId([])
                                getAllData(userId, customerListId, licenseTypes, [], unassigned)
                              }}
                              style={{ marginLeft: 3 }}
                            >
                              <Ionicons name="close-sharp" size={20} color="red" />
                            </TouchableOpacity>}
                          </View>
                          : customerListId.length > 0
                            ? <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                              <TouchableOpacity
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#ccc",
                                  paddingHorizontal: 5,
                                  paddingVertical: 5,
                                  borderRadius: 3
                                }}
                                activeOpacity={0.5}
                                onPress={() => {
                                  setViewFilter("Customer")
                                  setPatientViewFilter(true)
                                }}
                              >
                                <Text
                                  style={{ fontWeight: "500", fontSize: 12, color: "#000", }}
                                >
                                  {
                                    customerListId.length > 0 ?
                                      "" + customerListId[0]?.name :
                                      "Select Customer"
                                  }
                                </Text>
                              </TouchableOpacity>
                              {customerListId.length !== 0 &&
                                <TouchableOpacity
                                  onPress={() => {
                                    setCustomerListId([])
                                    getAllData(userId, [], licenseTypes, nurseFilterId, unassigned)
                                  }}
                                  style={{ marginLeft: 3 }}
                                >
                                  <Ionicons name="close-sharp" size={20} color="red" />
                                </TouchableOpacity>}
                            </View>
                            : <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                              <TouchableOpacity
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#ccc",
                                  paddingHorizontal: 5,
                                  paddingVertical: 5,
                                  borderRadius: 3
                                }}
                                activeOpacity={0.5}
                                onPress={() => {
                                  setViewFilter("Customer")
                                  setPatientViewFilter(true)
                                }}
                              >
                                <Text
                                  style={{ fontWeight: "500", fontSize: 12, color: "#000", }}
                                >
                                  Select
                                  {/* Filter */}
                                </Text>
                              </TouchableOpacity>
                            </View>}
                  </View>
                  {/* <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <TouchableOpacity
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#ccc",
                              paddingHorizontal: 5,
                              paddingVertical: 5,
                              borderRadius: 3
                            }}
                            activeOpacity={0.5}
                          >
                            <Text
                              style={{ fontWeight: "500", fontSize: 12, color: "#000", }}
                            >
                              Nurse - {nurseListId.length}
                            </Text>
                          </TouchableOpacity>
                          {nurseListId.length !== 0 && <TouchableOpacity
                            onPress={() => setNurseListId([])}
                            style={{ marginLeft: 3 }}
                          >
                            <Ionicons name="close-sharp" size={20} color="red" />
                          </TouchableOpacity>}
                        </View> */}
                  <View
                    style={{
                      alignItems: "center",
                    }}>
                    {/* <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <TouchableOpacity
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingHorizontal: 5,
                              paddingVertical: 5,
                              backgroundColor: "#ccc",
                              borderRadius: 3
                            }}
                            activeOpacity={0.5}
                            onPress={() => setShowLicenseTypeFilter(true)}
                          >
                            <Text
                              style={{ fontWeight: "500", fontSize: 12, color: "#000" }}
                            >
                              LicenseType - {licenseTypes.length}
                            </Text>
                          </TouchableOpacity>
                          {licenseTypes.length !== 0 && <TouchableOpacity
                            onPress={() => {
                              setLicenseTypes([])
                              getAllData(userId, customerListId, [], unassigned)
                            }}
                            style={{ marginLeft: 3 }}
                          >
                            <Ionicons name="close-sharp" size={20} color="red" />
                          </TouchableOpacity>}
                        </View> */}
                    <Text
                      style={{
                        fontWeight: "700",
                        fontSize: 12,
                        color: "#000",
                        marginBottom: 5,
                        textDecorationStyle: "solid",
                        textDecorationLine: "underline"
                      }}
                    >
                      License Type
                    </Text>
                    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                      {option?.map((item, index) => {
                        return (
                          <TouchableOpacity
                            key={index}
                            onPress={() => handleAddLicenseTypeFilter(item)}
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              marginRight: 10,
                            }}
                          >
                            <Text style={{ fontSize: 12, color: "#000", fontWeight: "600" }}>
                              {item}
                            </Text>
                            {licenseTypes?.includes(item) ?
                              <Ionicons name="close-sharp" size={18} color="red" />
                              :
                              <MaterialIcons name="add" size={18} color={"#00b359"} />
                            }
                          </TouchableOpacity>
                        );
                      })}
                    </View>
                  </View>
                </View>
                {/* Month View */}
                {
                  calenderView === "Schedule" ?
                    <View
                      style={{ flexDirection: "column", alignItems: "center" }}>
                      <View style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginHorizontal: 10,
                        alignItems: "center",
                        marginBottom: 10,
                        marginTop: 5
                      }}>
                        <TouchableOpacity onPress={() =>
                          generateOneMonthDates(moment(monthDate[1][6]).subtract(1, "month"))}>
                          <AntDesign name="caretleft" size={24} color="black" />
                        </TouchableOpacity>
                        <Text style={{ color: "#2775BD", fontWeight: "600" }}>{moment(monthDate[1][6]).format("MMMM YYYY")}</Text>
                        <TouchableOpacity onPress={() =>
                          generateOneMonthDates(moment(monthDate[monthDate?.length - 1][0]).add(1, "month"))}>
                          <AntDesign name="caretright" size={24} color="black" />
                        </TouchableOpacity>
                      </View>
                      <ScrollView showsVerticalScrollIndicator={Platform.OS === "web" ? true : false}>
                        <ScrollView
                          horizontal
                          showsHorizontalScrollIndicator={Platform.OS === "web" ? true : false}>
                          <View
                            style={{
                              marginBottom: 200,
                            }}>
                            {
                              monthDate !== undefined &&
                              monthDate?.map((item, index) => {
                                return (
                                  <View key={index}
                                    style={{
                                      flexDirection: "row",
                                    }}>
                                    {
                                      item?.map((item, index) => {
                                        let matchItemDate = item === ""
                                          ? null
                                          : moment(item).format("DD") === "Invalid date"
                                            ? null
                                            : moment(item).format("YYYY-MM-DD");

                                        const mergedArray = jobData
                                          ?.flat()
                                          .concat(timeOffNurse?.flat());


                                        const match = matchItemDate !== null &&
                                          mergedArray?.filter(jobItem =>
                                            moment(jobItem?.startDate).format("YYYY-MM-DD") === matchItemDate);

                                        // const allVisit = match?.length > 0 && match?.every(jobItem => jobItem.jobType === "Visit");
                                        // const allShift = match?.length > 0 && match?.every(jobItem => jobItem.jobType === "Shift");
                                        // const hasVisit = match?.length > 0 && match?.some(jobItem => jobItem.jobType === "Visit");
                                        // const hasShift = match?.length > 0 && match?.some(jobItem => jobItem.jobType === "Shift");

                                        // const bothAvailable = hasVisit && hasShift;  
                                        return (
                                          <TouchableOpacity
                                            key={index}
                                            style={{
                                              width: width < 1500 ? itemWidth : 200,
                                              alignItems: "center",
                                              justifyContent: "center",
                                              borderWidth: 1,
                                              borderColor: 'gray',
                                            }}
                                            onPress={() => {
                                              monthDateClick(match, item)
                                            }}
                                            disabled={item === "" ? true
                                              : moment(item).format("DD") === "Invalid date" ?
                                                true : false}>
                                            <View
                                              style={{
                                                width: width < 1500 ? itemWidth : 200,
                                                paddingVertical: 5,
                                                alignItems: "center",
                                                backgroundColor: item === ""
                                                  ? "#00000000"
                                                  : moment(item).format("DD") === "Invalid date"
                                                    ? "#ccc"
                                                    : "#00000000",
                                                borderTopWidth:
                                                  item === ""
                                                    ? 0
                                                    : moment(item).format("DD") === "Invalid date"
                                                      ? 0.5
                                                      : 0,
                                                borderRightWidth: item === ""
                                                  ? 0
                                                  : moment(item).format("DD") === "Invalid date"
                                                    ? 1
                                                    : 0,
                                                borderLeftWidth: item === ""
                                                  ? 0
                                                  : moment(item).format("DD") === "Invalid date"
                                                    ? 1
                                                    : 0,
                                                borderColor: 'gray',

                                              }}>
                                              <View
                                                style={{
                                                  justifyContent: width < 1500 ? "center" : "flex-start",
                                                  alignItems: width < 1500 ? "center" : "flex-start",
                                                }}>
                                                <View
                                                  style={{
                                                    backgroundColor: item === ""
                                                      ? "#00000000"
                                                      : moment(item).format("DD") === "Invalid date"
                                                        ? "#00000000"
                                                        : moment(item).format("DD-MM-YYYY") === moment().format("DD-MM-YYYY")
                                                          ? "#ccc" : "#00000000",
                                                    color: item === ""
                                                      ? "#00000000"
                                                      : moment(item).format("DD") === "Invalid date"
                                                        ? "#000"
                                                        : "#000",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontWeight: "600",
                                                    width: 30,
                                                    height: 30,
                                                    borderRadius: 100,
                                                  }}>
                                                  <Text style={{
                                                    color: item === ""
                                                      ? "#00000000"
                                                      : "#000",
                                                    fontWeight: item === ""
                                                      ? "400"
                                                      : moment(item).format("DD") === "Invalid date"
                                                        ? "700"
                                                        : "600",
                                                  }}>
                                                    {item === ""
                                                      ? null
                                                      : moment(item).format("DD") === "Invalid date"
                                                        ? item
                                                        : moment(item).format("DD")
                                                    }
                                                  </Text>
                                                </View>

                                                <View
                                                  style={{
                                                    flexDirection: width < 1500 ? "row" : "column",
                                                    flexWrap: "wrap",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}>
                                                  {match?.length > 0 &&
                                                    (match?.sort((a, b) => a?.startDateTimeStamp - b?.startDateTimeStamp)
                                                      ?.map((jobItem, index) => {
                                                        return (
                                                          <View key={index}>
                                                            {jobItem?.shiftTitle
                                                              ?
                                                              width < 1500 ?
                                                                <TouchableOpacity
                                                                  onPress={() =>
                                                                    onJobDetailNavigate({
                                                                      id: jobItem?.id,
                                                                    })
                                                                  }
                                                                  style={{
                                                                    backgroundColor:
                                                                      jobItem?.jobStatus === "Unfulfilled" ? "#888"
                                                                        // : jobItem?.jobType === "Visit" ? "#00b359" : "#2775BD",
                                                                        : (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#ffff00" :
                                                                          (jobItem?.jobStatus === "Completed") ? "#00b359" :
                                                                            (jobItem?.jobStatus === "Nurse Assigned" || jobItem?.jobStatus === "In-Progress"
                                                                              || jobItem?.jobStatus === "Pending Approval") ? "#2775BD" :
                                                                              (jobItem?.jobStatus === "Missed" || jobItem?.jobStatus === "Pending Clock Out") ? "red" :
                                                                                "#888",
                                                                    borderWidth:
                                                                      jobItem?.jobStatus === "Unfulfilled" ? 0 :
                                                                        jobItem?.jobStatus === "Open" ? 0 :
                                                                          jobItem?.jobStatus === "Pending Assignment" ? 1 :
                                                                            jobItem?.jobStatus === "Completed" ? 0 :
                                                                              (jobItem?.jobStatus === "Nurse Assigned" || jobItem?.jobStatus === "In-Progress") ? 0 :
                                                                                jobItem?.jobStatus === "Pending Approval" ? 1 :
                                                                                  (jobItem?.jobStatus === "Missed" || jobItem?.jobStatus === "Pending Clock Out") ? 0 :
                                                                                    0,
                                                                    width: 10,
                                                                    height: 10,
                                                                    margin: 3,
                                                                    borderRadius: 20
                                                                  }}>
                                                                </TouchableOpacity> :
                                                                <TouchableOpacity
                                                                  onPress={() =>
                                                                    onJobDetailNavigate({
                                                                      id: jobItem?.id,
                                                                    })
                                                                  }
                                                                  style={{
                                                                    backgroundColor:
                                                                      jobItem?.jobStatus === "Unfulfilled" ? "#888"
                                                                        // : jobItem?.jobType === "Visit" ? "#00b359" : "#2775BD",
                                                                        : (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#ffff00" :
                                                                          (jobItem?.jobStatus === "Completed") ? "#00b359" :
                                                                            (jobItem?.jobStatus === "Nurse Assigned" || jobItem?.jobStatus === "In-Progress"
                                                                              || jobItem?.jobStatus === "Pending Approval") ? "#2775BD" :
                                                                              (jobItem?.jobStatus === "Missed" || jobItem?.jobStatus === "Pending Clock Out") ? "red" :
                                                                                "#888",
                                                                    borderWidth:
                                                                      jobItem?.jobStatus === "Unfulfilled" ? 0 :
                                                                        jobItem?.jobStatus === "Open" ? 0 :
                                                                          jobItem?.jobStatus === "Pending Assignment" ? 1 :
                                                                            jobItem?.jobStatus === "Completed" ? 0 :
                                                                              (jobItem?.jobStatus === "Nurse Assigned" || jobItem?.jobStatus === "In-Progress") ? 0 :
                                                                                jobItem?.jobStatus === "Pending Approval" ? 1 :
                                                                                  (jobItem?.jobStatus === "Missed" || jobItem?.jobStatus === "Pending Clock Out") ? 0 :
                                                                                    0,
                                                                    padding: 5,
                                                                    margin: 3,
                                                                    borderRadius: 3,
                                                                    width: 170
                                                                  }}>
                                                                  {/* <Text
                                                                  style={{
                                                                    marginBottom: 2,
                                                                    color: (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#000" : "#fff",
                                                                    fontSize: 11
                                                                  }}>{jobUniqueId(jobItem?.createdAt, jobItem?.jobType)}-{jobItem?.id}</Text> */}
                                                                  <View style={{ flexDirection: "row" }}>
                                                                    <Text style={{ color: (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#000" : "#fff", fontSize: 11 }}>{moment(jobItem?.startTime).format("hh:mma")} - </Text>
                                                                    <Text style={{ color: (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#000" : "#fff", fontSize: 11 }}>{moment(jobItem?.endTime).format("hh:mma")} - </Text>
                                                                    <Text style={{ color: (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#000" : "#fff", fontSize: 11 }}>{timeDifferentCard(jobItem?.startTime, jobItem?.endTime)}</Text>
                                                                  </View>
                                                                  <Text style={{ color: (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#000" : "#fff", fontSize: 11, fontWeight: "600", marginTop: 3 }}>
                                                                    Status : {jobItem?.jobStatus}</Text>
                                                                  <Text style={{ color: (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#000" : "#fff", fontSize: 12, fontWeight: "600", marginTop: 3 }}>{jobItem?.shiftTitle}</Text>
                                                                  {jobItem?.jobFinalSelectionNurseId &&
                                                                    <View
                                                                      style={{ marginTop: 5 }}>
                                                                      <NurseData
                                                                        id={jobItem?.jobFinalSelectionNurseId}
                                                                        onUserDetailNavigate={onUserDetailNavigate}
                                                                        authToken={authToken}
                                                                        color={"#FFF"}
                                                                        fontSize={12}
                                                                        small={true}
                                                                        userUniqueId={userUniqueId} />
                                                                    </View>}
                                                                </TouchableOpacity>
                                                              : width < 1500 ?
                                                                <TouchableOpacity
                                                                  onPress={() =>
                                                                    webNavigate(jobItem?.status, {
                                                                      userId: jobItem?.nurseTableID,
                                                                      timeOffId: jobItem?.id,
                                                                    }, jobItem?.startDate)
                                                                  }
                                                                  style={{
                                                                    backgroundColor: jobItem?.status === "Pending" ? "#888" : "red",
                                                                    width: 10,
                                                                    height: 10,
                                                                    margin: 3,
                                                                    borderRadius: 20
                                                                  }}>
                                                                </TouchableOpacity> : <TouchableOpacity
                                                                  onPress={() =>
                                                                    webNavigate(jobItem?.status, {
                                                                      userId: jobItem?.nurseTableID,
                                                                      timeOffId: jobItem?.id,
                                                                    }, jobItem?.startDate)
                                                                  }
                                                                  style={{
                                                                    backgroundColor: jobItem?.status === "Pending" ? "#888" : "red",
                                                                    padding: 5,
                                                                    borderRadius: 3,
                                                                    marginVertical: 2,
                                                                    width: 170
                                                                  }}>
                                                                  {jobItem?.startTime === "" && jobItem?.endTime === "" ?
                                                                    <View style={{ flexDirection: "row" }}>
                                                                      <Text style={{ color: "#fff", fontSize: 11 }}>Full Day</Text>
                                                                    </View>
                                                                    :
                                                                    <View style={{ flexDirection: "row" }}>
                                                                      <Text style={{ color: "#fff", fontSize: 11 }}>{moment(jobItem?.startTime).format("hh:mma")} - </Text>
                                                                      <Text style={{ color: "#fff", fontSize: 11 }}>{moment(jobItem?.endTime).format("hh:mma")} - </Text>
                                                                      <Text style={{ color: "#fff", fontSize: 11 }}>{timeDifferentCard(jobItem?.startTime, jobItem?.endTime)}</Text>
                                                                    </View>}
                                                                  <Text style={{ color: "#fff", fontSize: 12, fontWeight: "600", marginTop: 3 }}>
                                                                    {/* {jobItem?.timeOffReason} */}
                                                                    TimeOff-{jobItem?.status}
                                                                  </Text>
                                                                  <View
                                                                    style={{ marginTop: 5 }}>
                                                                    <NurseData
                                                                      id={jobItem?.nurseTableID}
                                                                      onUserDetailNavigate={onUserDetailNavigate}
                                                                      authToken={authToken}
                                                                      color={"#FFF"}
                                                                      fontSize={12}
                                                                      small={true}
                                                                      userUniqueId={userUniqueId} />
                                                                  </View>
                                                                </TouchableOpacity>
                                                            }
                                                          </View>
                                                        )
                                                      }))
                                                  }
                                                </View>
                                              </View>
                                            </View>
                                          </TouchableOpacity>
                                        )
                                      })
                                    }
                                  </View>
                                )
                              })
                            }
                          </View>
                        </ScrollView>
                      </ScrollView>
                    </View>
                    :
                    loadingWeek ?
                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text>Loading...</Text>
                      </View>
                      :
                      <View
                        style={{
                          marginBottom: 100,
                        }}>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            marginHorizontal: 10,
                            alignItems: "center",
                            marginBottom: 10,
                          }}>
                          <TouchableOpacity onPress={() =>
                            generateOneWeekDateForJobPost(moment(weekdays[1]?.date).subtract(1, "days"))}>
                            <AntDesign name="caretleft" size={24} color="black" />
                          </TouchableOpacity>
                          <TouchableOpacity style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                            onPress={() => {
                              setCalenderView("Schedule");
                            }}>
                            <Text style={{ color: "#2775BD", fontWeight: "600" }}>{moment(weekdays[1]?.date).format("DD MMM")}</Text>
                            <Text style={{ color: "#2775BD", fontWeight: "600" }}> - </Text>
                            <Text style={{ color: "#2775BD", fontWeight: "600" }}>{moment(weekdays[weekdays?.length - 1]?.date).format("DD MMM")}{" "}</Text>
                          </TouchableOpacity>
                          <TouchableOpacity onPress={() =>
                            generateOneWeekDateForJobPost(moment(weekdays[weekdays?.length - 1]?.date).add(2, "days"))}>
                            <AntDesign name="caretright" size={24} color="black" />
                          </TouchableOpacity>
                        </View>

                        <ScrollView>
                          <ScrollView
                            horizontal
                            showsHorizontalScrollIndicator={Platform.OS === "web" ? true : false}>
                            <View>
                              <View style={{ flexDirection: "row" }}>
                                {weekdays?.map((item, index) => {
                                  return (
                                    <View key={index} style={{ flexDirection: "row" }}>
                                      <Text style={{
                                        width: 230,
                                        padding: 10,
                                        borderWidth: 1,
                                        borderColor: 'gray',
                                        textAlign: "center",
                                        backgroundColor: "#ccc",
                                        fontWeight: "700"
                                      }}>
                                        {item?.day === "Nurse List" ? "" : item?.day}
                                      </Text>
                                    </View>
                                  );
                                })}
                              </View>
                              <View style={{ flexDirection: "row" }}>
                                <View>
                                  {tableData?.map((item, index) => {
                                    return (
                                      <View key={index}
                                        style={{ flexDirection: "row" }}>
                                        {columns.map((column, indexC) => {
                                          const dayDate = moment(weekdays?.find(date => date.dayOrder === column)?.date).startOf('day');
                                          const currentFormattedDate = moment(currentDate).startOf('day');
                                          return (
                                            <View key={indexC} style={{
                                              width: 230,
                                              padding: 10,
                                              borderWidth: 1,
                                              borderColor: 'gray',
                                              justifyContent: "center",
                                              alignItems: "center"
                                            }}>
                                              {column === 'id' && item.hasOwnProperty("id") ?
                                                // Hiding View
                                                <View>
                                                  <TouchableOpacity
                                                    style={{
                                                      flexDirection: "row",
                                                      width: 180,
                                                    }}
                                                  >
                                                    {item["id"]?.firstName !== "Unassigned" &&
                                                      <View
                                                        style={{
                                                          // display: "none",
                                                          flexDirection: "row",
                                                          borderWidth: 1,
                                                          borderRadius: 10,
                                                          borderColor: "#00000000",
                                                          justifyContent: "center",
                                                          alignItems: "center",
                                                          backgroundColor: "#fff",
                                                          width: 40,
                                                          height: 40,
                                                          overflow: "hidden",
                                                        }}
                                                      >
                                                        {item["id"]?.profileImage ? (
                                                          <Image
                                                            source={{
                                                              uri: item["id"]?.profileImage
                                                            }}
                                                            style={{
                                                              width: 65,
                                                              height: 65,
                                                              borderRadius: 10,
                                                            }}

                                                          />
                                                        ) : (
                                                          <Image
                                                            source={require("../../../assets/images/icon.jpg")}
                                                            style={{ width: 65, height: 65 }}
                                                          />
                                                        )}
                                                      </View>
                                                    }
                                                    <View
                                                      style={{
                                                        // display: "none",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        flex: 1,
                                                      }}
                                                    >
                                                      <View
                                                        style={{
                                                          marginLeft: 10,
                                                          display: "flex",
                                                          flexDirection: "column",
                                                          justifyContent: "center",
                                                        }}
                                                      >
                                                        <Text style={{
                                                          color: "#00000000",
                                                          fontSize: 12,
                                                          fontWeight: "700"
                                                        }}>{item["id"]?.firstName + " " + item["id"]?.lastName}</Text>
                                                        <View
                                                          style={{
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                          }}
                                                        >
                                                          <Text style={{ color: "#00000000", fontWeight: "700", fontSize: 10 }}>
                                                            {item["id"]?.primaryLicenseType}
                                                          </Text>
                                                          {item["id"]?.firstName !== "Unassigned" && <Text style={{
                                                            color: item["totalHours"] < 40 ? "#00000000" : "#00000000",
                                                            fontWeight: "700", fontSize: 10, marginLeft: 5
                                                          }}>
                                                            - {item["totalHours"]}h
                                                          </Text>}
                                                        </View>
                                                      </View>
                                                      {item["id"]?.firstName !== "Unassigned" &&
                                                        <TouchableOpacity onPress={() => handleNurse(item["id"]?.id)}>
                                                          <Ionicons name="close-sharp" size={18} color="#00000000" />
                                                        </TouchableOpacity>}
                                                    </View>
                                                  </TouchableOpacity>
                                                </View>
                                                : item[column]?.length === 0 ?
                                                  dayDate.isBefore(currentFormattedDate)
                                                    ?
                                                    <MaterialIcons name="do-not-disturb" size={24} color="#ccc" />
                                                    :
                                                    ((item["id"]?.firstName !== "Unassigned" ?
                                                      <TouchableOpacity style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        padding: 10,
                                                      }}
                                                        onPress={() => {
                                                          const dateItem =
                                                            weekdays?.find(date =>
                                                              date.dayOrder === column);
                                                          setAddJob(true)
                                                          setJobSelection({
                                                            ...jobSelection,
                                                            nurse: item["id"],
                                                            startDate: dateItem?.date,
                                                            endDate: dateItem?.date,
                                                          })
                                                          setLicenseTypeArr([item["id"]?.primaryLicenseType])
                                                          setTimeOff({
                                                            ...timeOff,
                                                            startDate: dateItem?.date,
                                                            endDate: dateItem?.date,
                                                            phoneNum: item["id"]?.phoneNumber
                                                          })
                                                        }
                                                        }>
                                                        <MaterialIcons name="add" size={26} color={"#00b359"} />
                                                      </TouchableOpacity>
                                                      :
                                                      <TouchableOpacity style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        padding: 10,
                                                      }}
                                                        onPress={() => {
                                                          props?.navigation.navigate("AddJobScreen")
                                                        }}>
                                                        <MaterialIcons name="add" size={26} color={"#00b359"} />
                                                      </TouchableOpacity>))
                                                  :
                                                  <View
                                                    style={{
                                                      flexDirection: "row",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                    }}>
                                                    {
                                                      dayDate.isSameOrAfter(currentFormattedDate)
                                                      // moment(weekdays?.find(date =>
                                                      //   date.dayOrder === column)?.date).isSameOrAfter(moment(currentDate))
                                                      // moment(currentDate).format("MM-DD-YYYY")
                                                      //   <= moment(weekdays?.find(date =>
                                                      //     date.dayOrder === column)?.date).format("MM-DD-YYYY")
                                                      &&
                                                      (item["id"]?.firstName !== "Unassigned" ?
                                                        <TouchableOpacity
                                                          style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            padding: 10,
                                                          }}
                                                          onPress={() => {
                                                            const dateItem =
                                                              weekdays?.find(date =>
                                                                date.dayOrder === column);
                                                            setAddJob(true)
                                                            setJobSelection({
                                                              ...jobSelection,
                                                              nurse: item["id"],
                                                              startDate: dateItem?.date,
                                                              endDate: dateItem?.date,
                                                            })
                                                            setLicenseTypeArr([item["id"]?.primaryLicenseType])
                                                            setTimeOff({
                                                              ...timeOff,
                                                              startDate: dateItem?.date,
                                                              endDate: dateItem?.date,
                                                              phoneNum: item["id"]?.phoneNumber
                                                            })
                                                          }
                                                          }>
                                                          <MaterialIcons name="add" size={26} color={"#00b359"} />
                                                        </TouchableOpacity>
                                                        :
                                                        <TouchableOpacity style={{
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          justifyContent: "center",
                                                          alignItems: "center",
                                                          padding: 10,
                                                        }}
                                                          onPress={() => {
                                                            props?.navigation.navigate("AddJobScreen")
                                                          }}>
                                                          <MaterialIcons name="add" size={26} color={"#00b359"} />
                                                        </TouchableOpacity>)}
                                                    <View>
                                                      {(item[column]?.sort((a, b) => a?.startDateTimeStamp - b?.startDateTimeStamp)
                                                        ?.map((jobItem, index) => {
                                                          return (
                                                            <View key={index}>
                                                              {jobItem?.shiftTitle
                                                                ?
                                                                <TouchableOpacity
                                                                  onPress={() =>
                                                                    onJobDetailNavigate({
                                                                      id: jobItem?.id,
                                                                    })
                                                                  }
                                                                  style={{
                                                                    backgroundColor:
                                                                      jobItem?.jobStatus === "Unfulfilled" ? "#888"
                                                                        // : jobItem?.jobType === "Visit" ? "#00b359" : "#2775BD",
                                                                        : (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#ffff00" :
                                                                          (jobItem?.jobStatus === "Completed") ? "#00b359" :
                                                                            (jobItem?.jobStatus === "Nurse Assigned" || jobItem?.jobStatus === "In-Progress"
                                                                              || jobItem?.jobStatus === "Pending Approval") ? "#2775BD" :
                                                                              (jobItem?.jobStatus === "Missed" || jobItem?.jobStatus === "Pending Clock Out") ? "red" :
                                                                                "#888",
                                                                    borderWidth:
                                                                      jobItem?.jobStatus === "Unfulfilled" ? 0 :
                                                                        jobItem?.jobStatus === "Open" ? 0 :
                                                                          jobItem?.jobStatus === "Pending Assignment" ? 1 :
                                                                            jobItem?.jobStatus === "Completed" ? 0 :
                                                                              (jobItem?.jobStatus === "Nurse Assigned" || jobItem?.jobStatus === "In-Progress") ? 0 :
                                                                                jobItem?.jobStatus === "Pending Approval" ? 1 :
                                                                                  (jobItem?.jobStatus === "Missed" || jobItem?.jobStatus === "Pending Clock Out") ? 0 :
                                                                                    0,
                                                                    padding: 5,
                                                                    margin: 3,
                                                                    borderRadius: 3
                                                                  }}>
                                                                  {/* <Text style={{
                                                                  marginBottom: 2,
                                                                  color: (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#000" : "#fff",
                                                                  fontSize: 11
                                                                }}>{jobUniqueId(jobItem?.createdAt, jobItem?.jobType)}-{jobItem?.id}</Text> */}
                                                                  <View style={{ flexDirection: "row" }}>
                                                                    <Text style={{ color: (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#000" : "#fff", fontSize: 11 }}>{moment(jobItem?.startTime).format("hh:mma")} - </Text>
                                                                    <Text style={{ color: (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#000" : "#fff", fontSize: 11 }}>{moment(jobItem?.endTime).format("hh:mma")} - </Text>
                                                                    <Text style={{ color: (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#000" : "#fff", fontSize: 11 }}>{timeDifferentCard(jobItem?.startTime, jobItem?.endTime)}</Text>
                                                                  </View>
                                                                  <Text style={{ color: (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#000" : "#fff", fontSize: 11, fontWeight: "600", marginTop: 3 }}>
                                                                    Status : {jobItem?.jobStatus}</Text>
                                                                  <Text style={{ color: (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#000" : "#fff", fontSize: 12, fontWeight: "600", marginTop: 3 }}>{jobItem?.shiftTitle}</Text>
                                                                </TouchableOpacity>
                                                                :
                                                                <TouchableOpacity
                                                                  onPress={() =>
                                                                    webNavigate(jobItem?.status, {
                                                                      userId: jobItem?.nurseTableID,
                                                                      timeOffId: jobItem?.id,
                                                                    }, jobItem?.startDate)
                                                                  }
                                                                  style={{
                                                                    backgroundColor: jobItem?.status === "Pending" ? "#888" : "red",
                                                                    padding: 5,
                                                                    borderRadius: 3,
                                                                    marginVertical: 2
                                                                  }}>
                                                                  {jobItem?.startTime === "" && jobItem?.endTime === "" ?
                                                                    <View style={{ flexDirection: "row" }}>
                                                                      <Text style={{ color: "#fff", fontSize: 11 }}>Full Day</Text>
                                                                    </View>
                                                                    :
                                                                    <View style={{ flexDirection: "row" }}>
                                                                      <Text style={{ color: "#fff", fontSize: 11 }}>{moment(jobItem?.startTime).format("hh:mma")} - </Text>
                                                                      <Text style={{ color: "#fff", fontSize: 11 }}>{moment(jobItem?.endTime).format("hh:mma")} - </Text>
                                                                      <Text style={{ color: "#fff", fontSize: 11 }}>{timeDifferentCard(jobItem?.startTime, jobItem?.endTime)}</Text>
                                                                    </View>}
                                                                  <Text style={{ color: "#fff", fontSize: 12, fontWeight: "600", marginTop: 3 }}>
                                                                    {/* {jobItem?.timeOffReason} */}
                                                                    TimeOff-{jobItem?.status}
                                                                  </Text>
                                                                </TouchableOpacity>
                                                              }
                                                            </View>
                                                          )
                                                        }))}
                                                    </View>
                                                  </View>
                                              }
                                            </View>
                                          )
                                        }
                                        )}
                                      </View>
                                    );
                                  })}
                                </View>
                              </View>
                            </View>
                          </ScrollView>
                          {Platform.OS !== "web" &&
                            <View style={{
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              marginHorizontal: 20,
                              marginTop: 5
                            }}>
                              <Animated.Text
                                style={{
                                  textAlign: "center",
                                  fontSize: 10,
                                  backgroundColor: "#fff",
                                  transform: [{ translateX: translateX }],
                                }}
                              >
                                <AntDesign name="arrowleft" size={10} color="black" />
                                Scroll
                                <AntDesign name="arrowright" size={10} color="black" />
                              </Animated.Text>
                            </View>}
                          {/* OverFlow */}
                          <ScrollView
                            horizontal
                            showsHorizontalScrollIndicator={Platform.OS === "web" ? true : false}
                            scrollEnabled={false}
                            style={{
                              flexDirection: "row",
                              width: 230,
                              position: "absolute",
                              top: 0,
                              bottom: 0,
                              right: 0,
                              left: 0,
                            }}>
                            <View>
                              <View style={{ flexDirection: "row" }}>
                                {weekdays?.map((item, index) => {
                                  return (
                                    <View key={index} style={{ flexDirection: "row" }}>
                                      <Text style={{
                                        width: 230,
                                        padding: 10,
                                        borderWidth: 1,
                                        borderColor: 'gray',
                                        textAlign: "center",
                                        backgroundColor: "#ccc",
                                        fontWeight: "700"
                                      }}>
                                        {item?.day}
                                      </Text>

                                    </View>
                                  );
                                })}
                              </View>
                              <View style={{ flexDirection: "row" }}>
                                <View>
                                  {tableData?.map((item, index) => {
                                    return (
                                      <View key={index}
                                        style={{ flexDirection: "row" }}>
                                        {columns.map((column, indexC) => {
                                          const dayDate = moment(weekdays?.find(date => date.dayOrder === column)?.date).startOf('day');
                                          const currentFormattedDate = moment(currentDate).startOf('day');
                                          return (
                                            <View key={indexC} style={{
                                              width: 230,
                                              padding: 10,
                                              borderWidth: 1,
                                              borderColor: 'gray',
                                              justifyContent: "center",
                                              alignItems: "center",
                                              backgroundColor: "#fff"
                                            }}>
                                              {column === 'id' && item.hasOwnProperty("id") ?
                                                <View>
                                                  <TouchableOpacity
                                                    style={{
                                                      flexDirection: "row",
                                                      width: 180,
                                                    }}
                                                    onPress={() =>
                                                      onUserDetailNavigate({
                                                        id: item["id"]?.uniqueId,
                                                        facilityId: userUniqueId,
                                                      })
                                                    }>
                                                    {item["id"]?.firstName !== "Unassigned" && <View
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        borderWidth: 1,
                                                        borderRadius: 10,
                                                        borderColor: "#2775BD",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        backgroundColor: "#fff",
                                                        width: 40,
                                                        height: 40,
                                                        overflow: "hidden",
                                                      }}
                                                    >
                                                      {item["id"]?.profileImage ? (
                                                        <Image
                                                          source={{
                                                            uri: item["id"]?.profileImage
                                                          }}
                                                          style={{
                                                            width: 65,
                                                            height: 65,
                                                            borderRadius: 10,
                                                          }}

                                                        />
                                                      ) : (
                                                        <Image
                                                          source={require("../../../assets/images/icon.jpg")}
                                                          style={{ width: 65, height: 65 }}
                                                        />
                                                      )}
                                                    </View>}
                                                    <View
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        flex: 1,
                                                      }}
                                                    >
                                                      <View
                                                        style={{
                                                          marginLeft: 10,
                                                          display: "flex",
                                                          flexDirection: "column",
                                                          justifyContent: "center",
                                                        }}
                                                      >
                                                        <Text style={{
                                                          color: item["id"]?.firstName === "Unassigned" ? "#000" : "#2775BD",
                                                          fontSize: 12,
                                                          fontWeight: "700"
                                                        }}>{item["id"]?.firstName + " " + item["id"]?.lastName}</Text>
                                                        <View
                                                          style={{
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                          }}
                                                        >
                                                          <Text style={{ color: "#000", fontWeight: "700", fontSize: 10 }}>
                                                            {item["id"]?.primaryLicenseType}
                                                          </Text>
                                                          {item["id"]?.firstName !== "Unassigned" && <Text style={{
                                                            color: item["totalHours"] < 40 ? "green" : "red",
                                                            fontWeight: "700", fontSize: 10, marginLeft: 5
                                                          }}>
                                                            - {item["totalHours"]}h
                                                          </Text>}
                                                        </View>
                                                      </View>
                                                      {item["id"]?.firstName !== "Unassigned" &&
                                                        <TouchableOpacity onPress={() => handleNurse(item["id"]?.id)}>
                                                          <Ionicons name="close-sharp" size={18} color="red" />
                                                        </TouchableOpacity>}
                                                    </View>
                                                  </TouchableOpacity>
                                                </View>
                                                : item[column]?.length === 0 ?
                                                  dayDate.isBefore(currentFormattedDate)
                                                    ?
                                                    <MaterialIcons name="do-not-disturb" size={24} color="#ccc" />
                                                    :
                                                    (item["id"]?.firstName !== "Unassigned" ? <TouchableOpacity style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      padding: 10,
                                                    }}
                                                      onPress={() => {
                                                        const dateItem =
                                                          weekdays?.find(date =>
                                                            date.dayOrder === column);
                                                        setAddJob(true)
                                                        setJobSelection({
                                                          ...jobSelection,
                                                          nurse: item["id"],
                                                          startDate: dateItem?.date,
                                                          endDate: dateItem?.date,
                                                        })
                                                        setLicenseTypeArr([item["id"]?.primaryLicenseType])
                                                        setTimeOff({
                                                          ...timeOff,
                                                          startDate: dateItem?.date,
                                                          endDate: dateItem?.date,
                                                          phoneNum: item["id"]?.phoneNumber
                                                        })
                                                      }
                                                      }>
                                                      <MaterialIcons name="add" size={26} color={"#00b359"} />
                                                    </TouchableOpacity> : <TouchableOpacity style={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      padding: 10,
                                                    }}
                                                      onPress={() => {
                                                        props?.navigation.navigate("AddJobScreen")
                                                      }}>
                                                      <MaterialIcons name="add" size={26} color={"#00b359"} />
                                                    </TouchableOpacity>)
                                                  :
                                                  <View
                                                    style={{
                                                      flexDirection: "row",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                    }}>
                                                    {
                                                      moment(weekdays?.find(date =>
                                                        date.dayOrder === column)?.date).isSameOrAfter(moment(currentDate))
                                                      // moment(currentDate).format("MM-DD-YYYY")
                                                      //   <= moment(weekdays?.find(date =>
                                                      //     date.dayOrder === column)?.date).format("MM-DD-YYYY")
                                                      &&
                                                      (item["id"]?.firstName !== "Unassigned" ? <TouchableOpacity
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          justifyContent: "center",
                                                          alignItems: "center",
                                                          padding: 10,
                                                        }}
                                                        onPress={() => {
                                                          const dateItem =
                                                            weekdays?.find(date =>
                                                              date.dayOrder === column);
                                                          setAddJob(true)
                                                          setJobSelection({
                                                            ...jobSelection,
                                                            nurse: item["id"],
                                                            startDate: dateItem?.date,
                                                            endDate: dateItem?.date,
                                                          })
                                                          setLicenseTypeArr([item["id"]?.primaryLicenseType])
                                                          setTimeOff({
                                                            ...timeOff,
                                                            startDate: dateItem?.date,
                                                            endDate: dateItem?.date,
                                                            phoneNum: item["id"]?.phoneNumber
                                                          })
                                                        }
                                                        }>
                                                        <MaterialIcons name="add" size={26} color={"#00b359"} />
                                                      </TouchableOpacity> : <TouchableOpacity style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        padding: 10,
                                                      }}
                                                        onPress={() => {
                                                          props?.navigation.navigate("AddJobScreen")
                                                        }}>
                                                        <MaterialIcons name="add" size={26} color={"#00b359"} />
                                                      </TouchableOpacity>)}
                                                    <View>
                                                      {(item[column]?.sort((a, b) => a?.startDateTimeStamp - b?.startDateTimeStamp)
                                                        ?.map((jobItem, index) => {
                                                          return (
                                                            <View key={index}>
                                                              {jobItem?.shiftTitle
                                                                ?
                                                                <TouchableOpacity
                                                                  onPress={() =>
                                                                    onJobDetailNavigate({
                                                                      id: jobItem?.id,
                                                                    })
                                                                  }
                                                                  style={{
                                                                    backgroundColor:
                                                                      jobItem?.jobStatus === "Unfulfilled" ? "#888"
                                                                        // : jobItem?.jobType === "Visit" ? "#00b359" : "#2775BD",
                                                                        : (jobItem?.jobStatus === "Open" || jobItem?.jobStatus === "Pending Assignment") ? "#ffff00" :
                                                                          (jobItem?.jobStatus === "Completed") ? "#00b359" :
                                                                            (jobItem?.jobStatus === "Nurse Assigned" || jobItem?.jobStatus === "In-Progress"
                                                                              || jobItem?.jobStatus === "Pending Approval") ? "#2775BD" :
                                                                              (jobItem?.jobStatus === "Missed" || jobItem?.jobStatus === "Pending Clock Out") ? "red" :
                                                                                "#888",
                                                                    borderWidth:
                                                                      jobItem?.jobStatus === "Unfulfilled" ? 0 :
                                                                        jobItem?.jobStatus === "Open" ? 0 :
                                                                          jobItem?.jobStatus === "Pending Assignment" ? 1 :
                                                                            jobItem?.jobStatus === "Completed" ? 0 :
                                                                              (jobItem?.jobStatus === "Nurse Assigned" || jobItem?.jobStatus === "In-Progress") ? 0 :
                                                                                jobItem?.jobStatus === "Pending Approval" ? 1 :
                                                                                  (jobItem?.jobStatus === "Missed" || jobItem?.jobStatus === "Pending Clock Out") ? 0 :
                                                                                    0,
                                                                    padding: 5,
                                                                    margin: 3,
                                                                    borderRadius: 3
                                                                  }}>
                                                                  {/* <Text style={{ marginBottom: 2, color: "#fff", fontSize: 11 }}>{jobUniqueId(jobItem?.createdAt, jobItem?.jobType)}-{jobItem?.id}</Text> */}
                                                                  <View style={{ flexDirection: "row" }}>
                                                                    <Text style={{ color: "#fff", fontSize: 11 }}>{moment(jobItem?.startTime).format("hh:mma")} - </Text>
                                                                    <Text style={{ color: "#fff", fontSize: 11 }}>{moment(jobItem?.endTime).format("hh:mma")} - </Text>
                                                                    <Text style={{ color: "#fff", fontSize: 11 }}>{timeDifferentCard(jobItem?.startTime, jobItem?.endTime)}</Text>
                                                                  </View>
                                                                  <Text style={{ color: "#fff", fontSize: 11, fontWeight: "600", marginTop: 3 }}>
                                                                    Status : {jobItem?.jobStatus}</Text>
                                                                  <Text style={{ color: "#fff", fontSize: 12, fontWeight: "600", marginTop: 3 }}>{jobItem?.shiftTitle}</Text>
                                                                </TouchableOpacity>
                                                                :
                                                                <TouchableOpacity
                                                                  onPress={() =>
                                                                    webNavigate(jobItem?.status, {
                                                                      userId: jobItem?.nurseTableID,
                                                                      timeOffId: jobItem?.id,
                                                                    }, jobItem?.startDate)
                                                                  }
                                                                  style={{
                                                                    backgroundColor: jobItem?.status === "Pending" ? "#888" : "red",
                                                                    padding: 5,
                                                                    borderRadius: 3,
                                                                    marginVertical: 2
                                                                  }}>
                                                                  {jobItem?.startTime === "" && jobItem?.endTime === "" ?
                                                                    <View style={{ flexDirection: "row" }}>
                                                                      <Text style={{ color: "#fff", fontSize: 11 }}>Full Day</Text>
                                                                    </View>
                                                                    :
                                                                    <View style={{ flexDirection: "row" }}>
                                                                      <Text style={{ color: "#fff", fontSize: 11 }}>{moment(jobItem?.startTime).format("hh:mma")} - </Text>
                                                                      <Text style={{ color: "#fff", fontSize: 11 }}>{moment(jobItem?.endTime).format("hh:mma")} - </Text>
                                                                      <Text style={{ color: "#fff", fontSize: 11 }}>{timeDifferentCard(jobItem?.startTime, jobItem?.endTime)}</Text>
                                                                    </View>}
                                                                  <Text style={{ color: "#fff", fontSize: 12, fontWeight: "600", marginTop: 3 }}>
                                                                    {/* {jobItem?.timeOffReason} */}
                                                                    TimeOff-{jobItem?.status}
                                                                  </Text>
                                                                </TouchableOpacity>
                                                              }
                                                            </View>
                                                          )
                                                        }))}
                                                    </View>
                                                  </View>
                                              }
                                            </View>
                                          )
                                        })}
                                      </View>
                                    );
                                  })}
                                </View>
                              </View>
                            </View>
                          </ScrollView>
                          {nurseList
                            .filter(nurse => !nurseListId?.some(id => id === nurse.id))
                            .length > 0 &&
                            <View style={{ marginHorizontal: 10 }}>
                              <Text style={{
                                marginBottom: 10,
                                fontWeight: "700"
                              }}>Nurse List</Text>
                              {nurseList
                                .filter(nurse => !nurseListId?.some(id => id === nurse.id))
                                .map((item, index) => {
                                  return (
                                    <View key={index}>
                                      <TouchableOpacity style={{
                                        flexDirection: "row",
                                        width: 230,
                                        alignItems: "center",
                                        backgroundColor: "#f2f2f2",
                                        padding: 5,
                                        borderRadius: 5,
                                        marginTop: 3
                                      }}
                                        onPress={() =>
                                          onUserDetailNavigate({
                                            id: item?.uniqueId,
                                            facilityId: userUniqueId,
                                          })
                                        }>
                                        <View
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            borderWidth: 1,
                                            borderRadius: 10,
                                            borderColor: "#2775BD",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#fff",
                                            width: 40,
                                            height: 40,
                                            overflow: "hidden",
                                          }}
                                        >
                                          {item?.profileImage ? (
                                            <Image
                                              source={{
                                                uri: item?.profileImage
                                              }}
                                              style={{
                                                width: 38,
                                                height: 38,
                                                borderRadius: 10,
                                              }}

                                            />
                                          ) : (
                                            <Image
                                              source={require("../../../assets/images/icon.jpg")}
                                              style={{ width: 80, height: 80 }}
                                            />
                                          )}
                                        </View>
                                        <View
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            flex: 1,
                                          }}
                                        >
                                          <View
                                            style={{
                                              marginLeft: 10,
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Text style={{
                                              color: item["id"]?.firstName === "Unassigned" ? "#000" : "#2775BD", fontSize: 12, fontWeight: "700",
                                            }}>{item?.firstName + " " + item?.lastName}</Text>
                                            <Text style={{ color: "#000", fontWeight: "700", fontSize: 10, marginTop: 2 }}>
                                              {item?.primaryLicenseType}
                                            </Text>
                                          </View>
                                        </View>
                                        <TouchableOpacity
                                          onPress={() => handleNurse(item?.id)}
                                          style={{ marginLeft: 3 }}
                                        >
                                          <Ionicons name="add" size={20} color="#00b359" />
                                        </TouchableOpacity>
                                      </TouchableOpacity>
                                    </View>
                                  )
                                })}
                            </View>}
                        </ScrollView>
                      </View>
                }
              </View>
            </View>
          }
        </ScrollView>}

      {/* Model Window */}
      {showAddressWeb && Platform.OS === "web" &&
        <View
          style={{
            height: height,
            width: width,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "absolute"
          }}>
          <AutoFillAddress
            setShowAddress={(value) => {
              setAddJob(true)
              setShowAddressWeb(value)
            }}
            jobState={jobSelection}
            setJobState={setJobSelection}
          />
        </View>}

      <Modal
        animationType="fade"
        transparent={true}
        visible={showAddress}
        onRequestClose={() => {
          setAddJob(true)
          setShowAddress(false)
        }}
      >
        <AutoFillAddress
          setShowAddress={(value) => {
            setAddJob(true)
            setShowAddress(value)
          }}
          jobState={jobSelection}
          setJobState={setJobSelection}
        />
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={addJob}
        onRequestClose={() => setAddJob(false)}
      >
        <View
          style={{
            height: height,
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 10,
              elevation: 5,
              width: width / 1.1,
              paddingHorizontal: 10,
              borderRadius: 10,
              marginTop: Platform.OS === "web" ? 10 : 40,
              borderColor: "rgba(0, 0, 0, 0.1)",
            }}
          >

            <View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  borderBottomWidth: 1,
                  marginBottom: 3
                }}
              >
                <TouchableOpacity
                  style={{
                    backgroundColor: formView === "Custom" ? "#000" : "#00000000",
                    flex: 1,
                    borderTopWidth: formView === "Custom" ? 1 : 0,
                    borderRightWidth: formView === "Custom" ? 1 : 0,
                    borderLeftWidth: formView === "Custom" ? 1 : 0,
                    alignItems: "center",
                    height: 25,
                    justifyContent: 'center'
                  }}
                  activeOpacity={0.5}
                  onPress={() => {
                    setFormView("Custom");
                    setSelectedTemplate(undefined);
                    setLicenseTypeArr([jobSelection?.nurse?.primaryLicenseType])
                    setJobSelection({
                      ...jobSelection,
                      jobType: appJobType === "Shift" ? "Shift" :
                        appJobType === "Visit" ? "Visit" : "Shift",
                      startTime: new Date().toString(),
                      endTime: moment().add(1, "hour"),
                      title: "",
                      notes: "",
                      break: "",
                      customerVisibility: false,
                      customer: "",
                      fullAddress: "",
                      latitude: "",
                      longitude: "",
                      unit: ""
                    })
                    setFormTemView("Select")
                    setTemplateName("")
                    setSelectedHours("1")
                    setFullDay(false)
                  }}
                >
                  <Text
                    style={{ fontWeight: "500", fontSize: 12, color: formView === "Custom" ? "#fff" : "#000", }}
                  >
                    Custom
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: formView === "Template" ? "#000" : "#00000000",
                    borderTopWidth: formView === "Template" ? 1 : 0,
                    borderRightWidth: formView === "Template" ? 1 : 0,
                    borderLeftWidth: formView === "Template" ? 1 : 0,
                    flex: 1,
                    alignItems: "center",
                    height: 25,
                    justifyContent: 'center'
                  }}
                  activeOpacity={0.5}
                  onPress={() => {
                    setFormView("Template");
                    setJobSelection({
                      ...jobSelection,
                      jobType: appJobType === "Shift" ? "Shift" :
                        appJobType === "Visit" ? "Visit" : "Shift",
                      startTime: new Date().toString(),
                      endTime: moment().add(1, "hour"),
                      title: "",
                      notes: "",
                      break: "",
                      customerVisibility: false,
                      customer: "",
                      fullAddress: "",
                      latitude: "",
                      longitude: "",
                      unit: ""
                    })
                    setSelectedHours("1")
                    setFullDay(false)
                  }}
                >
                  <Text
                    style={{ fontWeight: "500", fontSize: 12, color: formView === "Template" ? "#fff" : "#000", }}
                  >
                    Template
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    backgroundColor: formView === "Time Off" ? "#000" : "#00000000",
                    borderTopWidth: formView === "Time Off" ? 1 : 0,
                    borderRightWidth: formView === "Time Off" ? 1 : 0,
                    borderLeftWidth: formView === "Time Off" ? 1 : 0,
                    flex: 1,
                    alignItems: "center",
                    height: 25,
                    justifyContent: 'center'
                  }}
                  activeOpacity={0.5}
                  onPress={() => {
                    setJobSelection({
                      ...jobSelection,
                      jobType: appJobType === "Shift" ? "Shift" :
                        appJobType === "Visit" ? "Visit" : "Shift",
                      startTime: new Date().toString(),
                      endTime: moment().add(1, "hour"),
                      title: "",
                      notes: "",
                      break: "",
                      customerVisibility: false,
                      customer: "",
                      fullAddress: "",
                      latitude: "",
                      longitude: "",
                      unit: ""
                    })
                    setFormView("Time Off");
                    setSelectedHours("1")
                    setFullDay(false)
                  }}
                >
                  <Text
                    style={{ fontWeight: "500", fontSize: 12, color: formView === "Time Off" ? "#fff" : "#000", }}
                  >
                    Time Off
                  </Text>
                </TouchableOpacity>
              </View>
            </View>

            {
              formView !== "Time Off" ?
                formView === "Template" && formTemView === "Create" ?
                  <View>
                    <View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>

                        <Text
                          style={{
                            fontSize: 12,
                            color: "#737373",
                          }} >Date : {moment(jobSelection.startDate).format("MM-DD-YYYY")}-
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            backgroundColor: "#fff",
                          }}>
                          <Text style={{
                            fontSize: 12,
                            color: "#737373",
                          }} >Job Typ : </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              backgroundColor: "#fff",
                            }}
                          >
                            {(appJobType === "Shift" || appJobType === "Both") &&
                              <TouchableOpacity
                                style={{
                                  backgroundColor: jobSelection.jobType === "Shift" ? "#00b359" : "#00000000",
                                  paddingVertical: 4,
                                  paddingHorizontal: 9,
                                  borderRadius: 3,
                                  alignItems: "center",
                                }}
                                activeOpacity={0.5}
                                onPress={() => {
                                  setJobSelection(prevState => ({
                                    ...prevState,
                                    jobType: "Shift",
                                  }));
                                }}
                              >
                                <Text
                                  style={{ fontWeight: "500", fontSize: 12, color: jobSelection.jobType === "Shift" ? "#fff" : "#000", }}
                                >
                                  Shift
                                </Text>
                              </TouchableOpacity>}
                            {(appJobType === "Visit" || appJobType === "Both") &&
                              <TouchableOpacity
                                style={{
                                  backgroundColor: jobSelection.jobType === "Visit" ? "#00b359" : "#00000000",
                                  paddingVertical: 4,
                                  paddingHorizontal: 9,
                                  borderRadius: 3,
                                  alignItems: "center",
                                }}
                                activeOpacity={0.5}
                                onPress={() => {
                                  setJobSelection(prevState => ({
                                    ...prevState,
                                    jobType: "Visit",
                                    endTime: moment(jobSelection.startTime).add(1, "hour"),
                                  }));
                                }}
                              >
                                <Text
                                  style={{ fontWeight: "500", fontSize: 12, color: jobSelection.jobType === "Visit" ? "#fff" : "#000", }}
                                >
                                  Visit
                                </Text>
                              </TouchableOpacity>}
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: 3,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              color: "#737373",
                            }}
                          >
                            Start Time<Text style={{ color: "red" }}>*</Text>
                          </Text>
                          <TouchableOpacity
                            style={{
                              marginLeft: 5,
                              borderWidth: 1,
                              borderColor: "#e6e6e6",
                              backgroundColor: "#f2f2f2",
                              borderRadius: 5,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: 3,
                            }}
                            onPress={() => {
                              setAddJob(false)
                              setShowTimeWeb(true)
                            }}
                          >
                            <Text
                              style={{
                                textAlign: "center",
                                fontSize: 12,
                                color: "#737373",
                              }}
                            >
                              {moment(jobSelection.startTime).format("hh:mm a")}
                            </Text>
                            <MaterialIcons name="access-time" size={20} color="#808080" />
                          </TouchableOpacity>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              color: "#737373",
                            }}
                          >
                            End Time<Text style={{ color: "red" }}>*</Text>
                          </Text>
                          <TouchableOpacity
                            // onPress={() => {
                            //   setAddJob(false)
                            //   setShowTimeEndWeb(true)
                            // }}
                            style={{
                              marginLeft: 5,
                              borderWidth: 1,
                              borderColor: "#e6e6e6",
                              backgroundColor: "#f2f2f2",
                              borderRadius: 5,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: 3,
                            }}
                          >
                            <Text
                              style={{
                                textAlign: "center",
                                fontSize: 12,
                                color: "#737373",
                              }}
                            >
                              {moment(jobSelection.endTime).format("hh:mm a")}
                            </Text>
                            <MaterialIcons name="access-time" size={20} color="#808080" />
                          </TouchableOpacity>
                        </View>
                      </View>
                      {jobSelection.jobType === "Shift" && <View
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginHorizontal: 10,
                          marginBottom: 15,
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginHorizontal: 20,
                            marginTop: 10
                          }}
                        >
                          <Text
                            style={[
                              {
                                fontSize: 12,
                                color: "#737373",
                              },
                            ]}
                          >
                            Select Hour's (
                            <Text
                              style={[
                                {
                                  fontSize: 12,
                                  color: "#000",
                                  fontWeight: "bold",
                                  marginBottom: 10,
                                },
                              ]}
                            >
                              {selectedHours !== "" &&
                                `${selectedHours} ${selectedHours >= 2 ? "Hour(s)" : "Hour"
                                }`}
                            </Text>
                            )<Text style={{ color: "red" }}>*</Text>
                          </Text>
                        </View>
                        {/* <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            top: 5,
                          }}
                        >
                          <Text style={{ fontSize: 12 }}>1 H</Text>
                          <Text style={{ fontSize: 12 }}>12 H</Text>
                        </View> */}
                        <View>
                          {/* <CustomSliderFilter
                            sliderWidth={width / 1.5}
                            min={1}
                            max={12}
                            step={1}
                            type={""}
                            label={true}
                            color={"#00b359"}
                            onValueChange={(range) => {
                              setSelectedHours(range.max);

                              let date = new Date();
                              let dateEnd = new Date();

                              let nowEnd = new Date(jobSelection.startTime);
                              let selectedMinuteTime = nowEnd.getMinutes().toString().padStart(2, "0");
                              let selectedAPTime = nowEnd.getHours() >= 12 ? "PM" : "AM";
                              let selectedHourTime = nowEnd.getHours() === 0
                                ? 12
                                : nowEnd.getHours() > 12
                                  ? nowEnd.getHours() - 12
                                  : nowEnd.getHours()

                              date.setHours(
                                selectedAPTime === "AM" && selectedHourTime === 12
                                  ? 0
                                  : selectedAPTime === "PM" && selectedHourTime === 12
                                    ? 12
                                    : selectedHourTime !== 12 && selectedAPTime === "PM"
                                      ? parseInt(selectedHourTime) + 12
                                      : parseInt(selectedHourTime)
                              );
                              date.setMinutes(selectedMinuteTime);

                              // Add 8 hours to dateEnd based on date
                              dateEnd.setFullYear(date.getFullYear());
                              dateEnd.setMonth(date.getMonth());
                              dateEnd.setDate(date.getDate());
                              dateEnd.setHours(date.getHours() + range.max);
                              dateEnd.setMinutes(date.getMinutes());

                              // Determine if it's AM or PM
                              let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

                              if (selectedAPTime === "PM" && meridiem === "AM") {
                                let endDate = moment(jobSelection.startDate);
                                endDate.add(1, "day")
                                setJobSelection({
                                  ...jobSelection,
                                  startTime: date,
                                  endTime: dateEnd,
                                  endDate: endDate,
                                });
                              } else {
                                setJobSelection({
                                  ...jobSelection,
                                  startTime: date,
                                  endTime: dateEnd,
                                  endDate: jobSelection.startDate,
                                });
                              }
                            }}
                            valueMin={0}
                            valueMax={parseInt(selectedHours)}
                            minDrag={false}
                          /> */}
                          <Text
                            style={{
                              fontSize: 12,
                              color: "#737373",
                              marginLeft: -5,
                              marginBottom: 2
                            }}
                          >
                            Duration
                          </Text>
                          <CustomSliderFilterDropDown
                            sliderWidth={-10}
                            min={1}
                            max={12}
                            step={1}
                            type={""}
                            label={true}
                            color={"#737373"}
                            onValueChange={(range) => {
                              setSelectedHours(range.max);

                              let date = new Date();
                              let dateEnd = new Date();

                              let nowEnd = new Date(jobSelection.startTime);
                              let selectedMinuteTime = nowEnd.getMinutes().toString().padStart(2, "0");
                              let selectedAPTime = nowEnd.getHours() >= 12 ? "PM" : "AM";
                              let selectedHourTime = nowEnd.getHours() === 0
                                ? 12
                                : nowEnd.getHours() > 12
                                  ? nowEnd.getHours() - 12
                                  : nowEnd.getHours()

                              date.setHours(
                                selectedAPTime === "AM" && selectedHourTime === 12
                                  ? 0
                                  : selectedAPTime === "PM" && selectedHourTime === 12
                                    ? 12
                                    : selectedHourTime !== 12 && selectedAPTime === "PM"
                                      ? parseInt(selectedHourTime) + 12
                                      : parseInt(selectedHourTime)
                              );
                              date.setMinutes(selectedMinuteTime);

                              // Add 8 hours to dateEnd based on date
                              dateEnd.setFullYear(date.getFullYear());
                              dateEnd.setMonth(date.getMonth());
                              dateEnd.setDate(date.getDate());
                              dateEnd.setHours(date.getHours() + range.max);
                              dateEnd.setMinutes(date.getMinutes());

                              // Determine if it's AM or PM
                              let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

                              if (selectedAPTime === "PM" && meridiem === "AM") {
                                let endDate = moment(jobSelection.startDate);
                                endDate.add(1, "day")
                                setJobSelection({
                                  ...jobSelection,
                                  startTime: date,
                                  endTime: dateEnd,
                                  endDate: endDate,
                                });
                              } else {
                                setJobSelection({
                                  ...jobSelection,
                                  startTime: date,
                                  endTime: dateEnd,
                                  endDate: jobSelection.startDate,
                                });
                              }
                            }}
                            valueMin={0}
                            valueMax={parseInt(selectedHours)}
                            minDrag={false}
                            dType={"time"}
                          />
                        </View>
                      </View>}
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: -3,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              color: "#737373",
                            }}
                          >
                            Break<Text style={{ color: "red" }}>*</Text>
                          </Text>
                          <TouchableOpacity
                            style={{
                              marginLeft: 5,
                              borderWidth: 1,
                              borderColor: "#e6e6e6",
                              backgroundColor: "#f2f2f2",
                              borderRadius: 5,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: 3,
                            }}
                            onPress={() => {
                              setAddJob(false)
                              setShowBreak(true)
                            }}
                          >
                            <Text
                              style={{
                                textAlign: "center",
                                fontSize: 12,
                                color: "#737373",
                              }}
                            >
                              {jobSelection.break === "" ?
                                "Select Break" : jobSelection.break}
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              color: "#737373",
                            }}
                          >
                            Job Duration
                          </Text>
                          <TouchableOpacity
                            style={{
                              marginLeft: 5,
                              borderWidth: 1,
                              borderColor: "#e6e6e6",
                              backgroundColor: "#f2f2f2",
                              borderRadius: 5,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: 3,
                            }}
                          >
                            <Text
                              style={{
                                textAlign: "center",
                                fontSize: 12,
                                color: "#737373",
                              }}
                            >
                              {timeDifferent(jobSelection.startTime, jobSelection.endTime)}
                            </Text>
                            <MaterialIcons name="access-time" size={20} color="#808080" />
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginTop: 5,
                        }}
                      >
                        <CustomInput
                          required={true}
                          label={`Template Name`}
                          onChangeText={(text) =>
                            setTemplateName(text)
                          }
                          keyboardType={"default"}
                          placeholder={`Template Name`}
                          value={templateName}
                          clearValue={() => setTemplateName("")}
                          viewStyle={{
                            height: 40,
                            backgroundColor: "#f2f2f2",
                            borderRadius: 10,
                            elevation: 1,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            borderWidth: 0.5,
                            borderColor:
                              error && templateName === "" ? "red" : "#e6e6e6",
                            paddingRight: 10,
                            justifyContent: "space-between",
                            marginTop: 0,
                          }}
                          textInputStyle={{
                            flex: 1,
                            height: 40,
                            borderRadius: 10,
                            paddingLeft: 10,
                            paddingRight: 5,
                            fontSize: 12,
                          }}
                          iconStyle={{
                            height: 40,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        />
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginTop: 5,
                        }}
                      >
                        <CustomInput
                          required={true}
                          label={`${jobSelection.jobType} Title`}
                          onChangeText={(text) =>
                            setJobSelection({ ...jobSelection, title: text })
                          }
                          keyboardType={"default"}
                          placeholder={`${jobSelection.jobType} Title`}
                          value={jobSelection.title}
                          clearValue={() => setJobSelection({ ...jobSelection, title: "" })}
                          viewStyle={{
                            height: 40,
                            backgroundColor: "#f2f2f2",
                            borderRadius: 10,
                            elevation: 1,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            borderWidth: 0.5,
                            borderColor:
                              error && jobSelection.title === "" ? "red" : "#e6e6e6",
                            paddingRight: 10,
                            justifyContent: "space-between",
                            marginTop: 0,
                          }}
                          textInputStyle={{
                            flex: 1,
                            height: 40,
                            borderRadius: 10,
                            paddingLeft: 10,
                            paddingRight: 5,
                            fontSize: 12,
                          }}
                          iconStyle={{
                            height: 40,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        />
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginTop: 5,
                        }}
                      >
                        <CustomInput
                          label={`Notes`}
                          onChangeText={(text) =>
                            setJobSelection({ ...jobSelection, notes: text })
                          }
                          keyboardType={"default"}
                          placeholder={`Notes`}
                          value={jobSelection.notes}
                          clearValue={() => setJobSelection({ ...jobSelection, notes: "" })}
                          viewStyle={{
                            height: 40,
                            backgroundColor: "#f2f2f2",
                            borderRadius: 10,
                            elevation: 1,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            borderWidth: 0.5,
                            borderColor: "#e6e6e6",
                            paddingRight: 10,
                            justifyContent: "space-between",
                            marginTop: 0,
                          }}
                          textInputStyle={{
                            flex: 1,
                            height: 40,
                            borderRadius: 10,
                            paddingLeft: 10,
                            paddingRight: 5,
                            fontSize: 12,
                          }}
                          iconStyle={{
                            height: 40,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        />
                      </View>
                      <View
                        style={{
                          marginTop: 5,
                        }}
                      >
                        <Text
                          style={[
                            {
                              fontSize: 12,
                              color: "#737373",
                            },
                          ]}
                        >
                          License Type
                          <Text style={{ color: "red" }}>*</Text>
                        </Text>
                        <View style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5
                        }}>
                          <TouchableOpacity
                            style={{
                              height: 40,
                              borderWidth: 1,
                              borderColor:
                                error && licenseTypeArr.length === 0 ? "red" : "#e6e6e6",
                              backgroundColor: "#f2f2f2",
                              borderRadius: 10,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              paddingHorizontal: 10,
                              marginRight: 10,
                            }}
                            onPress={() => {
                              setAddJob(false)
                              setShowLicenseType(true)
                            }}
                          >
                            <MaterialIcons
                              name={"add"}
                              size={24}
                            />
                          </TouchableOpacity>
                          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                            {licenseTypeArr?.map((item, index) => {
                              return (
                                <View
                                  key={index}
                                  style={{
                                    height: 40,
                                    borderWidth: 1,
                                    borderColor: "#e6e6e6",
                                    backgroundColor: "#f2f2f2",
                                    borderRadius: 10,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    paddingHorizontal: 10,
                                    paddingVertical: 5,
                                    marginRight: 10,
                                  }}
                                >
                                  <Text style={{ fontSize: 12, color: "#737373" }}>
                                    {item}
                                  </Text>
                                  <TouchableOpacity
                                    onPress={() => handleRemoveLicenseType(index)}
                                  >
                                    <MaterialIcons
                                      name={"delete-outline"}
                                      size={24}
                                      color="#999"
                                    />
                                  </TouchableOpacity>
                                </View>
                              );
                            })}
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          marginTop: 5,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text
                            style={{
                              marginBottom: 5,
                              fontSize: 12,
                              color: "#737373",
                            }}
                          >
                            Customer Name
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                marginBottom: 5,
                                fontSize: 12,
                                color: "#737373",
                              }}
                            >
                              After Assignment
                            </Text>
                            <CustomToggle
                              state={jobSelection.customerVisibility}
                              onValueChange={(value) =>
                                setJobSelection({ ...jobSelection, customerVisibility: value })
                              }
                            />
                          </View>
                        </View>

                        <TouchableOpacity
                          onPress={() => {
                            setAddJob(false)
                            setPatientView(true)
                          }}
                          style={{
                            backgroundColor: "#f2f2f2",
                            borderRadius: 10,
                            elevation: 1,
                            height: 40,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            borderWidth: 0.5,
                            borderColor: "#e6e6e6",
                            paddingRight: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <Text
                            style={{
                              color: "#737373",
                              paddingLeft: 5,
                              fontSize: 12,
                            }}
                          >
                            {jobSelection.customer === ""
                              ? "Select Customer"
                              : jobSelection?.customer}
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View
                        style={{ marginTop: 5 }}>
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={[
                              {
                                fontSize: 12,
                                color: "#737373",
                              },
                            ]}
                          >
                            Address
                          </Text>
                          <Text style={{ color: "red" }}>*</Text>
                        </View>
                        <View
                          style={{
                            marginTop: 5,
                          }}
                        >
                          <TouchableOpacity
                            style={{
                              borderWidth: 0.5,
                              borderColor:
                                error && jobSelection.fullAddress === "" ? "red" : "#e6e6e6",
                              backgroundColor: "#f2f2f2",
                              borderRadius: 10,
                              elevation: 1,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              paddingHorizontal: 10,
                              paddingVertical: 10,
                            }}
                            onPress={() => {
                              setAddJob(false)
                              if (Platform.OS === "web") {
                                setShowAddressWeb(true);
                              } else {
                                setShowAddress(true);
                              }
                            }}
                          >
                            <Text
                              style={{
                                textAlign: "center",
                                fontSize: 12,
                                color: "#737373",
                              }}
                            >
                              {jobSelection.fullAddress !== ""
                                ? jobSelection.fullAddress
                                : "Enter Address"}
                            </Text>
                            {jobSelection.fullAddress === "" && (
                              <MaterialIcons name={"add"} size={24} color="#808080" />
                            )}
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: 5
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          const ct = new Date(jobSelection?.startTime);
                          const currentHour = ct.getHours();
                          let jobTiming =
                            (currentHour >= 5 && currentHour < 12) ? "Morning" :
                              (currentHour >= 12 && currentHour < 17) ? "Afternoon" :
                                (currentHour >= 17 && currentHour < 20) ? "Evening" :
                                  "Night";
                          postTemplate(jobSelection?.startDate, jobSelection?.endDate, jobTiming)
                        }}
                        style={{
                          backgroundColor: "#00b359",
                          paddingHorizontal: 10,
                          paddingVertical: 5,
                          borderRadius: 5,
                          marginTop: 5,
                          marginHorizontal: 5,
                        }}
                      >
                        <Text
                          style={{
                            textAlign: "right",
                            fontSize: 12,
                            color: "#fff",
                          }}
                        >
                          Create Template
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          setAddJob(false)
                          setJobSelection({
                            nurse: {},
                            jobType: appJobType === "Shift" ? "Shift" :
                              appJobType === "Visit" ? "Visit" : "Shift",
                            title: "",
                            notes: "",
                            break: "",
                            customerVisibility: false,
                            startDate: new Date().toString(),
                            endDate: new Date().toString(),
                            startTime: new Date().toString(),
                            endTime: moment().add(1, "hour"),
                            customer: "",
                            fullAddress: "",
                            latitude: "",
                            longitude: "",
                            unit: ""
                          })
                          setLicenseTypeArr([])
                          setSelectedTemplate(undefined)
                          setFormView("Custom")
                          setTimeOff({
                            timeOffReason: "",
                            startDate: new Date().toString(),
                            startTime: new Date().toString(),
                            endDate: new Date().toString(),
                            endTime: moment().add(1, "hour"),
                            alternateContact: "",
                            phoneNum: "",
                            comments: "",
                          })
                          setError(false);
                          setFormTemView("Select")
                          setTemplateName("")
                          setSelectedHours("1")
                        }}
                        style={{
                          backgroundColor: "#ccc",
                          paddingHorizontal: 10,
                          paddingVertical: 5,
                          borderRadius: 5,
                          marginTop: 5,
                        }}
                      >
                        <Text
                          style={{
                            textAlign: "right",
                            fontSize: 12,
                            color: "#000",
                          }}
                        >
                          Close
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                  : formView === "Template" && formTemView === "Select" && selectedTemplate === undefined ?
                    <View style={{ marginTop: 5 }}>
                      <TouchableOpacity
                        onPress={() => {
                          setFormTemView("Create")
                        }}
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#00b359",
                          paddingHorizontal: 10,
                          paddingVertical: 8,
                          borderRadius: 5,
                          marginTop: 5,
                        }}
                      >
                        <MaterialIcons
                          name={"add"}
                          size={20}
                          color={"#ffff"}
                        />
                        <Text
                          style={{
                            textAlign: "center",
                            fontSize: 12,
                            color: "#fff",
                          }}
                        > Create Template
                        </Text>
                      </TouchableOpacity>
                      <View
                        style={{ flexDirection: "row" }}>
                        {(appJobType === "Shift" || appJobType === "Both") &&
                          <TouchableOpacity
                            onPress={() => {
                              setFormTemType("Shift")
                            }}
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: formTemType === "Shift" ? "#00b359" : "#00000000",
                              paddingVertical: 8,
                              borderRadius: 5,
                              marginVertical: 5,
                              marginRight: 2,
                              borderWidth: formTemType === "Shift" ? 0 : 0.5,
                            }}
                          >
                            <Text
                              style={{
                                textAlign: "center",
                                fontSize: 12,
                                color: formTemType === "Shift" ? "#fff" : "#000",
                              }}
                            > Shift
                            </Text>
                          </TouchableOpacity>}
                        {(appJobType === "Visit" || appJobType === "Both") &&
                          <TouchableOpacity
                            onPress={() => {
                              setFormTemType("Visit")
                            }}
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: formTemType === "Visit" ? "#00b359" : "#00000000",
                              paddingVertical: 8,
                              borderRadius: 5,
                              marginVertical: 5,
                              marginLeft: 2,
                              borderWidth: formTemType === "Visit" ? 0 : 0.5,
                            }}
                          >
                            <Text
                              style={{
                                textAlign: "center",
                                fontSize: 12,
                                color: formTemType === "Visit" ? "#fff" : "#000",
                              }}
                            > Visit
                            </Text>
                          </TouchableOpacity>}
                      </View>
                      <CustomTemplate
                        options={
                          templateList === undefined
                            ? []
                            : templateList?.filter(
                              i => i?.licenseType?.includes(jobSelection?.nurse?.primaryLicenseType))?.filter(
                                i => i?.jobType === formTemType)
                        }
                        selectedValue={"Search Template"}
                        onValueChange={(value) => {

                          let selectedAPTime = moment(value?.startTime).format("a")
                          let meridiem = moment(value?.endTime).format("a")

                          if (selectedAPTime === "pm" && meridiem === "am") {
                            const getDateForEndDate = moment(moment(jobSelection.startDate).format("YYYY-MM-DD"));
                            // Add one day
                            var nightEndDate = getDateForEndDate.add(1, 'day');
                            // Format the new date if needed
                            var endDateSet = nightEndDate.format('YYYY-MM-DD');
                            setJobSelection({
                              ...jobSelection,
                              jobType: value?.jobType,
                              title: value?.shiftTitle,
                              notes: value?.notes,
                              break: value?.break,
                              customerVisibility: value?.customerVisibility,
                              customer: value?.customerName,
                              customerId: value?.customerId,
                              fullAddress: value?.fullAddress,
                              latitude: value?.latitude,
                              longitude: value?.longitude,
                              unit: value?.unit,
                              startTime: new Date(value?.startTime),
                              endTime: new Date(value?.endTime),
                              endDate: endDateSet
                            })
                            setSelectedHours(timeDifferentFun(value?.startTime, value?.endTime))
                            // console.log("endDateSet", endDateSet)
                          } else {
                            setJobSelection({
                              ...jobSelection,
                              jobType: value?.jobType,
                              title: value?.shiftTitle,
                              notes: value?.notes,
                              break: value?.break,
                              customerVisibility: value?.customerVisibility,
                              customer: value?.customerName,
                              customerId: value?.customerId,
                              fullAddress: value?.fullAddress,
                              latitude: value?.latitude,
                              longitude: value?.longitude,
                              unit: value?.unit,
                              startTime: new Date(value?.startTime),
                              endTime: new Date(value?.endTime),
                              endDate: jobSelection.startDate
                            })
                            setSelectedHours(timeDifferentFun(value?.startTime, value?.endTime))
                          }

                          setSelectedTemplate(value);

                          const arr = [];
                          value?.licenseType?.map(i => {
                            arr.push(i)
                          })
                          if (!arr.includes(jobSelection?.nurse?.primaryLicenseType)) {
                            arr.push(jobSelection?.nurse?.primaryLicenseType)
                          }
                          setLicenseTypeArr(arr)
                        }}
                      />
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          marginTop: 5
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            setAddJob(false)
                            setJobSelection({
                              nurse: {},
                              jobType: appJobType === "Shift" ? "Shift" :
                                appJobType === "Visit" ? "Visit" : "Shift",
                              title: "",
                              notes: "",
                              break: "",
                              customerVisibility: false,
                              startDate: new Date().toString(),
                              startTime: new Date().toString(),
                              endDate: new Date().toString(),
                              endTime: moment().add(1, "hour"),
                              customer: "",
                              fullAddress: "",
                              latitude: "",
                              longitude: "",
                              unit: ""
                            })
                            setLicenseTypeArr([])
                            setSelectedTemplate(undefined)
                            setFormView("Custom")
                            setSelectedHours("1")
                          }}
                          style={{
                            backgroundColor: "#ccc",
                            paddingHorizontal: 10,
                            paddingVertical: 5,
                            borderRadius: 5,
                            marginTop: 5,
                          }}
                        >
                          <Text
                            style={{
                              textAlign: "right",
                              fontSize: 12,
                              color: "#000",
                            }}
                          >
                            Close
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    :
                    <View>
                      <View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}>

                          <Text
                            style={{
                              fontSize: 12,
                              color: "#737373",
                            }} >Date : {moment(jobSelection.startDate).format("MM-DD-YYYY")}</Text>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              backgroundColor: "#fff",
                            }}>
                            <Text style={{
                              fontSize: 12,
                              color: "#737373",
                            }} >Job Type : </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                backgroundColor: "#fff",
                              }}
                            >
                              {(appJobType === "Shift" || appJobType === "Both") &&
                                <TouchableOpacity
                                  style={{
                                    backgroundColor: jobSelection.jobType === "Shift" ? "#00b359" : "#00000000",
                                    paddingVertical: 4,
                                    paddingHorizontal: 9,
                                    borderRadius: 3,
                                    alignItems: "center",
                                  }}
                                  activeOpacity={0.5}
                                  onPress={() => {
                                    setJobSelection(prevState => ({
                                      ...prevState,
                                      jobType: "Shift"
                                    }));
                                  }}
                                >
                                  <Text
                                    style={{ fontWeight: "500", fontSize: 12, color: jobSelection.jobType === "Shift" ? "#fff" : "#000", }}
                                  >
                                    Shift
                                  </Text>
                                </TouchableOpacity>}
                              {(appJobType === "Visit" || appJobType === "Both") &&
                                <TouchableOpacity
                                  style={{
                                    backgroundColor: jobSelection.jobType === "Visit" ? "#00b359" : "#00000000",
                                    paddingVertical: 4,
                                    paddingHorizontal: 9,
                                    borderRadius: 3,
                                    alignItems: "center",
                                  }}
                                  activeOpacity={0.5}
                                  onPress={() => {
                                    setJobSelection(prevState => ({
                                      ...prevState,
                                      jobType: "Visit",
                                      endTime: moment(jobSelection.startTime).add(1, "hour"),
                                    }));
                                  }}
                                >
                                  <Text
                                    style={{ fontWeight: "500", fontSize: 12, color: jobSelection.jobType === "Visit" ? "#fff" : "#000", }}
                                  >
                                    Visit
                                  </Text>
                                </TouchableOpacity>}
                            </View>
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: 3,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 12,
                                color: "#737373",
                              }}
                            >
                              Start Time<Text style={{ color: "red" }}>*</Text>
                            </Text>
                            <TouchableOpacity
                              style={{
                                marginLeft: 5,
                                borderWidth: 1,
                                borderColor: "#e6e6e6",
                                backgroundColor: "#f2f2f2",
                                borderRadius: 5,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: 3,
                              }}
                              onPress={() => {
                                setAddJob(false)
                                setShowTimeWeb(true)
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: 12,
                                  color: "#737373",
                                }}
                              >
                                {moment(jobSelection.startTime).format("hh:mm a")}
                              </Text>
                              <MaterialIcons name="access-time" size={20} color="#808080" />
                            </TouchableOpacity>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 12,
                                color: "#737373",
                              }}
                            >
                              End Time<Text style={{ color: "red" }}>*</Text>
                            </Text>
                            <TouchableOpacity
                              // onPress={() => {
                              //   setAddJob(false)
                              //   setShowTimeEndWeb(true)
                              // }}
                              style={{
                                marginLeft: 5,
                                borderWidth: 1,
                                borderColor: "#e6e6e6",
                                backgroundColor: "#f2f2f2",
                                borderRadius: 5,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: 3,
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: 12,
                                  color: "#737373",
                                }}
                              >
                                {moment(jobSelection.endTime).format("hh:mm a")}
                              </Text>
                              <MaterialIcons name="access-time" size={20} color="#808080" />
                            </TouchableOpacity>
                          </View>
                        </View>
                        {jobSelection.jobType === "Shift" && <View
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginHorizontal: 10,
                            marginBottom: 15,
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginHorizontal: 20,
                              marginTop: 10
                            }}
                          >
                            <Text
                              style={[
                                {
                                  fontSize: 12,
                                  color: "#737373",
                                },
                              ]}
                            >
                              Select Hour's (
                              <Text
                                style={[
                                  {
                                    fontSize: 12,
                                    color: "#000",
                                    fontWeight: "bold",
                                    marginBottom: 10,
                                  },
                                ]}
                              >
                                {selectedHours !== "" &&
                                  `${selectedHours} ${selectedHours >= 2 ? "Hour(s)" : "Hour"
                                  }`}
                              </Text>
                              )<Text style={{ color: "red" }}>*</Text>
                            </Text>
                          </View>
                          {/* <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              top: 5,
                            }}
                          >
                            <Text style={{ fontSize: 12 }}>1 H</Text>
                            <Text style={{ fontSize: 12 }}>12 H</Text>
                          </View> */}
                          <View>
                            {/* <CustomSliderFilter
                              sliderWidth={width / 1.5}
                              min={1}
                              max={12}
                              step={1}
                              type={""}
                              label={true}
                              color={"#00b359"}
                              onValueChange={(range) => {
                                setSelectedHours(range.max);

                                let date = new Date();
                                let dateEnd = new Date();

                                let nowEnd = new Date(jobSelection.startTime);
                                let selectedMinuteTime = nowEnd.getMinutes().toString().padStart(2, "0");
                                let selectedAPTime = nowEnd.getHours() >= 12 ? "PM" : "AM";
                                let selectedHourTime = nowEnd.getHours() === 0
                                  ? 12
                                  : nowEnd.getHours() > 12
                                    ? nowEnd.getHours() - 12
                                    : nowEnd.getHours()

                                date.setHours(
                                  selectedAPTime === "AM" && selectedHourTime === 12
                                    ? 0
                                    : selectedAPTime === "PM" && selectedHourTime === 12
                                      ? 12
                                      : selectedHourTime !== 12 && selectedAPTime === "PM"
                                        ? parseInt(selectedHourTime) + 12
                                        : parseInt(selectedHourTime)
                                );
                                date.setMinutes(selectedMinuteTime);

                                // Add 8 hours to dateEnd based on date
                                dateEnd.setFullYear(date.getFullYear());
                                dateEnd.setMonth(date.getMonth());
                                dateEnd.setDate(date.getDate());
                                dateEnd.setHours(date.getHours() + range.max);
                                dateEnd.setMinutes(date.getMinutes());

                                // Determine if it's AM or PM
                                let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

                                if (selectedAPTime === "PM" && meridiem === "AM") {
                                  let endDate = moment(jobSelection.startDate);
                                  endDate.add(1, "day")
                                  setJobSelection({
                                    ...jobSelection,
                                    startTime: date,
                                    endTime: dateEnd,
                                    endDate: endDate,
                                  });
                                } else {
                                  setJobSelection({
                                    ...jobSelection,
                                    startTime: date,
                                    endTime: dateEnd,
                                    endDate: jobSelection.startDate,
                                  });
                                }
                              }}
                              valueMin={0}
                              valueMax={parseInt(selectedHours)}
                              minDrag={false}
                            /> */}
                            <Text
                              style={{
                                fontSize: 12,
                                color: "#737373",
                                marginLeft: -5,
                                marginBottom: 2
                              }}
                            >
                              Duration
                            </Text>
                            <CustomSliderFilterDropDown
                              sliderWidth={-10}
                              min={1}
                              max={12}
                              step={1}
                              type={""}
                              label={true}
                              color={"#737373"}
                              onValueChange={(range) => {
                                setSelectedHours(range.max);

                                let date = new Date();
                                let dateEnd = new Date();

                                let nowEnd = new Date(jobSelection.startTime);
                                let selectedMinuteTime = nowEnd.getMinutes().toString().padStart(2, "0");
                                let selectedAPTime = nowEnd.getHours() >= 12 ? "PM" : "AM";
                                let selectedHourTime = nowEnd.getHours() === 0
                                  ? 12
                                  : nowEnd.getHours() > 12
                                    ? nowEnd.getHours() - 12
                                    : nowEnd.getHours()

                                date.setHours(
                                  selectedAPTime === "AM" && selectedHourTime === 12
                                    ? 0
                                    : selectedAPTime === "PM" && selectedHourTime === 12
                                      ? 12
                                      : selectedHourTime !== 12 && selectedAPTime === "PM"
                                        ? parseInt(selectedHourTime) + 12
                                        : parseInt(selectedHourTime)
                                );
                                date.setMinutes(selectedMinuteTime);

                                // Add 8 hours to dateEnd based on date
                                dateEnd.setFullYear(date.getFullYear());
                                dateEnd.setMonth(date.getMonth());
                                dateEnd.setDate(date.getDate());
                                dateEnd.setHours(date.getHours() + range.max);
                                dateEnd.setMinutes(date.getMinutes());

                                // Determine if it's AM or PM
                                let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

                                if (selectedAPTime === "PM" && meridiem === "AM") {
                                  let endDate = moment(jobSelection.startDate);
                                  endDate.add(1, "day")
                                  setJobSelection({
                                    ...jobSelection,
                                    startTime: date,
                                    endTime: dateEnd,
                                    endDate: endDate,
                                  });
                                } else {
                                  setJobSelection({
                                    ...jobSelection,
                                    startTime: date,
                                    endTime: dateEnd,
                                    endDate: jobSelection.startDate,
                                  });
                                }
                              }}
                              valueMin={0}
                              valueMax={parseInt(selectedHours)}
                              minDrag={false}
                              dType={"time"}
                            />
                          </View>
                        </View>}
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: -3,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 12,
                                color: "#737373",
                              }}
                            >
                              Break<Text style={{ color: "red" }}>*</Text>
                            </Text>
                            <TouchableOpacity
                              style={{
                                marginLeft: 5,
                                borderWidth: 1,
                                borderColor: "#e6e6e6",
                                backgroundColor: "#f2f2f2",
                                borderRadius: 5,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: 3,
                              }}
                              onPress={() => {
                                setAddJob(false)
                                setShowBreak(true)
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: 12,
                                  color: "#737373",
                                }}
                              >
                                {jobSelection.break === "" ?
                                  "Select Break" : jobSelection.break}
                              </Text>
                            </TouchableOpacity>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 12,
                                color: "#737373",
                              }}
                            >
                              Job Duration
                            </Text>
                            <TouchableOpacity
                              style={{
                                marginLeft: 5,
                                borderWidth: 1,
                                borderColor: "#e6e6e6",
                                backgroundColor: "#f2f2f2",
                                borderRadius: 5,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: 3,
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: 12,
                                  color: "#737373",
                                }}
                              >
                                {timeDifferent(jobSelection.startTime, jobSelection.endTime)}
                              </Text>
                              <MaterialIcons name="access-time" size={20} color="#808080" />
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: 5,
                          }}
                        >
                          <CustomInput
                            required={true}
                            label={`${jobSelection.jobType} Title`}
                            onChangeText={(text) =>
                              setJobSelection({ ...jobSelection, title: text })
                            }
                            keyboardType={"default"}
                            placeholder={`${jobSelection.jobType} Title`}
                            value={jobSelection.title}
                            clearValue={() => setJobSelection({ ...jobSelection, title: "" })}
                            viewStyle={{
                              height: 40,
                              backgroundColor: "#f2f2f2",
                              borderRadius: 10,
                              elevation: 1,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              borderWidth: 0.5,
                              borderColor:
                                error && jobSelection.title === "" ? "red" : "#e6e6e6",
                              paddingRight: 10,
                              justifyContent: "space-between",
                              marginTop: 0,
                            }}
                            textInputStyle={{
                              flex: 1,
                              height: 40,
                              borderRadius: 10,
                              paddingLeft: 10,
                              paddingRight: 5,
                              fontSize: 12,
                            }}
                            iconStyle={{
                              height: 40,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          />
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: 5,
                          }}
                        >
                          <CustomInput
                            label={`Notes`}
                            onChangeText={(text) =>
                              setJobSelection({ ...jobSelection, notes: text })
                            }
                            keyboardType={"default"}
                            placeholder={`Notes`}
                            value={jobSelection.notes}
                            clearValue={() => setJobSelection({ ...jobSelection, notes: "" })}
                            viewStyle={{
                              height: 40,
                              backgroundColor: "#f2f2f2",
                              borderRadius: 10,
                              elevation: 1,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              borderWidth: 0.5,
                              borderColor: "#e6e6e6",
                              paddingRight: 10,
                              justifyContent: "space-between",
                              marginTop: 0,
                            }}
                            textInputStyle={{
                              flex: 1,
                              height: 40,
                              borderRadius: 10,
                              paddingLeft: 10,
                              paddingRight: 5,
                              fontSize: 12,
                            }}
                            iconStyle={{
                              height: 40,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          />
                        </View>
                        <View
                          style={{
                            marginTop: 5,
                          }}
                        >
                          <Text
                            style={[
                              {
                                fontSize: 12,
                                color: "#737373",
                              },
                            ]}
                          >
                            License Type
                            <Text style={{ color: "red" }}>*</Text>
                          </Text>
                          <View style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 5
                          }}>
                            <TouchableOpacity
                              style={{
                                height: 40,
                                borderWidth: 1,
                                borderColor:
                                  error && licenseTypeArr.length === 0 ? "red" : "#e6e6e6",
                                backgroundColor: "#f2f2f2",
                                borderRadius: 10,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingHorizontal: 10,
                                marginRight: 10,
                              }}
                              onPress={() => {
                                setAddJob(false)
                                setShowLicenseType(true)
                              }}
                            >
                              <MaterialIcons
                                name={"add"}
                                size={24}
                              />
                            </TouchableOpacity>
                            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                              {licenseTypeArr?.map((item, index) => {
                                return (
                                  <View
                                    key={index}
                                    style={{
                                      height: 40,
                                      borderWidth: 1,
                                      borderColor: "#e6e6e6",
                                      backgroundColor: "#f2f2f2",
                                      borderRadius: 10,
                                      flexDirection: "row",
                                      alignItems: "center",
                                      paddingHorizontal: 10,
                                      paddingVertical: 5,
                                      marginRight: 10,
                                    }}
                                  >
                                    <Text style={{ fontSize: 12, color: "#737373" }}>
                                      {item}
                                    </Text>
                                    <TouchableOpacity
                                      onPress={() => handleRemoveLicenseType(index)}
                                    >
                                      <MaterialIcons
                                        name={"delete-outline"}
                                        size={24}
                                        color="#999"
                                      />
                                    </TouchableOpacity>
                                  </View>
                                );
                              })}
                            </View>
                          </View>
                        </View>
                        <View
                          style={{
                            marginTop: 5,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                marginBottom: 5,
                                fontSize: 12,
                                color: "#737373",
                              }}
                            >
                              Customer Name
                            </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{
                                  marginBottom: 5,
                                  fontSize: 12,
                                  color: "#737373",
                                }}
                              >
                                After Assignment
                              </Text>
                              <CustomToggle
                                state={jobSelection.customerVisibility}
                                onValueChange={(value) =>
                                  setJobSelection({ ...jobSelection, customerVisibility: value })
                                }
                              />
                            </View>
                          </View>

                          <TouchableOpacity
                            onPress={() => {
                              setAddJob(false)
                              setPatientView(true)
                            }}
                            style={{
                              backgroundColor: "#f2f2f2",
                              borderRadius: 10,
                              elevation: 1,
                              height: 40,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              borderWidth: 0.5,
                              borderColor: "#e6e6e6",
                              paddingRight: 10,
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              style={{
                                color: "#737373",
                                paddingLeft: 5,
                                fontSize: 12,
                              }}
                            >
                              {jobSelection.customer === ""
                                ? "Select Customer"
                                : jobSelection?.customer}
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View
                          style={{ marginTop: 5 }}>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={[
                                {
                                  fontSize: 12,
                                  color: "#737373",
                                },
                              ]}
                            >
                              Address
                            </Text>
                            <Text style={{ color: "red" }}>*</Text>
                          </View>
                          <View
                            style={{
                              marginTop: 5,
                            }}
                          >
                            <TouchableOpacity
                              style={{
                                borderWidth: 0.5,
                                borderColor:
                                  error && jobSelection.fullAddress === "" ? "red" : "#e6e6e6",
                                backgroundColor: "#f2f2f2",
                                borderRadius: 10,
                                elevation: 1,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingHorizontal: 10,
                                paddingVertical: 10,
                              }}
                              onPress={() => {
                                setAddJob(false)
                                if (Platform.OS === "web") {
                                  setShowAddressWeb(true);
                                } else {
                                  setShowAddress(true);
                                }
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                  fontSize: 12,
                                  color: "#737373",
                                }}
                              >
                                {jobSelection.fullAddress !== ""
                                  ? jobSelection.fullAddress
                                  : "Enter Address"}
                              </Text>
                              {jobSelection.fullAddress === "" && (
                                <MaterialIcons name={"add"} size={24} color="#808080" />
                              )}
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View
                          style={{
                            marginTop: 5,
                          }}
                        >
                          <Text
                            style={[
                              {
                                fontSize: 12,
                                color: "#737373",
                              },
                            ]}
                          >
                            Employee
                          </Text>
                          <TouchableOpacity style={{
                            borderWidth: 1,
                            borderColor: "#e6e6e6",
                            backgroundColor: "#f2f2f2",
                            borderRadius: 10,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: 10,
                            marginTop: 5,
                          }}>
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                borderWidth: 1,
                                borderRadius: 10,
                                borderColor: "#2775BD",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#fff",
                                width: 40,
                                height: 40,
                                overflow: "hidden",
                              }}
                            >
                              {jobSelection?.nurse?.profileImage ? (
                                <Image
                                  source={{
                                    uri: jobSelection?.nurse?.profileImage
                                  }}
                                  style={{
                                    width: 38,
                                    height: 38,
                                    borderRadius: 10,
                                  }}

                                />
                              ) : (
                                <Image
                                  source={require("../../../assets/images/icon.jpg")}
                                  style={{ width: 80, height: 80 }}
                                />
                              )}
                            </View>
                            <View
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                flex: 1,
                              }}
                            >
                              <View
                                style={{
                                  marginLeft: 10,
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <Text style={{ fontSize: 12 }}>{jobSelection?.nurse?.firstName + " " + jobSelection?.nurse?.lastName}</Text>
                                <Text style={{ color: "#8d8d8d", fontWeight: "600", fontSize: 10 }}>
                                  {jobSelection?.nurse?.primaryLicenseType}
                                </Text>
                              </View>
                            </View>
                          </TouchableOpacity>

                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          marginTop: 5
                        }}
                      >
                        {selectedTemplate === undefined &&
                          <TouchableOpacity
                            onPress={() => {
                              setFormView("Template")
                              setFormTemView("Select")
                              setJobSelection({
                                ...jobSelection,
                                jobType: appJobType === "Shift" ? "Shift" :
                                  appJobType === "Visit" ? "Visit" : "Shift",
                                startTime: new Date().toString(),
                                endTime: moment().add(1, "hour"),
                                title: "",
                                notes: "",
                                break: "",
                                customerVisibility: false,
                                customer: "",
                                fullAddress: "",
                                latitude: "",
                                longitude: "",
                                unit: ""
                              })
                              setSelectedHours("1")
                            }}
                            style={{
                              backgroundColor: "#00b359",
                              paddingHorizontal: 10,
                              paddingVertical: 5,
                              borderRadius: 5,
                              marginTop: 5,
                            }}
                          >
                            <Text
                              style={{
                                textAlign: "right",
                                fontSize: 12,
                                color: "#fff",
                              }}
                            >
                              Select Template
                            </Text>
                          </TouchableOpacity>}
                        <TouchableOpacity
                          onPress={() => {
                            const ct = new Date(jobSelection?.startTime);
                            const currentHour = ct.getHours();
                            let jobTiming =
                              (currentHour >= 5 && currentHour < 12) ? "Morning" :
                                (currentHour >= 12 && currentHour < 17) ? "Afternoon" :
                                  (currentHour >= 17 && currentHour < 20) ? "Evening" :
                                    "Night";
                            let jobDuration = timeDifferent(jobSelection?.startTime, jobSelection?.endTime)
                            postJob(jobSelection?.startDate, jobSelection?.endDate, jobDuration, jobTiming)
                          }}
                          style={{
                            backgroundColor: "#00b359",
                            paddingHorizontal: 10,
                            paddingVertical: 5,
                            borderRadius: 5,
                            marginTop: 5,
                            marginHorizontal: 5,
                          }}
                        >
                          <Text
                            style={{
                              textAlign: "right",
                              fontSize: 12,
                              color: "#fff",
                            }}
                          >
                            Create Job
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            setAddJob(false)
                            setJobSelection({
                              nurse: {},
                              jobType: appJobType === "Shift" ? "Shift" :
                                appJobType === "Visit" ? "Visit" : "Shift",
                              title: "",
                              notes: "",
                              break: "",
                              customerVisibility: false,
                              startDate: new Date().toString(),
                              endDate: new Date().toString(),
                              startTime: new Date().toString(),
                              endTime: moment().add(1, "hour"),
                              customer: "",
                              fullAddress: "",
                              latitude: "",
                              longitude: "",
                              unit: ""
                            })
                            setLicenseTypeArr([])
                            setSelectedTemplate(undefined)
                            setFormView("Custom")
                            setTimeOff({
                              timeOffReason: "",
                              startDate: new Date().toString(),
                              startTime: new Date().toString(),
                              endDate: new Date().toString(),
                              endTime: moment().add(1, "hour"),
                              alternateContact: "",
                              phoneNum: "",
                              comments: "",
                            })
                            setError(false);
                            setFormTemView("Select")
                            setTemplateName("")
                            setSelectedHours("1")
                          }}
                          style={{
                            backgroundColor: "#ccc",
                            paddingHorizontal: 10,
                            paddingVertical: 5,
                            borderRadius: 5,
                            marginTop: 5,
                          }}
                        >
                          <Text
                            style={{
                              textAlign: "right",
                              fontSize: 12,
                              color: "#000",
                            }}
                          >
                            Close
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                :
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        alignItems: "center"
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#737373",
                        }}
                      >
                        Start Date
                      </Text>
                      <View
                        // onPress={() => {
                        //   setAddJob(false)
                        //   setShowCalendar(true)
                        // }}
                        style={{
                          borderWidth: 1,
                          borderColor: "#e6e6e6",
                          backgroundColor: "#f2f2f2",
                          borderRadius: 5,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <Text
                          style={{
                            textAlign: "center",
                            fontSize: 12,
                            color: "#737373",
                          }}
                        >
                          {moment(jobSelection.startDate).format("MM-DD-YYYY")}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center"
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#737373",
                        }}
                      >
                        End Date
                      </Text>
                      <View
                        // onPress={() => {
                        //   setAddJob(false)
                        //   setShowCalendarEnd(true)
                        // }}
                        style={{
                          borderWidth: 1,
                          borderColor: "#e6e6e6",
                          backgroundColor: "#f2f2f2",
                          borderRadius: 5,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <Text
                          style={{
                            textAlign: "center",
                            fontSize: 12,
                            color: "#737373",
                          }}
                        >
                          {moment(jobSelection.endDate).format("MM-DD-YYYY")}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                      marginBottom: fullDay ? 0 : 5,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          marginBottom: 5,
                          fontSize: 12,
                          color: "#737373",
                        }}
                      >
                        Full Day
                      </Text>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <CustomToggle
                          state={fullDay}
                          onValueChange={(value) =>
                            setFullDay(value)
                          }
                        />
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          marginBottom: 5,
                          fontSize: 12,
                          color: "#737373",
                        }}
                      >
                        Partial Day
                      </Text>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <CustomToggle
                          state={!fullDay}
                          onValueChange={(value) =>
                            setFullDay(!value)
                          }
                        />
                      </View>
                    </View>
                  </View>
                  {!fullDay && <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 3,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#737373",
                        }}
                      >
                        Start Time<Text style={{ color: "red" }}>*</Text>
                      </Text>
                      <TouchableOpacity
                        style={{
                          marginLeft: 5,
                          borderWidth: 1,
                          borderColor: "#e6e6e6",
                          backgroundColor: "#f2f2f2",
                          borderRadius: 5,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: 3,
                        }}
                        onPress={() => {
                          setAddJob(false)
                          setShowTimeWeb(true)
                        }}
                      >
                        <Text
                          style={{
                            textAlign: "center",
                            fontSize: 12,
                            color: "#737373",
                          }}
                        >
                          {moment(jobSelection.startTime).format("hh:mm a")}
                        </Text>
                        <MaterialIcons name="access-time" size={20} color="#808080" />
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#737373",
                        }}
                      >
                        End Time<Text style={{ color: "red" }}>*</Text>
                      </Text>
                      <TouchableOpacity
                        // onPress={() => {
                        //   setAddJob(false)
                        //   setShowTimeEndWeb(true)
                        // }}
                        style={{
                          marginLeft: 5,
                          borderWidth: 1,
                          borderColor: "#e6e6e6",
                          backgroundColor: "#f2f2f2",
                          borderRadius: 5,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: 3,
                        }}
                      >
                        <Text
                          style={{
                            textAlign: "center",
                            fontSize: 12,
                            color: "#737373",
                          }}
                        >
                          {moment(jobSelection.endTime).format("hh:mm a")}
                        </Text>
                        <MaterialIcons name="access-time" size={20} color="#808080" />
                      </TouchableOpacity>
                    </View>
                  </View>}
                  {!fullDay &&
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginHorizontal: 10,
                        marginBottom: 15,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginHorizontal: 20,
                          marginTop: 10
                        }}
                      >
                        <Text
                          style={[
                            {
                              fontSize: 12,
                              color: "#737373",
                            },
                          ]}
                        >
                          Select Hour's (
                          <Text
                            style={[
                              {
                                fontSize: 12,
                                color: "#000",
                                fontWeight: "bold",
                                marginBottom: 10,
                              },
                            ]}
                          >
                            {selectedHours !== "" &&
                              `${selectedHours} ${selectedHours >= 2 ? "Hour(s)" : "Hour"
                              }`}
                          </Text>
                          )<Text style={{ color: "red" }}>*</Text>
                        </Text>
                      </View>
                      {/* <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          top: 5,
                        }}
                      >
                        <Text style={{ fontSize: 12 }}>1 H</Text>
                        <Text style={{ fontSize: 12 }}>12 H</Text>
                      </View> */}
                      <View>
                        {/* <CustomSliderFilter
                          sliderWidth={width / 1.5}
                          min={1}
                          max={12}
                          step={1}
                          type={""}
                          label={true}
                          color={"#00b359"}
                          onValueChange={(range) => {
                            setSelectedHours(range.max);

                            let date = new Date();
                            let dateEnd = new Date();

                            let nowEnd = new Date(jobSelection.startTime);
                            let selectedMinuteTime = nowEnd.getMinutes().toString().padStart(2, "0");
                            let selectedAPTime = nowEnd.getHours() >= 12 ? "PM" : "AM";
                            let selectedHourTime = nowEnd.getHours() === 0
                              ? 12
                              : nowEnd.getHours() > 12
                                ? nowEnd.getHours() - 12
                                : nowEnd.getHours()

                            date.setHours(
                              selectedAPTime === "AM" && selectedHourTime === 12
                                ? 0
                                : selectedAPTime === "PM" && selectedHourTime === 12
                                  ? 12
                                  : selectedHourTime !== 12 && selectedAPTime === "PM"
                                    ? parseInt(selectedHourTime) + 12
                                    : parseInt(selectedHourTime)
                            );
                            date.setMinutes(selectedMinuteTime);

                            // Add 8 hours to dateEnd based on date
                            dateEnd.setFullYear(date.getFullYear());
                            dateEnd.setMonth(date.getMonth());
                            dateEnd.setDate(date.getDate());
                            dateEnd.setHours(date.getHours() + range.max);
                            dateEnd.setMinutes(date.getMinutes());

                            // Determine if it's AM or PM
                            let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

                            if (selectedAPTime === "PM" && meridiem === "AM") {
                              let endDate = moment(jobSelection.startDate);
                              endDate.add(1, "day")
                              setJobSelection({
                                ...jobSelection,
                                startTime: date,
                                endTime: dateEnd,
                                endDate: endDate,
                              });
                            } else {
                              setJobSelection({
                                ...jobSelection,
                                startTime: date,
                                endTime: dateEnd,
                                endDate: jobSelection.startDate,
                              });
                            }
                          }}
                          valueMin={0}
                          valueMax={parseInt(selectedHours)}
                          minDrag={false}
                        /> */}
                        <Text
                          style={{
                            fontSize: 12,
                            color: "#737373",
                            marginLeft: -5,
                            marginBottom: 2
                          }}
                        >
                          Duration
                        </Text>
                        <CustomSliderFilterDropDown
                          sliderWidth={-10}
                          min={1}
                          max={12}
                          step={1}
                          type={""}
                          label={true}
                          color={"#737373"}
                          onValueChange={(range) => {
                            setSelectedHours(range.max);

                            let date = new Date();
                            let dateEnd = new Date();

                            let nowEnd = new Date(jobSelection.startTime);
                            let selectedMinuteTime = nowEnd.getMinutes().toString().padStart(2, "0");
                            let selectedAPTime = nowEnd.getHours() >= 12 ? "PM" : "AM";
                            let selectedHourTime = nowEnd.getHours() === 0
                              ? 12
                              : nowEnd.getHours() > 12
                                ? nowEnd.getHours() - 12
                                : nowEnd.getHours()

                            date.setHours(
                              selectedAPTime === "AM" && selectedHourTime === 12
                                ? 0
                                : selectedAPTime === "PM" && selectedHourTime === 12
                                  ? 12
                                  : selectedHourTime !== 12 && selectedAPTime === "PM"
                                    ? parseInt(selectedHourTime) + 12
                                    : parseInt(selectedHourTime)
                            );
                            date.setMinutes(selectedMinuteTime);

                            // Add 8 hours to dateEnd based on date
                            dateEnd.setFullYear(date.getFullYear());
                            dateEnd.setMonth(date.getMonth());
                            dateEnd.setDate(date.getDate());
                            dateEnd.setHours(date.getHours() + range.max);
                            dateEnd.setMinutes(date.getMinutes());

                            // Determine if it's AM or PM
                            let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";

                            if (selectedAPTime === "PM" && meridiem === "AM") {
                              let endDate = moment(jobSelection.startDate);
                              endDate.add(1, "day")
                              setJobSelection({
                                ...jobSelection,
                                startTime: date,
                                endTime: dateEnd,
                                endDate: endDate,
                              });
                            } else {
                              setJobSelection({
                                ...jobSelection,
                                startTime: date,
                                endTime: dateEnd,
                                endDate: jobSelection.startDate,
                              });
                            }
                          }}
                          valueMin={0}
                          valueMax={parseInt(selectedHours)}
                          minDrag={false}
                          dType={"time"}
                        />
                      </View>
                    </View>}
                  <View>
                    <Text
                      style={{
                        marginBottom: 5,
                        marginTop: -3,
                        fontSize: 12,
                        color: "#737373",
                      }}
                    >
                      Time Off Reason <Text style={{ color: "red" }}>*</Text>
                    </Text>
                    <View
                      style={{
                        backgroundColor: "#f2f2f2",
                        borderRadius: 10,
                        elevation: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderWidth: 0.5,
                        borderColor:
                          error && timeOff.timeOffReason === "" ? "red" : "#e6e6e6",
                        paddingRight: 10,
                        justifyContent: "space-between",
                      }}
                    >
                      <TextInput
                        style={{
                          width: "90%",
                          padding: 2,
                          paddingLeft: 5,
                          fontSize: 12,
                          color: "#737373",
                        }}
                        onChangeText={(text) =>
                          setTimeOff({ ...timeOff, timeOffReason: text })
                        }
                        value={timeOff.timeOffReason}
                        keyboardType={"default"}
                        autoCapitalize="sentences"
                        placeholderTextColor="#b3b3b3"
                        placeholder={"Time Off Reason"}
                      />
                      <View
                        style={{
                          height: 40,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {timeOff.timeOffReason ? (
                          <TouchableOpacity
                            onPress={() => setTimeOff({ ...timeOff, timeOffReason: "" })}
                            style={{ marginLeft: 3 }}
                          >
                            <Ionicons name="close-sharp" size={22} color="#808080" />
                          </TouchableOpacity>
                        ) : null}
                      </View>
                    </View>
                  </View>
                  <View>
                    <Text
                      style={{
                        marginTop: 10,
                        fontSize: 12,
                        color: "#737373",
                      }}
                    >
                      Comments
                    </Text>

                    <View
                      style={{
                        backgroundColor: "#f2f2f2",
                        borderRadius: 10,
                        elevation: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderWidth: 0.5,
                        borderColor: "#e6e6e6",
                        paddingRight: 10,
                        justifyContent: "space-between",
                        marginTop: 5,
                      }}
                    >
                      <TextInput
                        style={{
                          width: "90%",
                          padding: 2,
                          paddingLeft: 5,
                          fontSize: 12,
                          color: "#737373",
                        }}
                        multiline
                        onChangeText={(text) =>
                          setTimeOff({ ...timeOff, comments: text })
                        }
                        value={timeOff.comments}
                        keyboardType={"default"}
                        autoCapitalize="sentences"
                        placeholderTextColor="#b3b3b3"
                        placeholder={"Comments..."}
                      // returnKeyType="next"
                      />
                      <View
                        style={{
                          height: 40,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {timeOff.comments ? (
                          <TouchableOpacity
                            onPress={() => setTimeOff({ ...timeOff, comments: "" })}
                            style={{ marginLeft: 3 }}
                          >
                            <Ionicons name="close-sharp" size={22} color="#808080" />
                          </TouchableOpacity>
                        ) : null}
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      marginTop: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#737373",
                      }}
                    >
                      Alternate Contact (Optional){" "}
                    </Text>
                    <View
                      style={{
                        height: 45,
                        backgroundColor: "#f2f2f2",
                        borderRadius: 10,
                        elevation: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderWidth: 0.5,
                        borderColor: "#e6e6e6",
                        paddingRight: 10,
                        justifyContent: "space-between",
                        marginTop: 5,
                      }}
                    >
                      <Text
                        style={{
                          marginLeft: 5,
                          paddingHorizontal: 5,
                          borderRightWidth: 1,
                          borderColor: "#d9d9d9",
                          color: "#737373",
                        }}
                      >
                        +1
                      </Text>
                      <TextInput
                        onChangeText={(text) =>
                          setTimeOff({ ...timeOff, alternateContact: text })
                        }
                        value={timeOff.alternateContact}
                        keyboardType={"numbers-and-punctuation"}
                        autoCapitalize="none"
                        placeholderTextColor="#b3b3b3"
                        placeholder={"Enter Alternate Contact"}
                        style={{
                          flex: 1,
                          height: 45,
                          borderRadius: 10,
                          paddingLeft: 10,
                          paddingRight: 5,
                          fontSize: 12,
                        }}
                      />
                      <View
                        style={{
                          height: 40,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {timeOff.alternateContact ? (
                          <TouchableOpacity
                            onPress={() =>
                              setTimeOff({ ...timeOff, alternateContact: "" })
                            }
                          >
                            <Ionicons name="close-sharp" size={22} color="#808080" />
                          </TouchableOpacity>
                        ) : null}
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      marginTop: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        color: "#737373",
                      }}
                    >
                      Phone Number
                    </Text>
                    <View
                      style={{
                        height: 45,
                        backgroundColor: "#f2f2f2",
                        borderRadius: 10,
                        elevation: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderWidth: 0.5,
                        borderColor: "#e6e6e6",
                        paddingRight: 10,
                        justifyContent: "space-between",
                        marginTop: 5,
                      }}
                    >
                      <Text
                        style={{
                          marginLeft: 5,
                          paddingHorizontal: 5,
                          borderRightWidth: 1,
                          borderColor: "#d9d9d9",
                          color: "#737373",
                        }}
                      >
                        +1
                      </Text>
                      <TextInput
                        onChangeText={(text) =>
                          setTimeOff({ ...timeOff, phoneNum: text })
                        }
                        value={timeOff.phoneNum}
                        keyboardType={"decimal-pad"}
                        editable={false}
                        autoCapitalize="none"
                        placeholderTextColor="#b3b3b3"
                        placeholder={"Enter Phone Number"}
                        style={{
                          flex: 1,
                          height: 45,
                          borderRadius: 10,
                          paddingLeft: 10,
                          paddingRight: 5,
                          fontSize: 12,
                        }}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      marginTop: 5,
                    }}
                  >
                    <Text
                      style={[
                        {
                          fontSize: 12,
                          color: "#737373",
                        },
                      ]}
                    >
                      Employee
                    </Text>
                    <TouchableOpacity style={{
                      borderWidth: 1,
                      borderColor: "#e6e6e6",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 10,
                      marginTop: 5,
                    }} >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          borderWidth: 1,
                          borderRadius: 10,
                          borderColor: "#2775BD",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#fff",
                          width: 40,
                          height: 40,
                          overflow: "hidden",
                        }}
                      >
                        {jobSelection?.nurse?.profileImage ? (
                          <Image
                            source={{
                              uri: jobSelection?.nurse?.profileImage
                            }}
                            style={{
                              width: 38,
                              height: 38,
                              borderRadius: 10,
                            }}

                          />
                        ) : (
                          <Image
                            source={require("../../../assets/images/icon.jpg")}
                            style={{ width: 80, height: 80 }}
                          />
                        )}
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flex: 1,
                        }}
                      >
                        <View
                          style={{
                            marginLeft: 10,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text style={{ fontSize: 12 }}>{jobSelection?.nurse?.firstName + jobSelection?.nurse?.lastName}</Text>
                          <Text style={{ color: "#8d8d8d", fontWeight: "600", fontSize: 10 }}>
                            {jobSelection?.nurse?.primaryLicenseType}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>

                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginTop: 5
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        postTimeOff(
                          jobSelection?.startDate,
                          jobSelection?.endDate,
                          jobSelection.startTime,
                          jobSelection.endTime)
                      }}
                      style={{
                        backgroundColor: "#00b359",
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                        borderRadius: 5,
                        marginTop: 5,
                        marginHorizontal: 5,
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "right",
                          fontSize: 12,
                          color: "#fff",
                        }}
                      >
                        Create Time Off
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        setAddJob(false)
                        setJobSelection({
                          nurse: {},
                          jobType: appJobType === "Shift" ? "Shift" :
                            appJobType === "Visit" ? "Visit" : "Shift",
                          title: "",
                          notes: "",
                          break: "",
                          customerVisibility: false,
                          startDate: new Date().toString(),
                          endDate: new Date().toString(),
                          startTime: new Date().toString(),
                          endTime: moment().add(1, "hour"),
                          customer: "",
                          fullAddress: "",
                          latitude: "",
                          longitude: "",
                          unit: ""
                        })
                        setLicenseTypeArr([])
                        setSelectedTemplate(undefined)
                        setFormView("Custom")
                        setTimeOff({
                          timeOffReason: "",
                          startDate: new Date().toString(),
                          startTime: new Date().toString(),
                          endDate: new Date().toString(),
                          endTime: moment().add(1, "hour"),
                          alternateContact: "",
                          phoneNum: "",
                          comments: "",
                        })
                        setError(false);
                        setSelectedHours("1");
                      }}
                      style={{
                        backgroundColor: "#ccc",
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                        borderRadius: 5,
                        marginTop: 5,
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "right",
                          fontSize: 12,
                          color: "#000",
                        }}
                      >
                        Close
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
            }
          </View>
        </View>
      </Modal>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showTimeWeb}
        onRequestClose={() => setShowTimeWeb(false)}
      >
        <View
          style={{
            height: height,
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 10,
              height: 280,
              justifyContent: "center",
              elevation: 5,
              width: width / 1.3,
              marginTop: 100,
              paddingHorizontal: 10,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <Text style={{ fontSize: 18, marginBottom: 0 }}>
              Select Start Time{" "}
            </Text>
            <Text style={{ fontSize: 10, marginBottom: 10 }}>
              Select Hour and Minutes
            </Text>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => {
                  setShowDropdown(!showDropdown);
                  setShowDropdownMinute(false);
                  setShowDropdownAP(false);
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    marginBottom: 10,
                    color: showDropdown ? "#00b359" : "#000",
                  }}
                >
                  {selectedHourTime === "" ? "00" : selectedHourTime}
                </Text>
              </TouchableOpacity>
              <Text style={{ fontSize: 25, marginBottom: 10 }}> : </Text>
              <TouchableOpacity
                onPress={() => {
                  setShowDropdownMinute(!showDropdownMinute);
                  setShowDropdown(false);
                  setShowDropdownAP(false);
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    marginBottom: 10,
                    color: showDropdownMinute ? "#00b359" : "#000",
                  }}
                >
                  {selectedMinuteTime === "" ? "00" : selectedMinuteTime}
                </Text>
              </TouchableOpacity>
              <Text style={{ fontSize: 25, marginBottom: 10 }}> </Text>
              <TouchableOpacity
                onPress={() => {
                  setShowDropdownAP(!showDropdownAP);
                  setShowDropdown(false);
                  setShowDropdownMinute(false);
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    marginBottom: 10,
                    color: showDropdownAP ? "#00b359" : "#000",
                  }}
                >
                  {selectedAPTime === "" ? "AM" : selectedAPTime}
                </Text>
              </TouchableOpacity>
            </View>
            {showDropdown && (
              <ScrollView
                style={{
                  width: "100%",
                  maxHeight: 150,
                  overflow: "scroll",
                  borderWidth: 0.5,
                  borderRadius: 4,
                  marginBottom: 5,
                  backgroundColor: "#fff",
                }}
              >
                {hour.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      onPress={() => {
                        setSelectedHourTime(item < 10 ? "0" + item : item);
                        setShowDropdown(false);
                      }}
                      style={styles.dropdownItem}
                    >
                      <Text>{item < 10 ? "0" + item : item}</Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            )}
            {showDropdownMinute && (
              <ScrollView
                style={{
                  width: "100%",
                  maxHeight: 150,
                  overflow: "scroll",
                  borderWidth: 0.5,
                  borderRadius: 4,
                  marginBottom: 5,
                  backgroundColor: "#fff",
                }}
              >
                <TextInput
                  style={{
                    width: "90%",
                    padding: 2,
                    paddingLeft: 5,
                    fontSize: 12,
                    color: "#737373",
                  }}
                  keyboardType={"decimal-pad"}
                  onChangeText={(text) => {
                    setSelectedMinuteTime(text);
                  }}
                  value={
                    selectedMinuteTime === "00" ? "" : selectedMinuteTime
                  }
                  placeholderTextColor="#b3b3b3"
                  placeholder={`Enter custom minutes`}
                />
                {minutes.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      onPress={() => {
                        setSelectedMinuteTime(item < 10 ? "0" + item : item);
                        setShowDropdownMinute(false);
                      }}
                      style={styles.dropdownItem}
                    >
                      <Text>{item < 10 ? "0" + item : item}</Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            )}
            {showDropdownAP && (
              <ScrollView
                style={{
                  width: "100%",
                  maxHeight: 100,
                  overflow: "scroll",
                  borderWidth: 0.5,
                  borderRadius: 4,
                  marginBottom: 5,
                  backgroundColor: "#fff",
                }}
              >
                {ampm.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      onPress={() => {
                        setSelectedAPTime(item);
                        setShowDropdownAP(false);
                      }}
                      style={styles.dropdownItem}
                    >
                      <Text>{item}</Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            )}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setShowTimeWeb(false);
                  setShowDropdown(false);
                  setShowDropdownMinute(false);
                  setShowDropdownAP(false);
                  setAddJob(true)
                }}
                style={{
                  backgroundColor: "#888",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                  marginHorizontal: 10,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  if (parseInt(selectedHours) === 1) {
                    let date = new Date();
                    let dateEnd = new Date();
                    date.setHours(
                      selectedAPTime === "AM" && selectedHourTime === 12
                        ? 0
                        : selectedAPTime === "PM" && selectedHourTime === 12
                          ? 12
                          : selectedHourTime !== 12 && selectedAPTime === "PM"
                            ? parseInt(selectedHourTime) + 12
                            : parseInt(selectedHourTime)
                    );
                    date.setMinutes(selectedMinuteTime);
                    // Add 8 hours to dateEnd based on date
                    dateEnd.setFullYear(date.getFullYear());
                    dateEnd.setMonth(date.getMonth());
                    dateEnd.setDate(date.getDate());
                    // dateEnd.setHours(date.getHours() + (jobSelection.jobType === "Shift" ? 8 : 1));
                    dateEnd.setHours(date.getHours() + 1);
                    dateEnd.setMinutes(date.getMinutes());

                    // Determine if it's AM or PM
                    let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";
                    if (selectedAPTime === "PM" && meridiem === "AM") {
                      let endDate = moment(jobSelection.startDate);
                      endDate.add(1, "day")
                      setJobSelection({
                        ...jobSelection,
                        startTime: date,
                        endTime: dateEnd,
                        endDate: endDate,
                      });
                    } else {
                      setJobSelection({
                        ...jobSelection,
                        startTime: date,
                        endTime: dateEnd,
                        endDate: jobSelection.startDate,
                      });
                    }
                  } else {
                    let date = new Date();
                    let dateEnd = new Date();
                    date.setHours(
                      selectedAPTime === "AM" && selectedHourTime === 12
                        ? 0
                        : selectedAPTime === "PM" && selectedHourTime === 12
                          ? 12
                          : selectedHourTime !== 12 && selectedAPTime === "PM"
                            ? parseInt(selectedHourTime) + 12
                            : parseInt(selectedHourTime)
                    );
                    date.setMinutes(selectedMinuteTime);
                    // Add 8 hours to dateEnd based on date
                    dateEnd.setFullYear(date.getFullYear());
                    dateEnd.setMonth(date.getMonth());
                    dateEnd.setDate(date.getDate());
                    // dateEnd.setHours(date.getHours() + (jobSelection.jobType === "Shift" ? 8 : 1));
                    dateEnd.setHours(date.getHours() + parseInt(selectedHours));
                    dateEnd.setMinutes(date.getMinutes());

                    // Determine if it's AM or PM
                    let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";
                    if (selectedAPTime === "PM" && meridiem === "AM") {
                      let endDate = moment(jobSelection.startDate);
                      endDate.add(1, "day")
                      setJobSelection({
                        ...jobSelection,
                        startTime: date,
                        endTime: dateEnd,
                        endDate: endDate,
                      });
                    } else {
                      setJobSelection({
                        ...jobSelection,
                        startTime: date,
                        endTime: dateEnd,
                        endDate: jobSelection.startDate,
                      });
                    }
                  }
                  // let date = new Date();
                  // let dateEnd = new Date();
                  // date.setHours(
                  //   selectedAPTime === "AM" && selectedHourTime === 12
                  //     ? 0
                  //     : selectedAPTime === "PM" && selectedHourTime === 12
                  //       ? 12
                  //       : selectedHourTime !== 12 && selectedAPTime === "PM"
                  //         ? parseInt(selectedHourTime) + 12
                  //         : parseInt(selectedHourTime)
                  // );
                  // date.setMinutes(selectedMinuteTime);

                  // dateEnd.setFullYear(date.getFullYear());
                  // dateEnd.setMonth(date.getMonth());
                  // dateEnd.setDate(date.getDate());
                  // dateEnd.setHours(date.getHours() + (formView === "Time Off" ? 0 : jobSelection.jobType === "Shift" ? 8 : 1));
                  // dateEnd.setMinutes(date.getMinutes() + (formView === "Time Off" ? 30 : 0));

                  // // Determine if it's AM or PM
                  // let meridiem = dateEnd.getHours() >= 12 ? "PM" : "AM";
                  // if (selectedAPTime === "PM" && meridiem === "AM") {
                  //   const getDateForEndDate = moment(moment(jobSelection.startDate).format("YYYY-MM-DD"));
                  //   // Add one day
                  //   var nightEndDate = getDateForEndDate.add(1, 'day');
                  //   // Format the new date if needed
                  //   var endDate = nightEndDate.format('YYYY-MM-DD');
                  //   setJobSelection({
                  //     ...jobSelection,
                  //     startTime: date,
                  //     endTime: dateEnd,
                  //     endDate: endDate,
                  //   });
                  // } else {
                  //   setJobSelection({
                  //     ...jobSelection,
                  //     startTime: date,
                  //     endTime: dateEnd,
                  //     endDate: jobSelection.startDate,
                  //   });
                  // }
                  setShowTimeWeb(false);
                  setShowDropdown(false);
                  setShowDropdownMinute(false);
                  setShowDropdownAP(false);
                  setAddJob(true)
                }}
                style={{
                  backgroundColor: "#00b359",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Select
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showTimeEndWeb}
        onRequestClose={() => setShowTimeEndWeb(false)}
      >
        <View
          style={{
            height: height,
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 10,
              height: 280,
              justifyContent: "center",
              elevation: 5,
              width: width / 1.3,
              marginTop: 100,
              paddingHorizontal: 10,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <Text style={{ fontSize: 18, marginBottom: 0 }}>
              Select End Time
            </Text>
            <Text style={{ fontSize: 10, marginBottom: 10 }}>
              Select Hour and Minutes
            </Text>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => {
                  setShowDropdownEnd(!showDropdownEnd);
                  setShowDropdownMinuteEnd(false);
                  setShowDropdownAPEnd(false);
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    marginBottom: 10,
                    color: showDropdownEnd ? "#00b359" : "#000",
                  }}
                >
                  {selectedHourTimeEnd === "" ? "00" : selectedHourTimeEnd}
                </Text>
              </TouchableOpacity>
              <Text style={{ fontSize: 25, marginBottom: 10 }}> : </Text>
              <TouchableOpacity
                onPress={() => {
                  setShowDropdownMinuteEnd(!showDropdownMinuteEnd);
                  setShowDropdownEnd(false);
                  setShowDropdownAPEnd(false);
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    marginBottom: 10,
                    color: showDropdownMinuteEnd ? "#00b359" : "#000",
                  }}
                >
                  {selectedMinuteTimeEnd === ""
                    ? "00"
                    : selectedMinuteTimeEnd}
                </Text>
              </TouchableOpacity>
              <Text style={{ fontSize: 25, marginBottom: 10 }}> </Text>
              <TouchableOpacity
                onPress={() => {
                  setShowDropdownAPEnd(!showDropdownAPEnd);
                  setShowDropdownEnd(false);
                  setShowDropdownMinuteEnd(false);
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    marginBottom: 10,
                    color: showDropdownAPEnd ? "#00b359" : "#000",
                  }}
                >
                  {selectedAPTimeEnd === "" ? "AM" : selectedAPTimeEnd}
                </Text>
              </TouchableOpacity>
            </View>

            {showDropdownEnd && (
              <ScrollView
                style={{
                  width: "100%",
                  maxHeight: 150,
                  overflow: "scroll",
                  borderWidth: 0.5,
                  borderRadius: 4,
                  marginBottom: 5,
                  backgroundColor: "#fff",
                }}
              >
                {hour.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      onPress={() => {
                        setSelectedHourTimeEnd(item < 10 ? "0" + item : item);
                        setShowDropdownEnd(false);
                      }}
                      style={styles.dropdownItem}
                    >
                      <Text>{item < 10 ? "0" + item : item}</Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            )}
            {showDropdownMinuteEnd && (
              <ScrollView
                style={{
                  width: "100%",
                  maxHeight: 150,
                  overflow: "scroll",
                  borderWidth: 0.5,
                  borderRadius: 4,
                  marginBottom: 5,
                  backgroundColor: "#fff",
                }}
              >
                <TextInput
                  style={{
                    width: "90%",
                    padding: 2,
                    paddingLeft: 5,
                    fontSize: 12,
                    color: "#737373",
                  }}
                  keyboardType={"decimal-pad"}
                  onChangeText={(text) => {
                    setSelectedMinuteTimeEnd(text);
                  }}
                  value={
                    selectedMinuteTimeEnd === "00"
                      ? ""
                      : selectedMinuteTimeEnd
                  }
                  placeholderTextColor="#b3b3b3"
                  placeholder={`Enter custom minutes`}
                />
                {minutes.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      onPress={() => {
                        setSelectedMinuteTimeEnd(
                          item < 10 ? "0" + item : item
                        );
                        setShowDropdownMinuteEnd(false);
                      }}
                      style={styles.dropdownItem}
                    >
                      <Text>{item < 10 ? "0" + item : item}</Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            )}

            {showDropdownAPEnd && (
              <ScrollView
                style={{
                  width: "100%",
                  maxHeight: 100,
                  overflow: "scroll",
                  borderWidth: 0.5,
                  borderRadius: 4,
                  marginBottom: 5,
                  backgroundColor: "#fff",
                }}
              >
                {ampm.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      onPress={() => {
                        setSelectedAPTimeEnd(item);
                        setShowDropdownAPEnd(false);
                      }}
                      style={styles.dropdownItem}
                    >
                      <Text>{item}</Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            )}

            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setShowTimeEndWeb(false);
                  setShowDropdownEnd(false);
                  setShowDropdownMinuteEnd(false);
                  setShowDropdownAPEnd(false);
                  setAddJob(true)
                }}
                style={{
                  backgroundColor: "#888",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                  marginHorizontal: 10,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  let date = new Date();
                  date.setHours(
                    selectedHourTimeEnd === 12 && selectedAPTimeEnd === "AM"
                      ? 0
                      : selectedAPTimeEnd === "PM" &&
                        selectedHourTimeEnd === 12
                        ? 12
                        : selectedHourTimeEnd !== 12 &&
                          selectedAPTimeEnd === "PM"
                          ? parseInt(selectedHourTimeEnd) + 12
                          : parseInt(selectedHourTimeEnd)
                  );
                  date.setMinutes(selectedMinuteTimeEnd);
                  setJobSelection({ ...jobSelection, endTime: date });
                  setShowTimeEndWeb(false);
                  setShowDropdownEnd(false);
                  setShowDropdownMinuteEnd(false);
                  setShowDropdownAPEnd(false);
                  setAddJob(true)
                }}
                style={{
                  backgroundColor: "#00b359",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Select
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showBreak}
        onRequestClose={() => {
          setAddJob(true)
          setShowBreak(false)
        }}
      >
        <View
          style={{
            height: height,
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 10,
              height: 280,
              justifyContent: "center",
              elevation: 5,
              width: width / 1.1,
              paddingHorizontal: 10,
              marginTop: 100,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <Text style={{ fontSize: 12, marginBottom: 10 }}>
              Select Break
            </Text>
            <CustomDropdown
              options={optionBreak}
              selectedValue={"Break"}
              onValueChange={(value) => {
                let date = new Date(jobSelection.endTime);
                let dateEnd = new Date(jobSelection.endTime);
                dateEnd.setFullYear(date.getFullYear());
                dateEnd.setMonth(date.getMonth());
                dateEnd.setDate(date.getDate());
                dateEnd.setHours(
                  value === "1 Hour" ? date.getHours() + 1 : date.getHours()
                );
                dateEnd.setMinutes(
                  value === "15 Minutes"
                    ? date.getMinutes() + 15
                    : value === "30 Minutes"
                      ? date.getMinutes() + 30
                      : value === "45 Minutes"
                        ? date.getMinutes() + 45
                        : date.getMinutes()
                );

                setJobSelection({
                  ...jobSelection,
                  break: value,
                  endTime: dateEnd,
                });
                setAddJob(true)
                setShowBreak(false);
              }}
            />

            <View
              style={{
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setAddJob(true)
                  setShowBreak(false)
                }}
                style={{
                  backgroundColor: "#00b359",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="fade"
        transparent={true}
        visible={patientView}
        onRequestClose={() => {
          setAddJob(true)
          setPatientView(false)
        }}
      >
        <View
          style={{
            height: height,
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 10,
              height: 280,
              justifyContent: "center",
              elevation: 5,
              width: width / 1.1,
              paddingHorizontal: 10,
              borderRadius: 15,
              marginTop: 100,
              borderColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <Text style={{ fontSize: 12, marginTop: 10, marginBottom: 10 }}>
              Customer Name
            </Text>
            <View
              style={{
                backgroundColor: "#f2f2f2",
                borderRadius: 10,
                elevation: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderWidth: 0.5,
                borderColor: "#e6e6e6",
                paddingRight: 10,
                justifyContent: "space-between",
              }}
            >
              <TextInput
                style={{
                  width: "90%",
                  padding: 2,
                  paddingLeft: 5,
                  fontSize: 12,
                  color: "#737373",
                }}
                onChangeText={(text) => {
                  setPatientSearch(text);
                  filterCustomerNameSuggestions(text);
                }}
                value={patientSearch}
                autoCapitalize="sentences"
                keyboardType={"default"}
                placeholderTextColor="#b3b3b3"
                placeholder={"Search Customer Name"}
              />
              <View
                style={{
                  height: 40,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {patientSearch !== "" ? (
                  <TouchableOpacity
                    onPress={() => setPatientSearch("")}
                    style={{ marginLeft: 3 }}
                  >
                    <Ionicons name="close-sharp" size={22} color="#808080" />
                  </TouchableOpacity>
                ) : null}
              </View>
            </View>

            <FlatList
              // style={styles.list}
              data={
                patientSearch.length === 0 ? customerList : filteredSuggestions
              }
              renderItem={renderCustomerCardSuggestion}
              keyExtractor={(item) => item?.id}
            />

            <View
              style={{
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setAddJob(true)
                  setPatientView(false)
                }}
                style={{
                  backgroundColor: "#00b359",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                  marginTop: 5,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showLicenseType}
        onRequestClose={() => {
          setAddJob(true)
          setShowLicenseType(false)
        }}
      >
        <View
          style={{
            height: height,
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 10,
              height: 300,
              justifyContent: "center",
              elevation: 5,
              width: width / 1.1,
              paddingHorizontal: 10,
              borderRadius: 15,
              marginTop: 100,
              borderColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <Text style={{ fontSize: 12, marginBottom: 10 }}>
              Select License Type
            </Text>
            <CustomDropdown
              options={option}
              selectedValue={"LicenseType"}
              onValueChange={(value) => handleAddLicenseType(value)}
            />

            <View
              style={{
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setAddJob(true)
                  setShowLicenseType(false)
                }}
                style={{
                  backgroundColor: "#00b359",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showCalendar}
        onRequestClose={() => {
          setAddJob(true)
          setShowCalendar(false)
        }}
      >
        <View
          style={{
            height: height,
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 20,
              justifyContent: "center",
              elevation: 5,
              borderRadius: 15,
              marginTop: 100,
              borderColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <CalendarPicker
              onDateChange={(date) =>
                setTimeOff({ ...timeOff, startDate: date })
              }
              selectedDayColor="#00b359"
              selectedDayTextColor="#FFFFFF"
              initialDate={new Date()}
              disabledDates={disabledDates}
              disabledDatesTextStyle={disabledDatesTextStyle}
            />
            <View
              style={{
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setAddJob(true)
                  setShowCalendar(false)
                }}
                style={{
                  backgroundColor: "#00b359",
                  marginHorizontal: 20,
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Select
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showCalendarEnd}
        onRequestClose={() => {
          setAddJob(true)
          setShowCalendarEnd(false)
        }}
      >
        <View
          style={{
            height: height,
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 20,
              justifyContent: "center",
              elevation: 5,
              borderRadius: 15,
              marginTop: 100,
              borderColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <CalendarPicker
              onDateChange={(date) =>
                setTimeOff({ ...timeOff, endDate: date })
              }
              selectedDayColor="#00b359"
              selectedDayTextColor="#FFFFFF"
              initialDate={new Date()}
              disabledDates={disabledStartDates}
              disabledDatesTextStyle={disabledDatesTextStyle}
            />
            <View
              style={{
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setAddJob(true)
                  setShowCalendarEnd(false)
                }}
                style={{
                  backgroundColor: "#00b359",
                  marginHorizontal: 20,
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Select
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={patientViewFilter}
        onRequestClose={() => setPatientViewFilter(false)}
      >
        <View
          style={{
            height: height,
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 10,
              height: 400,
              justifyContent: "center",
              elevation: 5,
              width: width / 1.1,
              paddingHorizontal: 10,
              borderRadius: 15,
              marginTop: 100,
              borderColor: "rgba(0, 0, 0, 0.1)",
            }}
          >

            {/* <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "#fff",
                borderBottomWidth: 1,
                marginBottom: 3
              }}
            >
              <TouchableOpacity
                style={{
                  backgroundColor: viewFilter === "Customer" ? "#000" : "#00000000",
                  flex: 1,
                  borderTopWidth: viewFilter === "Customer" ? 1 : 0,
                  borderRightWidth: viewFilter === "Customer" ? 1 : 0,
                  borderLeftWidth: viewFilter === "Customer" ? 1 : 0,
                  alignItems: "center",
                  height: 25,
                  justifyContent: 'center'
                }}
                activeOpacity={0.5}
                onPress={() => {
                  setViewFilter("Customer");
                }}
              >
                <Text
                  style={{
                    fontWeight: "500", fontSize: 12,
                    color: viewFilter === "Customer" ? "#fff" : "#000",
                  }}
                >
                  Customer
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  backgroundColor: viewFilter === "Nurse" ? "#000" : "#00000000",
                  borderTopWidth: viewFilter === "Nurse" ? 1 : 0,
                  borderRightWidth: viewFilter === "Nurse" ? 1 : 0,
                  borderLeftWidth: viewFilter === "Nurse" ? 1 : 0,
                  flex: 1,
                  alignItems: "center",
                  height: 25,
                  justifyContent: 'center'
                }}
                activeOpacity={0.5}
                onPress={() => {
                  setViewFilter("Nurse");
                }}
              >
                <Text
                  style={{ fontWeight: "500", fontSize: 12, color: viewFilter === "Nurse" ? "#fff" : "#000", }}
                >
                  Nurse
                </Text>
              </TouchableOpacity>
            </View> */}

            {viewFilter === "Customer"
              ?
              <View
                style={{
                  height: 320,
                }}
              >
                <View
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    paddingRight: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <TextInput
                    style={{
                      width: "90%",
                      padding: 2,
                      paddingLeft: 5,
                      fontSize: 12,
                      color: "#737373",
                    }}
                    onChangeText={(text) => {
                      setPatientSearchFilter(text);
                      filterCustomerNameSuggestionsFilter(text);
                    }}
                    value={patientSearchFilter}
                    autoCapitalize="sentences"
                    keyboardType={"default"}
                    placeholderTextColor="#b3b3b3"
                    placeholder={"Search Customer Name"}
                  />
                  <View
                    style={{
                      height: 40,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {patientSearchFilter !== "" ? (
                      <TouchableOpacity
                        onPress={() => setPatientSearchFilter("")}
                        style={{ marginLeft: 3 }}
                      >
                        <Ionicons name="close-sharp" size={22} color="#808080" />
                      </TouchableOpacity>
                    ) : null}
                  </View>
                </View>

                <FlatList
                  data={
                    patientSearchFilter.length === 0 ? customerList : filteredSuggestionsFilter
                  }
                  renderItem={renderCustomerCardSuggestionFilter}
                  keyExtractor={(item) => item?.id}
                />
              </View>
              :
              <View
                style={{
                  height: 320,
                }}
              >
                <View
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: 10,
                    elevation: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderWidth: 0.5,
                    borderColor: "#e6e6e6",
                    paddingRight: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <TextInput
                    style={{
                      width: "90%",
                      padding: 2,
                      paddingLeft: 5,
                      fontSize: 12,
                      color: "#737373",
                    }}
                    onChangeText={(text) => {
                      setPatientSearchFilterNurse(text);
                      filterNurseNameSuggestionsFilter(text);
                    }}
                    value={patientSearchFilterNurse}
                    autoCapitalize="sentences"
                    keyboardType={"default"}
                    placeholderTextColor="#b3b3b3"
                    placeholder={"Search Name"}
                  />
                  <View
                    style={{
                      height: 40,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {patientSearchFilter !== "" ? (
                      <TouchableOpacity
                        onPress={() => setPatientSearchFilter("")}
                        style={{ marginLeft: 3 }}
                      >
                        <Ionicons name="close-sharp" size={22} color="#808080" />
                      </TouchableOpacity>
                    ) : null}
                  </View>
                </View>

                <FlatList
                  data={
                    patientSearchFilterNurse.length === 0 ? nurseList : filteredSuggestionsFilterNurse
                  }
                  renderItem={renderNurseCardSuggestionFilter}
                  keyExtractor={(item) => item?.id}
                />
              </View>
            }

            <View
              style={{
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setPatientSearchFilter("");
                  setFilteredSuggestionsFilter([]);
                  setPatientViewFilter(false);
                  setPatientSearchFilterNurse("");
                  setFilteredSuggestionsFilterNurse([]);
                  setViewFilter("Customer");
                }}
                style={{
                  backgroundColor: "#00b359",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                  marginTop: 5,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="fade"
        transparent={true}
        visible={showLicenseTypeFilter}
        onRequestClose={() => setShowLicenseTypeFilter(false)}
      >
        <View
          style={{
            height: height,
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              paddingVertical: 10,
              height: 300,
              justifyContent: "space-between",
              elevation: 5,
              width: width / 1.1,
              paddingHorizontal: 10,
              marginTop: 100,
              borderRadius: 15,
              borderColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <View>
              <Text style={{ fontSize: 12, marginVertical: 10 }}>
                Select License Type
              </Text>
              <CustomDropdownLicense
                licenseTypes={licenseTypes}
                options={option}
                selectedValue={"LicenseType"}
                onValueChange={(value) => {
                  handleAddLicenseTypeFilter(value)
                }}
              />
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setShowLicenseTypeFilter(false)
                }}
                style={{
                  backgroundColor: "#00b359",
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      {refreshScreen && <AlertButton onPress={onPress} refreshingView={refreshScreen} />}
    </View >
  )
}

export default NewCalendarScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  input: {
    color: "#000",
    paddingLeft: 15,
    fontWeight: "bold",
    height: 40,
    fontSize: 15,
    marginVertical: 10,
    borderRadius: 5,
  },
  homeBtn: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#00b359",
    width: "40%",
    height: 35,
    borderRadius: 5,
    marginVertical: 15,
    marginHorizontal: 5,
    alignSelf: "center",
  },
  dayHeaderText: {
    fontWeight: "bold",
  },
  dayContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    borderRadius: 15,
    marginTop: 6,
  },
  pastDateContainer: {
    backgroundColor: "#CCCCCC",
  },
  futureDateText: {
    color: "black",
  },
  pastDateText: {
    color: "white",
  },
  inputContainer: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 4,
  },
  dropdownItem: {
    padding: 8,
  },
});